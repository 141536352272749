import { useState } from 'react';

import { Box, InputLabel } from '@mui/material';

import { Information } from '../..';
import { QuestionProps } from '../../../../../types/form';
import { AddressSearchResult } from '../../../../../types/locationSearch';
import LocationSearch from '../../../../../utility/locationSearch';
import { useFormQuestionDebounceOnChange } from '../../../hooks';

import './index.css';

const SmartLocation: React.FC<QuestionProps> = ({
  onChange,
  value,
  fieldDefinitionJson,
  infoHtml,
  fieldType,
  hideLabel = false,
}) => {
  const [addr, setAddr] = useState<string>(value.value?.address ?? '');
  const [hasChanged, setHasChanged] = useState(false);
  const labelPrefix = fieldDefinitionJson.LabelPrefix ?? '';
  const debounceOnChange = useFormQuestionDebounceOnChange(onChange);

  return (
    <Box className="smart-location">
      {!hideLabel && (
        <InputLabel
          htmlFor={fieldDefinitionJson?.Properties[0].PropertyKey}
          required={value.isRequired}
          sx={{ marginLeft: '.2rem', marginBottom: '.3rem' }}>
          {labelPrefix ? `${labelPrefix} Street Address` : 'Street Address'}
          {infoHtml && <Information infoHtml={infoHtml} />}
        </InputLabel>
      )}
      <LocationSearch
        value={addr}
        id={fieldDefinitionJson.Properties[0].PropertyKey}
        label={''}
        error={value.validationErrorText}
        icon={'faDirections'}
        validateOnBlur={(val: string) => {
          debounceOnChange(value.questionId, { address: val }, hasChanged);
        }}
        handleInputChanged={(val: string) => {
          setAddr(val);
          debounceOnChange(value.questionId, { address: val }, true);
          setHasChanged(true);
        }}
        handleSelectedLocation={(selectedLocation: AddressSearchResult) => {
          if (selectedLocation != null) {
            debounceOnChange(
              value.questionId,
              {
                address: selectedLocation.streetAddress,
                state: selectedLocation.stateAbbr,
                zip: selectedLocation.zipCode,
                city: selectedLocation.city,
              },
              true,
            );
            setHasChanged(true);
          }
        }}
        required
        minLength={fieldDefinitionJson?.Properties[0]?.MinLength}
        maxLength={fieldDefinitionJson?.Properties[0]?.MaxLength}
        fieldType={fieldType}
        propertyKey={fieldDefinitionJson.Properties[0].PropertyKey}
      />
    </Box>
  );
};

export { SmartLocation };
