import React from 'react';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import { useTheme } from '@mui/material/styles';

import { Information } from '../../features/form/components';
import { OneXThemePalette } from '../../types/enums/theme';
import { screenUtils } from '../../utility/screenUtils';

import styles from './index.module.css';

interface Props {
  children: React.ReactNode;
  wide?: boolean;
  [x: string]: any;
  style?: any;
  hideScrollTop?: boolean;
}

const LayoutCard: React.FC<Props> = ({ children, wide = false, style, hideScrollTop, ...props }) => {
  const theme = useTheme<OneXThemePalette>();
  const [isFrame] = screenUtils.useIsFrame();

  let cardStyle = styles.container;
  let contentStyle = styles.content;

  if (wide) {
    cardStyle = styles.containerWide;
    contentStyle = styles.contentWide;
  }
  if (isFrame) {
    cardStyle = styles.containerFrame;
    contentStyle = styles.content;
  }

  return (
    <Card
      data-testid="layout-card"
      className={cardStyle}
      style={{ ...style, height: 'auto' }}
      {...props}>
      <Box
        data-testid="layout-content"
        sx={{
          h1: {
            color: theme.palette.text.primary,
          },
        }}
        className={contentStyle + ' fade-in'}>
        {children}
      </Box>
    </Card>
  );
};

interface LayoutCardHeaderProps {
  showHeaderHtml: boolean;
  headerHtml: string;
  infoHtml?: string;
}

export const LayoutCardHeader: React.FC<LayoutCardHeaderProps> = ({ showHeaderHtml, headerHtml, infoHtml }) => {
  return (
    <Box className={styles.cardHeader} data-testid="layout-card-header">
      {showHeaderHtml && (
        <>
          <Box
            className={styles.cardHeaderHtml}
            color="text.primary"
            dangerouslySetInnerHTML={{ __html: headerHtml }}
          />
          {infoHtml && <Information infoHtml={infoHtml} />}
        </>
      )}
    </Box>
  );
};

export default LayoutCard;
