import { useEffect, useState } from 'react';

import { Box, FormControl, FormHelperText, InputLabel, TextField } from '@mui/material';

import { Information } from '../..';
import { HeartlandErrorText } from '../../../../../themes/overrides';
import { ProportionOption, ProportionOptionJSON, QuestionProps } from '../../../../../types/form';
import { isEmpty } from '../../../../../utility';
import NumberInput from '../../../../../utility/numberInput';
import { formHelperService } from '../../../services';
import PercentageBadge from '../../PercentageBadge';

import './index.css';

interface Props extends QuestionProps {
  onChange: (values: any, shouldValidate: boolean) => void;
  options: Array<ProportionOptionJSON>;
  total: number;
  valid: boolean;
  errorText: string;
  value: { options: Array<ProportionOption> };
  propertyKey: string;
}

const Proportion: React.FC<Props> = ({
  errorText,
  label,
  onChange,
  options,
  helperText,
  required,
  total,
  valid,
  value,
  infoHtml,
  fieldType,
  propertyKey,
  fieldSubType,
  hideLabel = false,
  renderForSummary = false,
}) => {
  const [optionValues, setOptionValues] = useState<Array<ProportionOption>>(value.options);
  const [blurValues, setBlurValues] = useState<Array<ProportionOption>>([]);
  const [focused, setFocused] = useState<boolean>(false);
  const [timer, setTimer] = useState<boolean>(false);
  const [hasChanged, setHasChanged] = useState(false);

  const triggerOnChange = (values: Array<ProportionOption>) => {
    const newArray = values.map((currentObject) => {
      const { ...modified } = currentObject;
      return modified;
    });
    setBlurValues(newArray);
  };

  useEffect(() => {
    if (!focused && timer) {
      onChange(blurValues, hasChanged);
      setTimer(false);
    }
  }, [blurValues, timer, focused, onChange, hasChanged]);

  useEffect(() => {
    if (!optionValues || optionValues.length <= 0) {
      const setup: Array<ProportionOption> = [];

      options.forEach((e: ProportionOptionJSON) => {
        setup.push({ option: e.Label, value: null, userModified: false });
      });

      setOptionValues(setup);
      triggerOnChange(setup);
    }
  }, [optionValues, options]);

  function updateProportion(option: ProportionOptionJSON, e: any, updateOther?: boolean) {
    const input = e.target.value.replace(/% ?/g, '');

    let updateValue: any = parseInt(input);

    if (isNaN(updateValue)) updateValue = null;

    if (updateValue > total) updateValue = total;

    const newValues = [...optionValues];

    if (newValues.length <= 0) {
      options.forEach((e) => {
        newValues.push({ option: e.Label, value: null, userModified: false });
      });
    }

    const valueIndex = newValues.findIndex((x) => x.option === option.Label);
    newValues[valueIndex] = { ...newValues[valueIndex], value: updateValue, userModified: true };

    const indexArray = getIndexesOfNonUserModifiedValues(newValues);

    if (indexArray && indexArray.length === 1 && updateOther) {
      const otherIndex = indexArray[0];

      const otherValue = total - getTotalOfUserModifiedValues(newValues);

      if (otherValue > 0) {
        newValues[otherIndex] = { ...newValues[otherIndex], value: otherValue };
      } else {
        newValues[otherIndex] = { ...newValues[otherIndex], value: 0 };
      }
    }

    setOptionValues(newValues);

    triggerOnChange(newValues);
  }

  function getIndexesOfNonUserModifiedValues(valueArray: Array<ProportionOption>) {
    const indexArray = [];

    let i;
    for (i = 0; i < valueArray.length; i++) {
      if (valueArray[i].userModified === false || valueArray[i].userModified === undefined) indexArray.push(i);
    }

    return indexArray;
  }

  function getTotalOfUserModifiedValues(valueArray: Array<ProportionOption>) {
    let currentTotal = 0;

    valueArray.forEach((e) => {
      if (e.userModified) currentTotal += e.value ?? 0;
    });

    return currentTotal;
  }

  return (
    <Box className="proportion">
      {!hideLabel && (
        <InputLabel shrink htmlFor={label} sx={{ fontWeight: '500' }} required={required}>
          {label}
          {infoHtml && <Information infoHtml={infoHtml} />}
        </InputLabel>
      )}
      <Box className={renderForSummary ? 'proportion-options-column' : 'proportion-options'} id={label}>
        {renderForSummary && (
          <PercentageBadge
            value={`${optionValues.reduce((a, b) => a + (b.value ?? 0), 0)}`}
            valid={!valid}
            testId={label}
            sx={{ alignSelf: 'flex-end' }}
          />
        )}
        {options.map((option, i) => {
          return (
            <FormControl fullWidth key={option.Label}>
              <TextField
                className={renderForSummary ? 'proportion-options-field' : ''}
                variant="filled"
                label={option.Label}
                id={option.PropertyKey}
                InputLabelProps={{ shrink: true }}
                error={valid}
                onFocus={() => {
                  setFocused(true);
                  //@ts-ignore
                  document.getElementById(option.PropertyKey).setSelectionRange(0, 9999);
                }}
                onChange={(e) => {
                  updateProportion(option, e, false);
                  setHasChanged(true);
                }}
                onBlur={(e) => {
                  if (e.target.value !== '') {
                    updateProportion(option, e, true);
                  }
                  setTimeout(() => setTimer(true), 50);
                  setFocused(false);
                }}
                style={i === options.length ? {} : { marginRight: '.3rem' }}
                value={
                  optionValues &&
                  optionValues.length > 0 &&
                  optionValues.findIndex((x) => x.option === option.Label) > -1 &&
                  optionValues[optionValues.findIndex((x) => x.option === option.Label)].value != null
                    ? optionValues[optionValues.findIndex((x) => x.option === option.Label)].value
                    : ''
                }
                inputProps={{
                  maxLength: 3,
                  min: 1,
                  allowLeadingZeros: true,
                  decimalPlaces: 0,
                  suffix: '%',
                  ...formHelperService.getCustomInputProps(fieldType, propertyKey, option.Label, fieldSubType),
                }}
                InputProps={{
                  inputComponent: NumberInput as any,
                }}
                placeholder="0%"
                autoComplete={'on'}
              />
            </FormControl>
          );
        })}
      </Box>
      {valid && <HeartlandErrorText>{errorText}</HeartlandErrorText>}
      {!isEmpty(helperText) && (
        <FormHelperText
          className={'proportion-helperText'}
          dangerouslySetInnerHTML={{ __html: helperText! }}></FormHelperText>
      )}
    </Box>
  );
};

export { Proportion };
