import {
  faCheck,
  faCogs,
  faCommentAltLines,
  faDesktop,
  faFileInvoiceDollar,
  faHandHoldingHeart,
  faHotel,
  faLeaf,
  faLockAlt,
  faMinusCircle,
  faMobileAlt,
  faPlusCircle,
  faRss,
  faSackDollar,
  faScannerKeyboard,
  faShippingFast as faShippingFastLight,
  faSignOutAlt,
  faTachometerAltFastest,
  faTimes,
  faUsers,
} from '@fortawesome/pro-light-svg-icons';
import { faDownToLine } from '@fortawesome/pro-regular-svg-icons';
import {
  faAddressCard,
  faArrowLeft,
  faBriefcase,
  faBuilding,
  faCalendarDay,
  faCampfire,
  faCaretDown,
  faCaretUp,
  faCashRegister,
  faCat,
  faChair,
  faChairOffice,
  faChartLine,
  faCheck as faCheckBold,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircleCheck,
  faCircleInfo,
  faCircleXmark,
  faClipboardList,
  faCoffinCross,
  faDirections,
  faEnvelope,
  faFingerprint,
  faGlobe,
  faMapMarker,
  faPen,
  faPersonSign,
  faPhone,
  faPhoneAlt,
  faShippingFast,
  faShippingTimed,
  faStar,
  faTriangleExclamation,
  faUnicorn,
  faUniversity,
} from '@fortawesome/pro-solid-svg-icons';

const icons = {
  faBriefcase: faBriefcase,
  faPersonSign: faPersonSign,
  faCat: faCat,
  faCaretDown: faCaretDown,
  faCaretUp: faCaretUp,
  faCampfire: faCampfire,
  faAddressCard: faAddressCard,
  faUniversity: faUniversity,
  faChartLine: faChartLine,
  faCashRegister: faCashRegister,
  faStar: faStar,
  faEnvelope: faEnvelope,
  faPhoneAlt: faPhoneAlt,
  faFingerprint: faFingerprint,
  faClipboardList: faClipboardList,
  faChairOffice: faChairOffice,
  faDirections: faDirections,
  faBuilding: faBuilding,
  faMapMarker: faMapMarker,
  faChair: faChair,
  faCalendarDay: faCalendarDay,
  faGlobe: faGlobe,
  faPen: faPen,
  faUnicorn: faUnicorn,
  faPhone: faPhone,
  faCircleCheck: faCircleCheck,
  faCircleInfo: faCircleInfo,
  faTimes: faTimes,
  faCommentAltLines: faCommentAltLines,
  faArrowLeft: faArrowLeft,
  faHotel: faHotel,
  faCogs: faCogs,
  faSackDollar: faSackDollar,
  faUsers: faUsers,
  faLockAlt: faLockAlt,
  faSignOutAlt: faSignOutAlt,
  faRss: faRss,
  faFileInvoiceDollar: faFileInvoiceDollar,
  faScannerKeyboard: faScannerKeyboard,
  faMobileAlt: faMobileAlt,
  faHandHoldingHeart: faHandHoldingHeart,
  faLeaf: faLeaf,
  faDesktop: faDesktop,
  faChevronRight: faChevronRight,
  faChevronLeft: faChevronLeft,
  faChevronUp: faChevronUp,
  faChevronDown: faChevronDown,
  faShippingFast: faShippingFast,
  faShippingTimed: faShippingTimed,
  faCoffinCross: faCoffinCross,
  faTachometerAltFastest: faTachometerAltFastest,
  faShippingFastLight: faShippingFastLight,
  faPlusCircle: faPlusCircle,
  faMinusCircle: faMinusCircle,
  faCheck: faCheck,
  faCheckBold: faCheckBold,
  faTriangleExclamation: faTriangleExclamation,
  faDownToLine: faDownToLine,
  faCircleXmark: faCircleXmark,
};

export const getIcon = (iconName) => {
  const icon = icons[iconName];
  return icon ? icon : faUnicorn;
};
