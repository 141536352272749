import _ from 'lodash';

import { insertUserAttributions } from '..';
import { UserClient } from '../../api/auth';
import { Attribution, UserAttribution } from '../../types/attribution';
import { LocalStorageKeys } from '../../types/enums/LocalStorageEnums';
import { SessionContextStackModel, SessionCreationStackModel } from '../../types/session';
import {
  createLocalArrayStorageService,
  createLocalStorageService,
  IArrayStorageService,
  IStorageService,
} from '../localStorage';

export interface IUserClientContextService {
  clientEmail: string | undefined;
  isClientEmailVerified: boolean;

  currentUserClient: UserClient | undefined;
  currentUserClientUpdatedAt: number | undefined;
  currentUserAttributions: UserAttribution | undefined;
  pushUserAttributions: (inboundAttributions: Array<Attribution> | null) => void;
  handleSessionEmail: (email: string | null, isEmailVerified: boolean) => void;
  xatClient: string | undefined;

  pushCurrentSessionContext: (session: SessionContextStackModel) => void;
  getLastSessionContext: () => SessionContextStackModel | undefined;
  pushSessionCreationContext: (session: SessionCreationStackModel) => void;
  getLastSessionCreationContext: () => SessionCreationStackModel | undefined;
  getSessionCreationContextForParams: (queryStringParams: string) => SessionCreationStackModel | undefined;
  isLastSessionCreationContextReferral: () => boolean;
  hasSessionCreationContext: () => boolean;
}

class UserClientContextService implements IUserClientContextService {
  private clientEmailStore: IStorageService<string> = createLocalStorageService<string>(LocalStorageKeys.ClientEmail);
  private clientEmailVerifiedStore: IStorageService<string> = createLocalStorageService<string>(
    LocalStorageKeys.ClientEmailVerified,
  );
  private userClientStore: IStorageService<UserClient> = createLocalStorageService<UserClient>(
    LocalStorageKeys.UserClient,
  );
  private UserClientUpdatedAtStore: IStorageService<string> = createLocalStorageService<string>(
    LocalStorageKeys.UserClientUpdatedAt,
  );
  private userAttributionStore: IStorageService<UserAttribution> = createLocalStorageService<UserAttribution>(
    LocalStorageKeys.UserAttribution,
  );
  private sessionContextStackStore: IArrayStorageService<SessionContextStackModel> =
    createLocalArrayStorageService<SessionContextStackModel>(LocalStorageKeys.SessionContextStack);
  private sessionCreationStackStore: IArrayStorageService<SessionCreationStackModel> =
    createLocalArrayStorageService<SessionCreationStackModel>(LocalStorageKeys.SessionCreationStack);
  private xatClientStore: IStorageService<string> = createLocalStorageService<string>(LocalStorageKeys.XatClient);

  public get clientEmail() {
    return this.clientEmailStore.isPresent() ? this.clientEmailStore.get() : undefined;
  }
  public set clientEmail(value: string | undefined) {
    if (value == null) {
      this.clientEmailStore.remove();
    } else {
      this.clientEmailStore.set(value);
    }
  }

  public get isClientEmailVerified() {
    return this.clientEmailVerifiedStore.isPresent() ? JSON.parse(this.clientEmailVerifiedStore.get()) === true : false;
  }
  public set isClientEmailVerified(value: boolean) {
    this.clientEmailVerifiedStore.set(JSON.stringify(value));
  }

  public get currentUserClient(): UserClient | undefined {
    return this.userClientStore.get();
  }
  public set currentUserClient(value: UserClient | undefined) {
    if (value == null) {
      this.userClientStore.clear();
      this.currentUserClientUpdatedAt = undefined;
    } else {
      this.userClientStore.set(value);
      this.currentUserClientUpdatedAt = Date.now();
    }
  }

  public get currentUserClientUpdatedAt(): number | undefined {
    return this.UserClientUpdatedAtStore.isPresent()
      ? (JSON.parse(this.UserClientUpdatedAtStore.get()) as number)
      : undefined;
  }
  public set currentUserClientUpdatedAt(value: number | undefined) {
    if (value == null) {
      this.UserClientUpdatedAtStore.clear();
    } else {
      this.UserClientUpdatedAtStore.set(JSON.stringify(value));
    }
  }
  public get xatClient() {
    return this.xatClientStore.isPresent() ? this.xatClientStore.get() : undefined;
  }
  public set xatClient(value: string | undefined) {
    if (value == null) {
      this.xatClientStore.remove();
    } else {
      this.xatClientStore.set(value);
    }
  }

  pushCurrentSessionContext = (session: SessionContextStackModel): void => {
    let newStack = this.sessionContextStackStore.get();

    //Remove modifies the original list, returning the matches as an array
    _.remove(
      newStack,
      (x) =>
        //by session UID match
        x.sessionUid === session.sessionUid,
    );

    //Last item in stack is latest
    newStack.push(session);

    this.sessionContextStackStore.set(newStack);
  };

  handleSessionEmail = (email: string | null, isEmailVerified: boolean): void => {
    if (email != null && this.isLastSessionCreationContextReferral() === false) {
      const previousEmail = this.clientEmail;
      const isPreviousEmailVerified = this.isClientEmailVerified;
      if (email !== previousEmail) {
        if (isEmailVerified || isPreviousEmailVerified === false) {
          this.clientEmail = email;

          if (isEmailVerified !== isPreviousEmailVerified) this.isClientEmailVerified = isEmailVerified;
        }
      }
    } else {
      //ignore it
    }
  };

  //Last item in stack is latest
  getLastSessionContext = (): SessionContextStackModel | undefined => _.last(this.sessionContextStackStore.get());

  pushSessionCreationContext = (creationModel: SessionCreationStackModel): void => {
    if (_.isEmpty(creationModel.queryStringParams)) return;

    let newStack = this.sessionCreationStackStore.get();

    //Remove modifies the original list, returning the matches as an array
    _.remove(
      newStack,
      (x) =>
        //by session UID match
        x.queryStringParams === creationModel.queryStringParams,
    );

    //Last item in stack is latest
    newStack.push(creationModel);

    this.sessionCreationStackStore.set(newStack);
  };

  //Last item in stack is latest
  getLastSessionCreationContext = (): SessionCreationStackModel | undefined =>
    _.last(this.sessionCreationStackStore.get());

  getSessionCreationContextForParams = (queryStringParams: string): SessionCreationStackModel | undefined => {
    return _.find(
      this.sessionCreationStackStore.get(),
      (x) => x.queryStringParams.toLowerCase() === queryStringParams.toLowerCase(),
    );
  };

  isLastSessionCreationContextReferral = (): boolean => {
    return this.getLastSessionCreationContext()?.isReferral === true;
  };

  hasSessionCreationContext = (): boolean => {
    return Boolean(this.getLastSessionCreationContext());
  };

  public get currentUserAttributions(): UserAttribution | undefined {
    return this.userAttributionStore.get();
  }
  public set currentUserAttributions(value: UserAttribution | undefined) {
    if (value == null) {
      this.userAttributionStore.clear();
    } else {
      this.userAttributionStore.set(value);
    }
  }

  pushUserAttributions = (inboundAttributions: Attribution[] | null) => {
    const userAttributions = this.currentUserAttributions ?? ({ attributions: [] } as UserAttribution);
    insertUserAttributions(userAttributions, inboundAttributions);
    this.currentUserAttributions = userAttributions;
  };
}

export const userClientContextService: IUserClientContextService = new UserClientContextService();
