import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Chip from '@mui/material/Chip';

import { getIcon } from '../../../../utility/icon';

interface Props {
  value: string;
  valid: boolean;
  testId?: string;
  sx?: any;
}
//faCircleCheck
const percentageBadge = ({ value, valid, testId, sx }: Props) => {
  return (
    <Chip
      sx={{ maxWidth: '6rem', ...sx }}
      icon={
        valid ? (
          <FontAwesomeIcon icon={getIcon('faCircleCheck')} />
        ) : (
          <FontAwesomeIcon icon={getIcon('faCircleXmark')} />
        )
      }
      label={`${value}%`}
      color={valid ? 'success' : 'error'}
      data-testid={testId ?? 'percentage-badge-chip'}
    />
  );
};

export default percentageBadge;
