import _ from 'lodash';

import { getQueryParams, setDocumentSubtitle, setQueryParams, setQueryParamString } from '..';
import { VegaMuiThemeTypes } from '../../themes/theme';
import { SessionStorageKeys } from '../../types/enums/LocalStorageEnums';
import {
  ClientRoutingAction,
  ClientRoutingFlags,
  ClientRoutingModel,
  ResumeSessionScope,
  Session,
  SessionContextStackModel,
} from '../../types/session';
import { createSessionStorageService, IStorageService } from '../localStorage';
import { userClientContextService } from '../userClientContext';

export interface ISessionContextService {
  theme: string | undefined;
  isFrame: boolean;
  currentSession: Session | undefined;
  currentSessionToken: string | undefined;
  readonly currentEmail: string | undefined;
  readonly isCurrentEmailVerified: boolean;
  getCurrentTroubleshootingInfo: () => string;
  updateClientContextServiceValues: () => void;
  processQueryStringParameters: () => void;
  getStartupClientRoutingModel: () => ClientRoutingModel;
  getNewSessionCreationParams: () => Object;
  getRequestResumeSession: () => any;
}

class SessionContextService implements ISessionContextService {
  private isFrameStore: IStorageService<string> = createSessionStorageService<string>(SessionStorageKeys.IsFrame);
  public get isFrame(): boolean {
    return this.isFrameStore.isPresent() ? JSON.parse(this.isFrameStore.get()) === true : false;
  }
  public set isFrame(value: boolean) {
    this.isFrameStore.set(JSON.stringify(value));
  }

  private themeStore: IStorageService<string> = createSessionStorageService<string>(SessionStorageKeys.Theme);
  public get theme(): string {
    return this.themeStore.get() ?? VegaMuiThemeTypes.Light;
  }
  public set theme(value: string) {
    if (value == null) {
      this.themeStore.remove();
    } else {
      this.themeStore.set(value);
    }
  }

  private currentSessionStore: IStorageService<Session> = createSessionStorageService<Session>(
    SessionStorageKeys.CurrentSession,
  );
  public get currentSession(): Session | undefined {
    return this.currentSessionStore.isPresent() ? this.currentSessionStore.get() : undefined;
  }
  public set currentSession(value: Session | undefined) {
    if (value == null) {
      this.currentSessionStore.remove();
      setDocumentSubtitle(''); //removes subtitle
    } else {
      this.currentSessionStore.set(value);

      setDocumentSubtitle(value.onDemandConfig?.gpBusiness);

      this.updateClientContextServiceValues();
    }
  }

  public get currentSessionToken(): string | undefined {
    return this.currentSession?.sessionToken;
  }
  public set currentSessionToken(value: string | undefined) {
    let updatedSession = this.currentSession;
    updatedSession = {
      ...updatedSession,
      sessionToken: value,
    } as Session;
    this.currentSession = updatedSession;
  }

  public get currentEmail(): string | undefined {
    return this.currentSession?.sessionInfo.email ?? userClientContextService.clientEmail;
  }

  public get isCurrentEmailVerified(): boolean {
    return this.currentSession?.sessionInfo.isEmailVerified ?? userClientContextService.isClientEmailVerified;
  }

  updateClientContextServiceValues = (): void => {
    const currentSession = this.currentSession;

    if (currentSession) {
      const stackModel: SessionContextStackModel = {
        sessionUid: currentSession.sessionUid,
        queryStringParams: currentSession.sessionInfo?.creationParams ?? null,
      };

      userClientContextService.pushCurrentSessionContext(stackModel);

      userClientContextService.handleSessionEmail(
        currentSession.sessionInfo.email,
        currentSession.sessionInfo.isEmailVerified,
      );
    }
  };

  getCurrentTroubleshootingInfo = (): string => {
    const session = this.currentSession;

    //the string 'null' is printed. A null value will not print that property
    const model = {
      UserClientUid: userClientContextService.currentUserClient?.userClientUid ?? 'null',
      SessionUid: session?.sessionInfo?.sessionUid ?? 'null',
      ConfigId: session?.onDemandConfig?.id ?? 'null',
      PhaseId: session?.payload?.id ?? 'null',
      Email: this.currentEmail,
      Timestamp: new Date().toISOString(),
    };

    let result = '';
    for (const [key, value] of Object.entries(model)) {
      if (value != null) {
        if (result.length > 0) result += ', ';

        result += `${key}: ${value}`;
      }
    }

    return result;
  };

  processQueryStringParameters = (): ClientRoutingFlags => {
    let params: any = getQueryParams();
    let hasParamUpdate: boolean = false;
    const result = {
      showSessions: false, //Default to creating new sessions if we have the parameters to do so. See Story #39125
    } as ClientRoutingFlags;

    if (!_.isEmpty(params.theme)) {
      this.theme = params.theme;
      hasParamUpdate = true;
      params.theme = undefined;
    }

    if (!_.isEmpty(params.create)) {
      result.showSessions = false;
      hasParamUpdate = true;
      params.create = undefined;
    }

    if (!_.isEmpty(params.sessions)) {
      result.showSessions = JSON.parse(params.sessions) === true;
      hasParamUpdate = true;
      params.sessions = undefined;
    }

    if (!_.isEmpty(params.xatClient)) {
      userClientContextService.xatClient = params.xatClient;
      hasParamUpdate = true;
      params.xatClient = undefined;
    }

    if (hasParamUpdate) {
      setQueryParams(params);
    }

    return result;
  };

  private startupClientRoutingModel: ClientRoutingModel | undefined;
  getStartupClientRoutingModel = (): ClientRoutingModel => {
    if (this.startupClientRoutingModel == null) {
      const routingFlags = this.processQueryStringParameters();

      const newModel = {
        clientRoutingAction: ClientRoutingAction.Unknown,
        queryStringParamObject: getQueryParams(),
      } as ClientRoutingModel;

      if (routingFlags.showSessions === true) {
        //we explicitly want to show sessions
        newModel.clientRoutingAction = ClientRoutingAction.SessionSelection;
      } else if (newModel.queryStringParamObject.login) {
        newModel.clientRoutingAction = ClientRoutingAction.LoginByParameter;
        newModel.queryStringParamObject = JSON.parse(window.atob(newModel.queryStringParamObject.login));
      } else if (newModel.queryStringParamObject.continue) {
        newModel.clientRoutingAction = ClientRoutingAction.ContinueSession;
      } else if (Object.keys(newModel.queryStringParamObject).length > 0) {
        //parameters are populated
        const previousSessionCreationContextForParams = userClientContextService.getSessionCreationContextForParams(
          window.location.search,
        );
        const lastSessionContext = userClientContextService.getLastSessionContext();

        if (
          //explicitly do not show sessions
          routingFlags.showSessions === false ||
          //we have not loaded a session before
          lastSessionContext == null ||
          //we know that these querystring parameters are for a referral config
          previousSessionCreationContextForParams?.isReferral === true
        ) {
          //If we have already used the params for referral creation, just create a new session as a convenience
          newModel.clientRoutingAction = ClientRoutingAction.CreateSession;
        } else {
          //If we don't know or have used it for a non-referral session, have the user choose
          newModel.clientRoutingAction = ClientRoutingAction.SessionSelection;
        }
      } else {
        //no parameters
        const lastSessionCreationContext = userClientContextService.getLastSessionCreationContext();
        const lastSessionContext = userClientContextService.getLastSessionContext();
        const currentSessionForTabReload = this.currentSession;

        //if the user is refreshing their tab
        //    reload the session already in the SessionStorage data
        //else if there was no previous created session or one was created but it's not a referral, and a previous session exists
        //    load the previous session in the session context stack
        //else if a previous session creation context exists
        //    create a new session
        //otherwise, leave it as unknown (show an error)
        if (currentSessionForTabReload != null) {
          newModel.clientRoutingAction = ClientRoutingAction.ResumeClientSession;
          newModel.targetSessionUid = currentSessionForTabReload!.sessionUid;
        } else if ((lastSessionCreationContext?.isReferral ?? false) === false && lastSessionContext != null) {
          newModel.clientRoutingAction = ClientRoutingAction.ResumeClientSession;
          newModel.targetSessionUid = lastSessionContext!.sessionUid;
        } else if (lastSessionCreationContext != null) {
          setQueryParamString(lastSessionCreationContext.queryStringParams); //set query string
          newModel.clientRoutingAction = ClientRoutingAction.CreateSession;
          newModel.queryStringParamObject = getQueryParams(); //reload query string
        }
      }

      this.startupClientRoutingModel = newModel;
    }

    return _.clone(this.startupClientRoutingModel); //return a copy of the original
  };

  //This is intended to be used when we want to create a new session at any point
  getNewSessionCreationParams = (): Object => {
    //current querystring parameters > startup params for tab > latest parameters used for creating a session
    const isLoginByParameter =
      this.startupClientRoutingModel!.clientRoutingAction === ClientRoutingAction.LoginByParameter;
    let currentQueryStringParams = getQueryParams();

    if (Object.keys(currentQueryStringParams).length === 0 || isLoginByParameter) {
      if (
        this.startupClientRoutingModel != null &&
        this.startupClientRoutingModel!.clientRoutingAction === ClientRoutingAction.CreateSession
      ) {
        currentQueryStringParams = this.startupClientRoutingModel.queryStringParamObject;
      }

      if (Object.keys(currentQueryStringParams).length === 0 || isLoginByParameter) {
        const lastCreationContext = userClientContextService.getLastSessionCreationContext();

        if (lastCreationContext != null) {
          currentQueryStringParams = getQueryParams(lastCreationContext.queryStringParams);
        }
      }

      //TODO: load the ultimate fallback value from environment settings?
      // if (Object.keys(currentQueryStringParams).length === 0) {
      //   currentQueryStringParams = getQueryParams('?channel=BuyersExperience&subchannel=SignUpNow_v2');
      // }
    }

    return currentQueryStringParams;
  };

  getRequestResumeSession = (): any => {
    return {
      Scope: ResumeSessionScope.TARGET_SESSION,
      Email: this.currentEmail,
      TargetSessionUid: this.currentSession?.sessionUid ?? userClientContextService.getLastSessionContext()?.sessionUid,
      OnDemandConfigId: this.currentSession?.onDemandConfig?.id,
    };
  };
}

export const currentSessionContextService: ISessionContextService = new SessionContextService();
