import { debounce } from 'lodash';

type onChange = (questionId: number, value: any, shouldValidate: boolean, confirmationValue?: any) => void;

export const useFormQuestionDebounceOnChange = (onChange: onChange, delay: number = 300) => {
  return debounce(
    (questionId, newValue, hasChanged, confirmationValue = undefined) => {
      onChange(questionId, newValue, hasChanged, confirmationValue);
    },
    delay,
    { leading: true, trailing: true },
  );
};
