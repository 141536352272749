export enum LocalStorageKeys {
  ClientEmail = 'ClientEmail',
  ClientEmailVerified = 'ClientEmailVerified',
  CachedQuestionGroupValues = 'CachedQuestionGroupValues',
  SessionContextStack = 'SessionContextStack',
  SessionCreationStack = 'SessionCreationStack',
  UserAttribution = 'UserAttribution',
  UserClient = 'UserClient',
  UserClientUpdatedAt = 'UserClientUpdatedAt',
  XatClient = 'XatClient',
  DismissCopyCartModal = 'DismissCopyCartModal',
}

export enum SessionStorageKeys {
  CurrentSession = 'CurrentSession',
  Theme = 'Theme',
  IsFrame = 'IsFrame',
  BackButtonHostOverride = 'BackButtonHostOverride',
}

//list of allowed params to be saved. Type them as they should appear in browser url bar.
export const ParamsToStore = ['Channel', 'AAID', 'Code', 'SubChannel'];
