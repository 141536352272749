import { FormFieldTypes, TextMaskTypes } from '../enums/FormFieldTypes';
import { BasicSessionInfoModel } from '../session';

//#region Shared Equipment Base Types

//PLACE IN ORDER OF PAGE NAVIGATION SEQUENCE.
export enum EquipmentViews {
  Packages = 0,
  PackageDetail = 1,
  Cart = 2,
  ContactInfo = 3,
  Shipping = 4,
  Complete = 5,
  //PAGES AFTER THIS ARE NOT PART OF NAVIGATION SEQUENCE
  Loading = 6,
}

export type EquipmentSession = {
  sessionInfo: BasicSessionInfoModel;
  shippingMethod: ShippingMethodTypes | null;
  shippingAddressType: ShippingAddressTypes | null;
  verifiedTaxes: boolean;
  showTracker: boolean;
};

export type EquipmentAddresses = {
  dba?: EquipmentAddress;
  legal?: EquipmentAddress;
};

export type EquipmentAddress = {
  streetAddress: string;
  city: string;
  state: string;
  zipCode: string;
};

export interface PageNavigation {
  Next: (id?: number | undefined, packageName?: string | undefined) => void;
  Previous: (id?: number | undefined, packageName?: string | undefined) => void;
  StartOver: (id?: number | undefined, packageName?: string | undefined) => void;
}

//#endregion

//#region Price

export enum PriceRecurrenceFrequency {
  Monthly = 'Monthly',
  Yearly = 'Yearly',
}

export enum PriceType {
  BasePrice = 'BasePrice',
  SubscriptionPrice = 'SubscriptionPrice',
  FeePrice = 'FeePrice',
}

//#endregion

//#region Shipping

export type SessionInfo = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
};

export enum ShippingAddressTypes {
  DBA = 'DBA',
  Legal = 'Legal',
  Other = 'Other',
}

export enum ShippingMethodTypes {
  TwoDayAir = 'TwoDayAir',
  OneDayAir = 'OneDayAir',
}

export type ShippingMethodTypeHydrated = {
  name: string;
  price: number;
  shippingMethod: ShippingMethodTypes;
  fontAwesomeIcon: string;
};

export type ShippingInfo = {
  companyName: string | null;
  streetAddress: string | null;
  addressCity: string | null;
  addressState: string | null;
  addressZipCode: string | null;
  shippingMethod: ShippingMethodTypes;
  taxesVerified: boolean;
  shippingAddressType: ShippingAddressTypes;
};

export type ShippingInfoField = {
  id: string;
  label: string;
  value: any;
  error: string | null;
  mask: string;
  isRequired: boolean;
  placeholder: string;
  ordinal: number;
  icon: string;
  type: FormFieldTypes;
  validated?: Boolean;
  headerText?: string;
};

export type ContactInfoFields = {
  firstName: string | null;
  lastName: string | null;
  contactPhone: string | null;
  contactEmail: string | null;
};

export const shippingInfoFields: Array<ShippingInfoField> = [
  {
    id: 'addressBusinessName',
    label: 'Business Name',
    value: '',
    error: null,
    mask: 'Text',
    isRequired: true,
    placeholder: 'Business Name',
    ordinal: 1,
    icon: 'faBriefcase',
    type: FormFieldTypes.TEXT,
  },
  {
    id: 'streetAddress',
    label: 'Street Address',
    value: '',
    error: null,
    mask: 'Text',
    isRequired: true,
    placeholder: '',
    ordinal: 2,
    icon: 'faAddressCard',
    type: FormFieldTypes.ADDRESS,
  },
  {
    id: 'City',
    label: 'City',
    value: '',
    error: null,
    mask: 'Text',
    isRequired: true,
    placeholder: 'City',
    ordinal: 3,
    icon: 'faMapMarker',
    type: FormFieldTypes.TEXT,
  },
  {
    id: 'State',
    label: 'State',
    value: '',
    error: null,
    mask: 'Text',
    isRequired: true,
    placeholder: '',
    ordinal: 4,
    icon: 'faMapMarker',
    type: FormFieldTypes.TEXT,
  },
  {
    id: 'Zip',
    label: 'Zip or Postal Code',
    value: '',
    error: null,
    mask: TextMaskTypes.ZIPCODE,
    isRequired: true,
    placeholder: '',
    ordinal: 5,
    icon: 'faMapMarker',
    type: FormFieldTypes.TEXT,
  },
];

export type StateLabelValuePair = {
  label: string;
  value: string;
};
//#endregion

//#region CompleteOrder

export type CompleteOrderPayload = {
  shippingMethod: ShippingMethodTypes;
  shippingStreetAddress: string;
  shippingCity: string;
  shippingState: string;
  shippingZip: string;
  shippingAddressType: ShippingAddressTypes;
};

//#endregion

//#region Cart

export type LocalCartItem = {
  id: number;
  quantity: number;
  configValues: Array<ConfigurationOptionPayload>;
  accessoriesValues: Array<AccessorySelection>;
  priceOverride?: number | undefined;
};

export type CartPackageModel = {
  cartPackageId: number;
  equipmentPackageId: number;
  packageName: string;
  quantity: number;
  configurationOptions: Array<ConfigurationOptionPayload>;
  pricePaymentOptions: Array<CartPricePaymentOption>;
  accessories: Array<CartAccessory>;
  addOns: Array<Addon>;
  availableAddOns: Array<Addon>;
  createdUTC: Date;
  lastUpdatedUTC: Date;
  imageUrl: string;
  maxQuantity: number;
  inStock: boolean;
};

export type LandingPage = {
  heroTextHtml?: string | undefined;
  heroImageUrl?: string | undefined;
  heroPackageId?: number | undefined;
  packages: Package[] | null;
  err?: any;
};

export type CartAccessory = {
  packageAccessoryId: number;
  parentPackageAccessoryId?: number;
  equipmentId: number;
  equipmentName: string;
  equipmentFullName?: string;
  equipmentDescription: string;
  imageUrl: string;
  ordinal: number;
  priceType: PriceType;
  price: number;
  recurrenceFrequency: PriceRecurrenceFrequency;
  isRemoved: boolean;
};

export type CartPricePaymentOption = {
  packagePriceId: number;
  name: string;
  priceType: PriceType;
  price: number;
  originalPrice: number;
  recurrenceFrequency: PriceRecurrenceFrequency;
  recurrenceCount?: number;
};

export type ModifyCartPayload = {
  selectedPackage: Package;
  selectedPriceOption: PriceOption;
  selectedAddons: Array<Addon>;
  configurations: Array<ConfigurationOptionPayload>;
  accessories: Array<AccessorySelection>;
  addOns: Array<Addon>;
  quantity: number;
  cartPackageId?: number;
};

export type UpdateCartPackageQuantity = {
  cartPackageId: number;
  quantity: number;
};

export type CartCopyOpportunity = {
  locationUid: string;
  locationName: string;
  locationOrdinal: number;
  itemDescriptors: Array<string>;
};

//#endregion Cart

//#region Addon

export type Addon = {
  equipmentAddOnId: number;
  quantity: number;
  equipmentId: number;
  equipmentName: string;
  equipmentDescription: string;
  ordinal: number;
  imageUrl: string;
  maxQuantity: number;
  addOnPrices: Array<AddonPrice>;
  dehydratedObject: AddonDehydratedObject;
};

export type AddonPrice = {
  equipmentAddOnPriceId: number;
  name: string;
  priceType: PriceType;
  price: number;
  recurrenceFrequency: PriceRecurrenceFrequency;
  recurrentCount: number;
};

export type AddonDehydratedObject = {
  EquipmentAddonOnId: number;
  quantity: number;
  equipmentId: number;
};

export type AddonPayload = {
  equipmentAddOnId: number;
  quantity: number;
};

export type EphemeralAddon = {
  id: number;
  quantity: number;
  cartPackageId: number;
  equipmentName: string;
};

//#endregion

//#region OrderSummary

export type OrderPriceSummary = {
  name: string;
  equipmentName?: string;
  discount?: number;
  price: number;
  quantity: number;
  priceSubTotal: number;
  originalPrice: number;
  priceType: string;
  recurrenceFrequency: string | null;
  recurrenceCount: number | null;
  isAddOn: boolean;
};

export type OrderSummary = {
  oneTimePurchaseItems: Array<OrderPriceSummary> | [];
  recurringItems: Array<OrderPriceSummary> | [];
  hardwareTotal: number;
  rentalTotal: number;
  estimatedTotal: number;
  shipping: number;
  taxes: number;
  err?: any;
};

//#endregion

//#region Package Configuration

export type PackageConfig = {
  cartPackageId?: number;
  equipmentPackageId: number;
  pricePaymentOptions: Array<{ packagePriceId: number | undefined }>;
  addOns: Array<AddonPayload>;
  quantity: number;
  configurationOptions: Array<ConfigurationOptionPayload>;
  accessories: Array<any>; //This will need to be updated once we have the accessories config added.
};

//#endregion

//#region Equipment

export type Equipment = {
  equipmentId: number;
  name: string;
  fullName?: string;
  description: string;
  logisticsDID: number;
  imageUrls: Array<string>;
  equipmentConfigQuestions: Array<any>;
};

export type ImageUrl = {
  ordinal?: number;
  hero?: string;
  thumbnail?: string;
  large?: string;
  video?: string;
};

//#endregion

//#region Accessory

export type Accessory = {
  packageAccessoryId: number;
  packageId: number;
  equipmentId: number;
  parentPackageAccessoryId?: number;
  ordinal: number;
  quantity: number;
  equipment: Equipment;
  showPreview: boolean;
  packageAccessoryPrices: Array<AccessoryPrice>;
};

//used on the actual component
export type AccessorySelection = {
  packageAccessoryId: number;
  price: number;
  priceType: string;
};

export type AccessoryPrice = {
  packageAccessoryPriceId: number;
  packageAccessoryId: number;
  name: string;
  channelType: string;
  priceType: string;
  price: number;
  recurrenceFrequency: PriceRecurrenceFrequency;
  recurrenceCount: number;
};

export type GroupedAccessories = {
  firstAccessory: CartAccessory;
  secondAccessory: CartAccessory;
};

//#endregion

//#region Configuration

export type ConfigurationOption = {
  equipmentConfigOptionId: number;
  equipmentConfigQuestionId: number;
  isDefaultOption: boolean;
  value: string;
  label: string;
};

export type ConfigurationOptionPayload = {
  equipmentConfigQuestionId: number;
  value: string;
};

export enum ConfigurationType {
  AutoClose = 'AutoClose',
  AdministrationEmail = 'Text',
}

export type ConfigurationQuestion = {
  autoComplete: string;
  fieldType: ConfigurationType;
  fieldDefinition: string;
  fontAwesomeIcon?: string;
  isSharedPackageValue: boolean;
  isRequired: boolean;
  label: string;
  ordinal: number;
  configQuestionOptions: Array<ConfigurationOption>;
  equipmentConfigQuestionId: number;
  questionGroupId: number;
  value: any;
  error: boolean;
  errorText: string;
  complete: boolean;
  open: boolean;
};

export type PackageConfiguration = {
  configValues: Array<ConfigurationOptionPayload>;
  accessoriesValues: Array<AccessorySelection>;
  quantity: number;
  cartPackageId?: number;
  uuid?: string;
};
//#endregion

//#region Features

export type Feature = {
  ordinal: number;
  description: string;
  fontAwesomeIcon: string;
};

export type FeatureTitle = {
  title: string;
  values: Array<FeatureMatrixValue>;
};

export type FeatureMatrixValue = {
  title: string;
  values: Array<number>;
};

export type FeatureProductSelect = {
  title: string;
  picture: string;
  id: number;
};

export type Features = {
  products: Array<FeatureProductSelect>;
  features: Array<FeatureTitle>;
};

//#endregion

//#region Package

export type Package = {
  packageId: number;
  productCategory: string;
  name: string;
  features: Array<Feature>;
  location: string;
  imageUrls: Array<ImageUrl>;
  salesText?: string;
  extendedSalesText: string;
  supportingDocumentationUrl: string;
  allowMultipleConfigurations: boolean;
  allowSubscription: boolean;
  detailsHtml: string;
  maxQuantity: number;
  minQuantity: number | null;
  ordinal: number;
  deviceDescription?: string;
  hasAccessories: boolean;
  packagePrices: Array<PriceOption>;
  packageEquipment: Array<any>;
  packageAccessories: Array<any>;
  isDisabled: boolean;
};

export type ConfigurationTotalResponse = {
  oneTimePrice?: number;
  monthlyPrice?: number;
};

//#endregion

//#region Price Option

export type PriceOption = {
  packagePriceId: number;
  packageId: number;
  name: string;
  channelType: string;
  priceType: PriceType;
  price: number;
  pricingDescription?: string;
  recurrenceFrequency: PriceRecurrenceFrequency;
  recurrenceCount: number;
  inStock: boolean;
  priceRanges: PriceRange[];
};

export type PriceRange = {
  flatDiscount: number;
  maxQuantity: number | null;
  minQuantity: number | null;
  price: number;
};

//#endregion
