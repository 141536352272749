import * as dateFns from 'date-fns';
import * as dateFnsTz from 'date-fns-tz';
import _, { isEmpty } from 'lodash';

import { queryClient } from '../../App';
import { CHARACTER_LIMIT, FormFieldTypes, KVPTypes, TextMaskTypes } from '../../types/enums/FormFieldTypes';
import { ValidationResults } from '../../types/enums/ValidationResults';
import { FieldValue, ProportionOption, ValidationResult } from '../../types/form';
import { MCC } from '../../types/form';
import { PhaseQueries } from '../../types/queries';
import { InputValidationResult, InputValidationStatus } from '../../types/verification';
import {
  convertStringToDate,
  countDecimals,
  invalidString,
  stripPhoneMask,
  validateAddressString,
  validateAlphaNumericString,
  validateEmailString,
  validateNameString,
  validatePhoneNumberString,
  validateUrlString,
  validateZipCodeString,
} from '../../utility';

const successResult: ValidationResult = {
  validationError: false,
  validationErrorText: '',
};

export const ValidationErrorTextOptions = {
  DEFAULT: ' is required.',
  CHARACTER_LIMIT: ` exceeds ${CHARACTER_LIMIT} character limit.`,
  FORMAT: ' has an invalid value.',
  MULTIPLE_SELECTIONS: ' cannot have multiple selected options.',
  NUMBER_LOWER_BOUNDARY: ' cannot be lower than ',
  NUMBER_UPPER_BOUNDARY: ' cannot be higher than ',
  NUMBER_LOWER_LENGTH: ' did not meet required length of ',
  NUMBER_UPPER_LENGTH: ' exceeded maximum length of ',
  NUMBER_BOUNDARY: ' was not within allowable minimum and maximum values.',
  NUMBER_PRECISION: ' exceeded the allowable amount of decimal places.',
  NO_OPTION_SELECTED: ' has no option selected.',
  NO_LABEL_NO_OPTION: 'Please select an option.',
  INVALID_DATE: ' should be a valid date.',
  INVALID_TIME: ' should be a valid time.',
  DATE_LOWER_BOUNDARY: ' cannot be before ',
  DATE_UPPER_BOUNDARY: ' cannot be after ',
  DATE_IN_FUTURE: ' cannot be in the future.',
  DATE_TOO_OLD: " can't be before 01/01/1900.",
  DATE_RANGE: ' is not a valid date range.',
  TIME_RANGE: ' is not a valid time range.',
  MISSING_LOCATION_FIELD: 'Missing required shipping field(s).',
  TIME_REVERSE_RANGE: ' beginning time is greater or equal to end time.',
  TIME_INCLUDE_AM_PM: ' is not complete. Include AM or PM, hours and minutes.',
  PROPORTION_FORMAT: ' options should be numeric only.',
  PROPORTION_TOTAL: ' options do not equal required total of ',
  URL_FORMAT: ' is not a valid URL. Please re enter with the correct formatting: "https://exampleurl.com"',
  PO_BOX: 'We are unable to ship equipment to a P.O. Box, please enter a valid ',
  ACCOUNT_NUMBER_DOES_NOT_MATCH: 'Account Number does not match.',
  TOTAL_OWNERSHIP_PERCENTAGE: 'Total ownership percentage must not exceed 100%',
};

export const validateFieldValue = (field: FieldValue): ValidationResult => {
  const methods = {
    [FormFieldTypes.KVP]: { process: (e: FieldValue) => validateKVPField(e) },
    [FormFieldTypes.PARTNER_ECOMM_SOFTWARE]: { process: (e: FieldValue) => validateKVPField(e) },
    [FormFieldTypes.PARTNER_GPI_SOFTWARE]: { process: (e: FieldValue) => validateKVPField(e) },
    [FormFieldTypes.STATE]: { process: (e: FieldValue) => validateKVPField(e) },
    [FormFieldTypes.MCC]: { process: (e: FieldValue) => validateMCCValue(e.value) },
    [FormFieldTypes.NUMBER]: { process: (e: FieldValue) => validateNumberField(e) },
    [FormFieldTypes.TEXT]: { process: (e: FieldValue) => validateTextField(e) },
    [FormFieldTypes.PROPORTION]: { process: (e: FieldValue) => validateProportionField(e) },
    [FormFieldTypes.DATE]: { process: (e: FieldValue) => validateDateField(e) },
    [FormFieldTypes.DATE_RANGE]: { process: (e: FieldValue) => validateDateRange(e) },
    [FormFieldTypes.TIME_RANGE]: { process: (e: FieldValue) => validateTimeRange(e) },
    [FormFieldTypes.URL]: { process: (e: FieldValue) => validateUrlField(e) },
    [FormFieldTypes.NAME]: { process: (e: FieldValue) => validateNameField(e) },
    [FormFieldTypes.ALPHA_NUMERIC]: { process: (e: FieldValue) => validateAlphaNumericField(e) },
    [FormFieldTypes.ADDRESS]: { process: (e: FieldValue) => validateAddressField(e) },
    [FormFieldTypes.SMART_LOCATION]: {
      process: (e: FieldValue) => validateAddressField({ ...e, value: e.value.address ?? '' }),
    },
    [FormFieldTypes.AUTO_CLOSE]: {
      process: (e: FieldValue) => validateTimeField(e),
    },
    [FormFieldTypes.EQUIPMENT]: {
      process: () => successResult,
    },
    [FormFieldTypes.MATRIX]: {
      process: () => successResult,
    },
    [FormFieldTypes.SLIDER]: {
      process: () => successResult,
    },
    [FormFieldTypes.EMAIL_VALIDATOR]: {
      process: (e: FieldValue) => validateEmailValidatorField(e),
    },
    [FormFieldTypes.HIDDEN]: {
      process: () => successResult,
    },
    [FormFieldTypes.BANK_ROUTING_VALIDATOR]: {
      process: (e: FieldValue) => validateBankRouting(e),
    },
    [FormFieldTypes.ACH_DISTRIBUTION]: {
      process: (e: FieldValue) => validateProportionField(e),
    },
  };

  return methods[field.fieldType].process(field);
};

const validateKVPField = (field: FieldValue): ValidationResult => {
  let validationResult;
  const fieldDefinitionJson = JSON.parse(field.fieldDefinition);
  if (field.fieldType === FormFieldTypes.STATE) validationResult = validateKVPValue(field);
  else if (field.fieldType === FormFieldTypes.PARTNER_ECOMM_SOFTWARE) validationResult = validateKVPValue(field);
  else if (field.fieldType === FormFieldTypes.PARTNER_GPI_SOFTWARE) validationResult = validateKVPValue(field);
  else
    switch (fieldDefinitionJson?.KVPType) {
      case KVPTypes.BUTTON: //KVP buttons have different validation messages than other KVP components
        validationResult = validateKVPButtonValue(field);
        break;
      default: //all other KVP types perform the same validations
        validationResult = validateKVPValue(field);
        break;
    }

  return validationResult;
};

const validateKVPValue = (field: FieldValue): ValidationResult => {
  const fieldDefinitionJson = JSON.parse(
    field.fieldDefinition && field.fieldDefinition !== '' ? field.fieldDefinition : '{}',
  );

  if (field.isRequired && (!field.value || field.value.length < 1 || field.value[0] === '')) {
    return {
      validationError: true,
      validationErrorText: `${fieldDefinitionJson.ValidationTitle || field.label}${ValidationErrorTextOptions.DEFAULT}`,
    };
  }

  //Multiple values, when the field is single select
  if (
    Array.isArray(field.value) &&
    (!fieldDefinitionJson?.MultiSelect ||
      fieldDefinitionJson?.KVPType === KVPTypes.RADIO || //force single select for Radio
      fieldDefinitionJson?.KVPType === KVPTypes.CHECKBOXTOGGLE) //force single select for CheckboxToggle
  ) {
    return {
      validationError: true,
      validationErrorText: `${fieldDefinitionJson.ValidationTitle || field.label}${
        ValidationErrorTextOptions.MULTIPLE_SELECTIONS
      }`,
    };
  }

  return successResult;
};

const validateKVPButtonValue = (field: FieldValue): ValidationResult => {
  const isMultiSelect = Array.isArray(field.value);

  //KVP Buttons have different error messages shown when no option is selected than other KVP types
  if (field.isRequired && !isMultiSelect && isEmpty(field.value)) {
    const errorText = !isEmpty(field.label)
      ? `${field.label}${ValidationErrorTextOptions.NO_OPTION_SELECTED}`
      : ValidationErrorTextOptions.NO_LABEL_NO_OPTION;
    return {
      validationError: true,
      validationErrorText: errorText,
    };
  }

  if (field.isRequired && isMultiSelect && isEmpty(field.value[0])) {
    const errorText = !isEmpty(field.label)
      ? `${field.label}${ValidationErrorTextOptions.NO_OPTION_SELECTED}`
      : ValidationErrorTextOptions.NO_LABEL_NO_OPTION;
    return {
      validationError: true,
      validationErrorText: errorText,
    };
  }

  return successResult;
};

const validateTimeField = (field: FieldValue): ValidationResult => {
  const fieldDefinitionJson = JSON.parse(field.fieldDefinition);
  const timeSplit = field.value.split(' ').filter(Boolean);

  if (field.isRequired || field.value.length > 0) {
    //-- Validate value --//
    if ((fieldDefinitionJson?.withTimeZone && timeSplit.length < 2) || timeSplit.length === 0) {
      return {
        validationError: true,
        validationErrorText: `${field.label}${ValidationErrorTextOptions.DEFAULT}`,
      };
    }

    //-- Validate format --//
    const myTimeFormatted = timeSplit[0];

    const timeReg = /^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;
    if (!timeReg.test(myTimeFormatted)) {
      return {
        validationError: true,
        validationErrorText: `${field.label}${ValidationErrorTextOptions.INVALID_TIME}`,
      };
    }
  }

  return successResult;
};

const validateEmailValidatorField = (field: FieldValue): ValidationResult => {
  let validationResult = successResult;
  if (field.isRequired || field.value.length > 0) {
    validationResult = validateEmailValue(field);
  } else {
    validationResult = successResult;
  }
  return validationResult;
};

const validateTextField = (field: FieldValue): ValidationResult => {
  let validationResult;
  const fieldDefinitionJson = JSON.parse(
    field.fieldDefinition && field.fieldDefinition !== '' ? field.fieldDefinition : '{}',
  );

  switch (fieldDefinitionJson?.MaskType) {
    case TextMaskTypes.EMAIL:
      if (field.isRequired || field.value.length > 0) {
        validationResult = validateEmailValue(field);
      } else {
        validationResult = successResult;
      }
      break;
    case TextMaskTypes.PHONE:
      if (field.isRequired || stripPhoneMask(field.value).length > 0) {
        validationResult = validatePhoneNumberValue(field);
      } else {
        validationResult = successResult;
      }
      break;
    case TextMaskTypes.TAX_ID:
      if (field.isRequired || field.value.replace('-', '').replace(' ', '').length > 0) {
        validationResult = validateTaxID(field);
      } else {
        validationResult = successResult;
      }
      break;
    case TextMaskTypes.ZIPCODE:
      if (field.isRequired || field.value.trim().length > 0) {
        validationResult = validateZipCodeValue(field);
      } else {
        validationResult = successResult;
      }
      break;
    default:
      if (field.isRequired || field.value.length > 0) {
        validationResult = validateTextValue(field);
      } else {
        validationResult = successResult;
      }
      break;
  }
  if (fieldDefinitionJson?.RequiresConfirmation) {
    if (field.value?.toUpperCase() !== field.confirmationValue?.toUpperCase()) {
      validationResult = {
        ...validationResult,
        confirmationError: true,
      };
    } else validationResult = { ...validationResult, confirmationError: false };
  }

  if (field.isSensitive) validationResult = successResult;

  return validationResult;
};

const validateNameField = (field: FieldValue): ValidationResult => {
  const fieldDefinitionJson = JSON.parse(
    field.fieldDefinition && field.fieldDefinition !== '' ? field.fieldDefinition : '{}',
  );

  if (field.isSensitive) return successResult;

  if (field.isRequired || field.value !== '') {
    switch (validateNameString(field.value)) {
      case ValidationResults.VALID:
        return successResult;
      case ValidationResults.MAX_LIMIT:
        return {
          validationError: true,
          validationErrorText: `${fieldDefinitionJson.ValidationTitle || field.label}${
            ValidationErrorTextOptions.CHARACTER_LIMIT
          }`,
        };
      case ValidationResults.NAME_FORMAT:
        return {
          validationError: true,
          validationErrorText: `${fieldDefinitionJson.ValidationTitle || field.label}${
            ValidationErrorTextOptions.FORMAT
          }`,
        };
      default:
        if (field.isRequired)
          return {
            validationError: true,
            validationErrorText: `${fieldDefinitionJson.ValidationTitle || field.label}${
              ValidationErrorTextOptions.DEFAULT
            }`,
          };
        else return successResult;
    }
  } else return successResult;
};

const validateAlphaNumericField = (field: FieldValue): ValidationResult => {
  let validationResult;
  const fieldDefinitionJson = JSON.parse(
    field.fieldDefinition && field.fieldDefinition !== '' ? field.fieldDefinition : '{}',
  );

  if (field.isSensitive) return successResult;

  if (field.isRequired || field.value.length > 0) {
    switch (validateAlphaNumericString(field.value)) {
      case ValidationResults.VALID:
        validationResult = successResult;
        break;
      case ValidationResults.MAX_LIMIT:
        validationResult = {
          validationError: true,
          validationErrorText: `${fieldDefinitionJson.ValidationTitle || field.label}${
            ValidationErrorTextOptions.CHARACTER_LIMIT
          }`,
        };
        break;
      case ValidationResults.ALPHA_NUMERIC_FORMAT:
        validationResult = {
          validationError: true,
          validationErrorText: `${fieldDefinitionJson.ValidationTitle || field.label}${
            ValidationErrorTextOptions.FORMAT
          }`,
        };
        break;
      default:
        validationResult = {
          validationError: true,
          validationErrorText: `${fieldDefinitionJson.ValidationTitle || field.label}${
            ValidationErrorTextOptions.DEFAULT
          }`,
        };
    }
  } else {
    validationResult = successResult;
  }

  if (fieldDefinitionJson?.RequiresConfirmation) {
    if (field.value?.toUpperCase() !== field.confirmationValue?.toUpperCase()) {
      validationResult = {
        ...validationResult,
        confirmationError: true,
      };
    } else validationResult = { ...validationResult, confirmationError: false };
  }

  return validationResult;
};

const validateAddressField = (field: FieldValue): ValidationResult => {
  if (field.isRequired || field.value !== '') {
    const fieldDefinitionJson = JSON.parse(
      field.fieldDefinition && field.fieldDefinition !== '' ? field.fieldDefinition : '{}',
    );

    if (field.isSensitive) return successResult;

    switch (validateAddressString(field.value)) {
      case ValidationResults.VALID:
        return successResult;
      case ValidationResults.MAX_LIMIT:
        return {
          validationError: true,
          validationErrorText: `${fieldDefinitionJson.ValidationTitle || field.label}${
            ValidationErrorTextOptions.CHARACTER_LIMIT
          }`,
        };
      case ValidationResults.PO_BOX:
        return {
          validationError: true,
          validationErrorText: `${ValidationErrorTextOptions.PO_BOX}${
            fieldDefinitionJson.ValidationTitle || field.label
          }.`,
        };
      default:
        if (field.isRequired)
          return {
            validationError: true,
            validationErrorText: `${fieldDefinitionJson.ValidationTitle || field.label}${
              ValidationErrorTextOptions.DEFAULT
            }`,
          };
        else return successResult;
    }
  } else return successResult;
};

const validateUrlField = (field: FieldValue): ValidationResult => {
  const fieldDefinitionJson = JSON.parse(
    field.fieldDefinition && field.fieldDefinition !== '' ? field.fieldDefinition : '{}',
  );

  if (field.isSensitive) return successResult;

  if (field.isRequired || field.value !== '') {
    switch (validateUrlString(field.value)) {
      case ValidationResults.VALID:
        return successResult;
      case ValidationResults.MAX_LIMIT:
        return {
          validationError: true,
          validationErrorText: `${fieldDefinitionJson.ValidationTitle || field.label}${
            ValidationErrorTextOptions.CHARACTER_LIMIT
          }`,
        };
      case ValidationResults.URL_FORMAT:
        return {
          validationError: true,
          validationErrorText: `${fieldDefinitionJson.ValidationTitle || field.label}${
            ValidationErrorTextOptions.URL_FORMAT
          }`,
        };
      default:
        if (field.isRequired)
          return {
            validationError: true,
            validationErrorText: `${fieldDefinitionJson.ValidationTitle || field.label}${
              ValidationErrorTextOptions.DEFAULT
            }`,
          };
        else return successResult;
    }
  } else return successResult;
};

const validateNumberField = (field: FieldValue): ValidationResult => {
  const fieldDefinitionJson = JSON.parse(
    field.fieldDefinition && field.fieldDefinition !== '' ? field.fieldDefinition : '{}',
  );

  let validationResult: ValidationResult = successResult;

  if (field.isRequired || field.value.length > 0) {
    if (fieldDefinitionJson?.Min !== undefined && field.value < fieldDefinitionJson.Min) {
      validationResult = {
        validationError: true,
        validationErrorText: `${fieldDefinitionJson.ValidationTitle || field.label}${
          ValidationErrorTextOptions.NUMBER_LOWER_BOUNDARY
        }${fieldDefinitionJson.Min}.`,
      };
    }

    if (fieldDefinitionJson?.Max !== undefined && field.value > fieldDefinitionJson.Max) {
      validationResult = {
        validationError: true,
        validationErrorText: `${fieldDefinitionJson.ValidationTitle || field.label}${
          ValidationErrorTextOptions.NUMBER_UPPER_BOUNDARY
        }${fieldDefinitionJson.Max}.`,
      };
    }

    if (fieldDefinitionJson?.MinLength !== undefined && field.value.toString().length < fieldDefinitionJson.MinLength) {
      validationResult = {
        validationError: true,
        validationErrorText: `${fieldDefinitionJson.ValidationTitle || field.label}${
          ValidationErrorTextOptions.NUMBER_LOWER_LENGTH
        }${fieldDefinitionJson.MinLength}.`,
      };
    }

    if (fieldDefinitionJson?.MaxLength !== undefined && field.value.toString().length > fieldDefinitionJson.MaxLength) {
      validationResult = {
        validationError: true,
        validationErrorText: `${fieldDefinitionJson.ValidationTitle || field.label}${
          ValidationErrorTextOptions.NUMBER_UPPER_LENGTH
        }${fieldDefinitionJson.MaxLength}.`,
      };
    }
  }

  if (
    field.value &&
    fieldDefinitionJson?.DecimalPlaces !== undefined &&
    fieldDefinitionJson.DecimalPlaces < countDecimals(field.value)
  ) {
    validationResult = {
      validationError: true,
      validationErrorText: `${fieldDefinitionJson.ValidationTitle || field.label}${
        ValidationErrorTextOptions.NUMBER_PRECISION
      }`,
    };
  }

  if (field.isRequired && field.value !== undefined && field.value.toString().length < 1) {
    validationResult = {
      validationError: true,
      validationErrorText: `${fieldDefinitionJson.ValidationTitle || field.label}${ValidationErrorTextOptions.DEFAULT}`,
    };
  }

  if (fieldDefinitionJson?.RequiresConfirmation) {
    if (field.value !== field.confirmationValue) {
      validationResult = {
        ...validationResult,
        confirmationError: true,
      };
    } else validationResult = { ...validationResult, confirmationError: false };
  }

  if (field.isSensitive) validationResult = successResult;

  return validationResult;
};

const validateTextValue = (field: FieldValue): ValidationResult => {
  const fieldDefinitionJson = JSON.parse(
    field.fieldDefinition && field.fieldDefinition !== '' ? field.fieldDefinition : '{}',
  );
  switch (invalidString(field.value)) {
    case ValidationResults.VALID:
      return successResult;
    case ValidationResults.MAX_LIMIT:
      return {
        validationError: true,
        validationErrorText: `${fieldDefinitionJson.ValidationTitle || field.label}${
          ValidationErrorTextOptions.CHARACTER_LIMIT
        }`,
      };
    default:
      return {
        validationError: true,
        validationErrorText: `${fieldDefinitionJson.ValidationTitle || field.label}${
          ValidationErrorTextOptions.DEFAULT
        }`,
      };
  }
};

const validateEmailValue = (field: FieldValue): ValidationResult => {
  const fieldDefinitionJson = JSON.parse(
    field.fieldDefinition && field.fieldDefinition !== '' ? field.fieldDefinition : '{}',
  );

  switch (validateEmailString(field.value)) {
    case ValidationResults.VALID:
      return successResult;
    case ValidationResults.MAX_LIMIT:
      return {
        validationError: true,
        validationErrorText: `${fieldDefinitionJson.ValidationTitle || field.label}${
          ValidationErrorTextOptions.CHARACTER_LIMIT
        }`,
      };
    case ValidationResults.EMAIL_FORMAT:
      return {
        validationError: true,
        validationErrorText: `${fieldDefinitionJson.ValidationTitle || field.label}${
          ValidationErrorTextOptions.FORMAT
        }`,
      };
    default:
      return {
        validationError: true,
        validationErrorText: `${fieldDefinitionJson.ValidationTitle || field.label}${
          ValidationErrorTextOptions.DEFAULT
        }`,
      };
  }
};

const validatePhoneNumberValue = (field: FieldValue): ValidationResult => {
  const fieldDefinitionJson = JSON.parse(
    field.fieldDefinition && field.fieldDefinition !== '' ? field.fieldDefinition : '{}',
  );

  switch (validatePhoneNumberString(field.value)) {
    case ValidationResults.VALID:
      return successResult;
    case ValidationResults.MAX_LIMIT:
      return {
        validationError: true,
        validationErrorText: `${fieldDefinitionJson.ValidationTitle || field.label}${
          ValidationErrorTextOptions.CHARACTER_LIMIT
        }`,
      };
    case ValidationResults.PHONE_NUMBER_FORMAT:
      return {
        validationError: true,
        validationErrorText: `${fieldDefinitionJson.ValidationTitle || field.label}${
          ValidationErrorTextOptions.FORMAT
        }`,
      };
    default:
      return {
        validationError: true,
        validationErrorText: `${fieldDefinitionJson.ValidationTitle || field.label}${
          ValidationErrorTextOptions.DEFAULT
        }`,
      };
  }
};

const validateTaxID = (field: FieldValue): ValidationResult => {
  const fieldDefinitionJson = JSON.parse(
    field.fieldDefinition && field.fieldDefinition !== '' ? field.fieldDefinition : '{}',
  );

  const value = field.value.replace('-', '').replace(' ', '');

  if (parseInt(value) < 99999999) {
    return {
      validationError: true,
      validationErrorText: `${fieldDefinitionJson.ValidationTitle || field.label}${ValidationErrorTextOptions.FORMAT}`,
    };
  } else if (isNaN(parseInt(value)))
    return {
      validationError: true,
      validationErrorText: `${fieldDefinitionJson.ValidationTitle || field.label}${ValidationErrorTextOptions.DEFAULT}`,
    };
  else return successResult;
};

const validateZipCodeValue = (field: FieldValue): ValidationResult => {
  const fieldDefinitionJson = JSON.parse(
    field.fieldDefinition && field.fieldDefinition !== '' ? field.fieldDefinition : '{}',
  );

  if (!field.value) {
    return {
      validationError: true,
      validationErrorText: `${fieldDefinitionJson.ValidationTitle || field.label}${ValidationErrorTextOptions.DEFAULT}`,
    };
  }

  switch (validateZipCodeString(field.value)) {
    case ValidationResults.VALID:
      return successResult;
    case ValidationResults.MAX_LIMIT:
      return {
        validationError: true,
        validationErrorText: `${fieldDefinitionJson.ValidationTitle || field.label}${
          ValidationErrorTextOptions.CHARACTER_LIMIT
        }`,
      };
    case ValidationResults.ZIP_CODE_FORMAT:
      return {
        validationError: true,
        validationErrorText: `${fieldDefinitionJson.ValidationTitle || field.label}${
          ValidationErrorTextOptions.FORMAT
        }`,
      };
    default:
      return {
        validationError: true,
        validationErrorText: `${fieldDefinitionJson.ValidationTitle || field.label}${
          ValidationErrorTextOptions.DEFAULT
        }`,
      };
  }
};

const validateMCCValue = (mcc: MCC) => {
  if (!mcc || (mcc.Code === '0' && _.isEmpty(mcc.Name))) {
    return {
      validationError: true,
      validationErrorText: `Business Type${ValidationErrorTextOptions.DEFAULT}`,
    };
  } else if (!mcc.Code) {
    switch (invalidString(mcc.Name)) {
      case ValidationResults.VALID:
        return successResult;
      case ValidationResults.MAX_LIMIT:
        return {
          validationError: true,
          validationErrorText: `Business Type${ValidationErrorTextOptions.CHARACTER_LIMIT}`,
        };
      default:
        return {
          validationError: true,
          validationErrorText: `Business Type${ValidationErrorTextOptions.DEFAULT}`,
        };
    }
  } else {
    return successResult;
  }
};

const validateProportionField = (field: FieldValue): ValidationResult => {
  const fieldDefinitionJson = JSON.parse(
    field.fieldDefinition && field.fieldDefinition !== '' ? field.fieldDefinition : '{}',
  );

  const proportion = field.value;
  let valueTotal: number = 0;

  const proportion_has_text = `${fieldDefinitionJson.ValidationTitle || field.label}${
    ValidationErrorTextOptions.PROPORTION_FORMAT
  }`;

  let proportion_not_total = `All options must equal ${proportion.total}%.`;

  if (field.fieldType === FormFieldTypes.ACH_DISTRIBUTION) {
    proportion.total = 100;
  }

  if (proportion.options && proportion.options.length === 2) {
    proportion_not_total = `${proportion.options[0].option} and ${proportion.options[1].option} values must equal ${proportion.total}%.`;
  }

  if (proportion.options && proportion.options.length > 2) {
    const optionArray: Array<string> = [];
    proportion.options.forEach((element: any, index: number) => {
      if (index === proportion.options.length - 1) optionArray.push(`and ${element.option}`);
      else optionArray.push(element.option);
    });

    proportion_not_total = `${optionArray.join(', ')} values must equal ${proportion.total}%.`;
  }

  if (proportion && proportion.options.length > 0) {
    for (let i = 0; i < proportion.options.length; i++) {
      valueTotal += proportion.options[i].value;
    }
  } else {
    valueTotal = 0;
  }

  let allFieldsEmpty = true;
  if (proportion && proportion.options.length > 0) {
    proportion.options.forEach((option: ProportionOption) => {
      if (option.value) {
        allFieldsEmpty = false;
      }
    });

    if (allFieldsEmpty && field.isRequired === false) {
      return successResult;
    }
  }

  let valueIsNumber = true;

  if (proportion && proportion.options.length > 0) {
    proportion.options.forEach((option: ProportionOption) => {
      if (Number.isInteger(option.value) === false) valueIsNumber = false;
    });
  }

  if (!valueIsNumber) return { validationError: true, validationErrorText: proportion_has_text };

  if ((field.isRequired && proportion === '') || (field.isRequired && allFieldsEmpty)) {
    return {
      validationError: true,
      validationErrorText: `${fieldDefinitionJson.ValidationTitle || field.label}${ValidationErrorTextOptions.DEFAULT}`,
    };
  }

  if (field.isRequired && valueTotal !== proportion.total && !allFieldsEmpty) {
    return {
      validationError: true,
      validationErrorText: fieldDefinitionJson.ValidationTitle || proportion_not_total,
    };
  }

  if (field.isRequired === false && valueTotal !== 0 && valueTotal !== proportion.total)
    return {
      validationError: true,
      validationErrorText: fieldDefinitionJson.ValidationTitle || proportion_not_total,
    };

  return successResult;
};

const validateDateField = (field: FieldValue): ValidationResult => {
  const fieldDefinitionJson = JSON.parse(
    field.fieldDefinition && field.fieldDefinition !== '' ? field.fieldDefinition : '{}',
  );

  let minDate = convertStringToDate(fieldDefinitionJson?.Min);
  let maxDate = convertStringToDate(fieldDefinitionJson?.Max);
  const relativeMinDate = fieldDefinitionJson?.RelativeStartDate;
  const relativeMaxDate = fieldDefinitionJson?.RelativeEndDate;

  if (relativeMinDate !== undefined && relativeMinDate !== null) {
    minDate = dateFnsTz.format(dateFns.addDays(new Date(), relativeMinDate), 'yyyy-MM-dd', {
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    });
  }

  if (relativeMaxDate !== undefined && relativeMaxDate !== null) {
    maxDate = dateFnsTz.format(dateFns.addDays(new Date(), relativeMaxDate), 'yyyy-MM-dd', {
      timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    });
  }

  if (field.isRequired && !field.value) {
    return {
      validationError: true,
      validationErrorText: `${fieldDefinitionJson.ValidationTitle || field.label}${
        ValidationErrorTextOptions.INVALID_DATE
      }`,
    };
  }

  if (field.isRequired || field.value.length > 0) {
    const myDate = new Date(field.value);
    if (dateFns.isValid(myDate) === false) {
      return {
        validationError: true,
        validationErrorText: `${fieldDefinitionJson.ValidationTitle || field.label}${
          ValidationErrorTextOptions.INVALID_DATE
        }`,
      };
    }

    if (minDate !== undefined && dateFns.isBefore(myDate, dateFns.parseISO(minDate))) {
      const formattedDate = dateFnsTz.format(dateFns.parseISO(minDate), 'MM/dd/yyyy', {
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      });

      return {
        validationError: true,
        validationErrorText: `${fieldDefinitionJson.ValidationTitle || field.label}${
          ValidationErrorTextOptions.DATE_LOWER_BOUNDARY
        }${formattedDate}.`,
      };
    }

    if (maxDate !== undefined && dateFns.isAfter(myDate, dateFns.parseISO(maxDate))) {
      const formattedDate = dateFnsTz.format(dateFns.parseISO(maxDate), 'MM/dd/yyyy', {
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      });

      return {
        validationError: true,
        validationErrorText: `${fieldDefinitionJson.ValidationTitle || field.label}${
          ValidationErrorTextOptions.DATE_UPPER_BOUNDARY
        }${formattedDate}.`,
      };
    }
  }
  return successResult;
};

const validateDateRange = (field: FieldValue): ValidationResult => {
  const fieldDefinitionJson = JSON.parse(
    field.fieldDefinition && field.fieldDefinition !== '' ? field.fieldDefinition : '{}',
  );
  if (field.isRequired || !_.isEmpty(field.value)) {
    if (!_.isEmpty(field.value)) {
      if (field.value.range1 === '' && field.value.range2 === '' && !field.isRequired) return successResult;

      const date1 = dateFnsTz.utcToZonedTime(
        dateFns.parseISO(field.value.range1),
        Intl.DateTimeFormat().resolvedOptions().timeZone,
      );
      const date2 = dateFnsTz.utcToZonedTime(
        dateFns.parseISO(field.value.range2),
        Intl.DateTimeFormat().resolvedOptions().timeZone,
      );

      if (dateFns.isValid(date1) === false || dateFns.isValid(date2) === false) {
        return {
          validationError: true,
          validationErrorText: `${fieldDefinitionJson.ValidationTitle || field.label}${
            ValidationErrorTextOptions.DATE_RANGE
          }`,
        };
      } else if (
        (fieldDefinitionJson?.Min !== undefined &&
          dateFns.isBefore(date1, new Date(convertStringToDate(fieldDefinitionJson.Min)))) ||
        (fieldDefinitionJson?.Max !== undefined &&
          dateFns.isAfter(date2, new Date(convertStringToDate(fieldDefinitionJson.Max))))
      )
        return {
          validationError: true,
          validationErrorText: `${
            fieldDefinitionJson.ValidationTitle || field.label
          } is not within the required range: between ${dateFnsTz.format(
            dateFns.parseISO(fieldDefinitionJson.Min),
            'MM/dd/yyyy',
            { timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone },
          )} and ${dateFnsTz.format(dateFns.parseISO(fieldDefinitionJson.Max), 'MM/dd/yyyy', {
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          })}.`,
        };
      else if (
        dateFns.isValid(date1) === true &&
        dateFns.isValid(date2) === true &&
        (dateFns.isEqual(date1, date2) || dateFns.isAfter(date1, date2))
      ) {
        return {
          validationError: true,
          validationErrorText: `${fieldDefinitionJson.ValidationTitle || field.label}${
            ValidationErrorTextOptions.TIME_REVERSE_RANGE
          }`,
        };
      }
    } else
      return {
        validationError: true,
        validationErrorText: `${fieldDefinitionJson.ValidationTitle || field.label}${
          ValidationErrorTextOptions.DATE_RANGE
        }`,
      };
  }
  return successResult;
};

const validateTimeRange = (field: FieldValue): ValidationResult => {
  const fieldDefinitionJson = JSON.parse(
    field.fieldDefinition && field.fieldDefinition !== '' ? field.fieldDefinition : '{}',
  );

  if (field.isRequired || (field.value !== '' && field.value != null)) {
    if (field.value !== '' && field.value != null) {
      if (field.value.range1 === '' && field.value.range2 === '' && !field.isRequired) return successResult;
      const time1Hour = parseInt(field.value.range1.substring(0, 2));
      const time2Hour = parseInt(field.value.range2.substring(0, 2));

      const time1Minute = parseInt(field.value.range1.substring(3, 5));
      const time2Minute = parseInt(field.value.range2.substring(3, 5));

      if (isNaN(time1Minute) || isNaN(time2Minute) || isNaN(time1Hour) || isNaN(time2Hour)) {
        return {
          validationError: true,
          validationErrorText: `${fieldDefinitionJson.ValidationTitle || field.label}${
            ValidationErrorTextOptions.TIME_INCLUDE_AM_PM
          }`,
        };
      } else if (time1Hour > time2Hour) {
        return {
          validationError: true,
          validationErrorText: `${fieldDefinitionJson.ValidationTitle || field.label}${
            ValidationErrorTextOptions.TIME_REVERSE_RANGE
          }`,
        };
      } else if (time1Hour === time2Hour && time2Minute <= time1Minute) {
        return {
          validationError: true,
          validationErrorText: `${fieldDefinitionJson.ValidationTitle || field.label}${
            ValidationErrorTextOptions.TIME_REVERSE_RANGE
          }`,
        };
      }
    } else
      return {
        validationError: true,
        validationErrorText: `${fieldDefinitionJson.ValidationTitle || field.label}${
          ValidationErrorTextOptions.TIME_RANGE
        }`,
      };
  }
  return successResult;
};

const validateBankRouting = (field: FieldValue): ValidationResult => {
  const { value, isRequired } = field;
  //-- Get last ServerSide validation information --//
  const validation = queryClient.getQueryData<InputValidationResult>(PhaseQueries.ROUTING_NUMBER_VALIDATION);

  const validateRouting = validateBankAccountFields('Routing Number', value, isRequired, 9, 9);

  if (isRequired || !_.isEmpty(value)) {
    if (validateRouting.validationError) {
      return {
        validationError: true,
        validationErrorText: validateRouting.validationErrorText,
      };
    }

    if (Boolean(validation?.validationStatus) && validation?.validationStatus !== InputValidationStatus.VALID) {
      return {
        validationError: true,
        validationErrorText: validation?.validationMessage ?? '',
        validationStatus: validation?.validationStatus,
      };
    }
  }
  return successResult;
};

const validateBankAccountFields = (
  title: string,
  value: string,
  isRequired: boolean = false,
  minLength: number = 0,
  maxLength: number = 999,
): ValidationResult => {
  if (isRequired && isEmpty(value)) {
    return {
      validationError: true,
      validationErrorText: `${title}${ValidationErrorTextOptions.DEFAULT}`,
    };
  }

  if (value.length < minLength) {
    return {
      validationError: true,
      validationErrorText: `${title}${ValidationErrorTextOptions.NUMBER_LOWER_LENGTH}${minLength}.`,
    };
  }

  if (value.length > maxLength) {
    return {
      validationError: true,
      validationErrorText: `${title}${ValidationErrorTextOptions.NUMBER_UPPER_LENGTH}${maxLength}.`,
    };
  }

  return successResult;
};

const validateACHDistribution = (field: FieldValue): ValidationResult => {
  // go through all the ACH distribution fields and add up the total percentage
  let totalPercentage = 0;
  let elements = document.getElementsByName('ACHDistribution');
  for (let i = 0; i < elements.length; i++) {
    if ((elements[i] as HTMLInputElement).value === '') {
      return {
        validationError: true,
        validationErrorText: `This field cannot be empty.`,
      };
    }

    totalPercentage += parseFloat((elements[i] as HTMLInputElement).value);
  }

  if (totalPercentage > 100) {
    return {
      validationError: true,
      validationErrorText: `Fields may not total more than 100%.`,
    };
  }

  if (totalPercentage < 100) {
    return {
      validationError: true,
      validationErrorText: `Fields total must be 100%.`,
    };
  }

  return successResult;
};
