import { useEffect, useState } from 'react';

import * as dateFns from 'date-fns';
import enUS from 'date-fns/locale/en-US';
import * as dateFnsTz from 'date-fns-tz';
import _ from 'lodash';

import { defaultTimeZone, supportedTimeZones } from './supportedTimeZones';
import { Attribution, UserAttribution } from '../types/attribution';
import { CommonStringValidations } from '../types/enums/CommonStringValidations';
import { CHARACTER_LIMIT, FormFieldTypes } from '../types/enums/FormFieldTypes';
import { ValidationResults } from '../types/enums/ValidationResults';
import {
  AccessorySelection,
  CartAccessory,
  CartPackageModel,
  ConfigurationQuestion,
  ConfigurationType,
  Package,
  PriceOption,
  PriceRecurrenceFrequency,
  PriceType,
  StateLabelValuePair,
} from '../types/equipment';
import { FormFieldOption, Question } from '../types/form';
import { stateOptions } from '../types/locationSearch';
import { FormEntryPhase } from '../types/phase';
import { AddressInfo, ResumeSessionScope } from '../types/session';

export const getQueryParams = (queryStringParamText?: string | undefined): Object => {
  let params = new URLSearchParams(queryStringParamText ?? window.location.search);
  const queryStringParameters = {};
  //Ensure no duplicate keys are sent to the API. toLowerCase is the criteria for comparison, but keeps
  //parameters and values intact.
  let uniqueKeys = _.uniqBy(Array.from(params.keys()), (k) => k.toLowerCase());
  uniqueKeys.forEach((k: string) => {
    let value = params.get(k);
    Object.assign(queryStringParameters, { [k]: value });
  });

  return queryStringParameters;
};

export const setQueryParams = (newParams: any) => {
  const entries = Object.entries(newParams);
  let paramString = '';
  if (entries.length > 0) {
    for (const [key, value] of entries) {
      if (value != null) {
        if (paramString.length > 0) paramString += '&';

        paramString += `${key}=${value}`;
      }
    }

    if (paramString.length > 0) paramString = '?' + paramString;
  }

  setQueryParamString(paramString);
};

export const setQueryParamString = (queryParamString: string) => {
  let newUrl = window.location.origin + queryParamString;
  window.history.replaceState({}, document.title, newUrl);
};

export const setDocumentSubtitle = (documentSubTitle: string | undefined) => {
  if (_.isEmpty(documentSubTitle)) {
    document.title = `On Demand`;
  } else {
    document.title = `On Demand | ${documentSubTitle}`;
  }
};

export const isEmpty = (data: any) => {
  return (
    (data == null || (typeof data === 'string' && data.trim() === '') || Object.keys(data).length === 0) &&
    data !== 0 &&
    typeof data !== 'number'
  );
};

export const createSelector = (name: string) => {
  return name
    .replace(/[^a-zA-Z0-9\s]+/g, '')
    .trim()
    .replace(/\s+/g, '-')
    .toLowerCase();
};

export const invalidString = (str: string) => {
  if (!str.replace(/\s/g, '').length) return ValidationResults.ONLY_SPACE;
  else if (str.length > CHARACTER_LIMIT) return ValidationResults.MAX_LIMIT;
  else return ValidationResults.VALID;
};

export const validateEmailString = (emailStr: string) => {
  const emailFormat = CommonStringValidations.EmailAddress;

  if (invalidString(emailStr) !== ValidationResults.VALID) return invalidString(emailStr);
  else if (emailStr.match(emailFormat)) return ValidationResults.VALID;
  else return ValidationResults.EMAIL_FORMAT;
};

export const validateAddressString = (addressString: string) => {
  const addressFormat = CommonStringValidations.AddressFormat;

  if (invalidString(addressString) !== ValidationResults.VALID) return invalidString(addressString);
  else if (!addressString.match(addressFormat)) return ValidationResults.VALID;
  else return ValidationResults.PO_BOX;
};

export const validatePhoneNumberString = (phoneStr: string) => {
  const phoneFormat = CommonStringValidations.UsPhoneNumber;

  phoneStr = stripPhoneMask(phoneStr);

  if (invalidString(phoneStr) !== ValidationResults.VALID) return invalidString(phoneStr);
  else if (phoneStr.match(phoneFormat)) return ValidationResults.VALID;
  else return ValidationResults.PHONE_NUMBER_FORMAT;
};

export const validateZipCodeString = (zipStr: string) => {
  const zipFormat = CommonStringValidations.UsZipCode;

  if (invalidString(zipStr) !== ValidationResults.VALID) return invalidString(zipStr);
  else if (zipStr.match(zipFormat)) return ValidationResults.VALID;
  else return ValidationResults.ZIP_CODE_FORMAT;
};

export const validateNameString = (nameStr: string) => {
  const nameFormat = CommonStringValidations.NameFormat;

  if (invalidString(nameStr) !== ValidationResults.VALID) return invalidString(nameStr);
  else if (nameStr.match(nameFormat)) return ValidationResults.VALID;
  else return ValidationResults.NAME_FORMAT;
};

export const validateAlphaNumericString = (str: string) => {
  const strRegex = CommonStringValidations.Alphanumeric;

  if (invalidString(str) !== ValidationResults.VALID) return invalidString(str);
  else if (str.match(strRegex)) return ValidationResults.VALID;
  else return ValidationResults.ALPHA_NUMERIC_FORMAT;
};

export const validateUrlString = (urlStr: string) => {
  const urlFormat = CommonStringValidations.HttpUrl;

  if (invalidString(urlStr) !== ValidationResults.VALID) return invalidString(urlStr);
  else if (!isNaN(parseInt(urlStr))) return ValidationResults.URL_FORMAT;
  else if (urlStr.match(urlFormat)) return ValidationResults.VALID;
  else return ValidationResults.URL_FORMAT;
};

export const isImageUrl = (str: string) => {
  str = str.split('?')[0];
  return CommonStringValidations.ImageFileExtensions.test(str);
};
export const stripPhoneMask = (phoneStr: string) => {
  const maskSymbol = ['(', ')', '-', ' '];

  maskSymbol.forEach((symbol) => {
    phoneStr = phoneStr.replace(symbol, '');
  });

  return phoneStr;
};

export const validateSsnString = (ssnStr: string) => {
  // if SSN contains asterisks, it was not updated since loaded from DB, so it should be considered valid
  if (ssnStr.indexOf('*') !== -1) return ValidationResults.VALID;

  let strippedSsnValue = ssnStr.replace(/-/g, '');
  let ssnStrTokens = ssnStr.split('-');

  if (strippedSsnValue.length !== 9) return ValidationResults.SSN_INVALID_LENGTH;
  let firstpart = ssnStrTokens[0];
  if (firstpart.length !== 3 || firstpart === '000' || firstpart === '666' || +firstpart >= 900) {
    return ValidationResults.SSN_INVALID_FIRST_SECTION;
  }

  let secondPart = ssnStrTokens[1];
  if (secondPart.length !== 2 || secondPart === '00') {
    return ValidationResults.SSN_INVALID_SECOND_SECTION;
  }

  let thirdPart = ssnStrTokens[2];
  if (thirdPart.length !== 4 || thirdPart === '0000') {
    return ValidationResults.SSN_INVALID_THIRD_SECTION;
  }

  return ValidationResults.VALID;
};

export const mobileCheck = () => {
  let check = false;
  (function (a) {
    if (
      CommonStringValidations.MobileUserAgent.test(a) ||
      CommonStringValidations.MobileUserAgentPart2.test(a.substr(0, 4))
    )
      check = true;
  })(navigator.userAgent || navigator.vendor);
  return check;
};

export const countDecimals = (value: any) => {
  // eqeq required for decimal validation
  // eslint-disable-next-line
  if (Math.floor(value) == value) return 0;
  return value.toString().split('.')[1]?.length || 0;
};

export const convertStringToDate = (strValue: string, format: string = 'yyyy-MM-dd') => {
  switch (strValue) {
    case 'today':
      return dateFnsTz.format(new Date(), format, {
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      });
    default:
      if (dateFns.isValid(new Date(strValue))) {
        try {
          return dateFnsTz.format(dateFns.parseISO(strValue), format, {
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          });
        } catch {
          const date = new Date(strValue);
          return dateFnsTz.format(date, format, {
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          });
        }
      }

      return '';
  }
};

export const convertStringToTime = (strValue: string) => {
  if (dateFns.isValid(strValue)) {
    return dateFns.format(dateFns.parse(strValue, 'HH:mm', new Date()), 'HH:mm');
  }
  return '';
};

export const addSmartLocationFields = (q: Question, rootId?: number) => {
  let fieldDefinitionJson = JSON.parse(q.fieldDefinition);

  let Properties = fieldDefinitionJson.Properties;

  const labelPrefix = fieldDefinitionJson.LabelPrefix ?? '';

  let temporalId = Math.pow(q.id, rootId ?? 3);

  let newQuestions: Array<Question> = [q];

  let fieldDefinition = { Properties: [{ PropertyKey: Properties[1].PropertyKey }] } as any;

  if (Properties[1].MinLength) {
    fieldDefinition.MinLength = Properties[1].MinLength;
  }

  if (Properties[1].MaxLength) {
    fieldDefinition.MaxLength = Properties[1].MaxLength;
  }

  newQuestions.push({
    id: temporalId,
    fieldDefinition: JSON.stringify(fieldDefinition),
    fieldType: FormFieldTypes.TEXT,
    isRequired: q.isRequired || Properties[1].IsRequired,
    helperText: '',
    infoHtml: '',
    options: [],
    label: labelPrefix ? `${labelPrefix} City` : 'City',
    placeholder: labelPrefix ? `${labelPrefix} City` : 'City',
    fontAwesomeIcon: 'faMapMarker',
    ordinal: q.ordinal + 1,
    formFieldQuestionGroupId: q.formFieldQuestionGroupId,
    parentFormFieldOptionId: null,
    parentFormQuestionId: q.id,
    hasVisibilityDependency: q.hasVisibilityDependency,
  });
  let formFieldStateOptions: Array<FormFieldOption> = stateOptions.map((o, i) => {
    return {
      id: i,
      ordinal: 1,
      formFieldQuestionId: temporalId - 1,
      label: o.label,
      value: o.value,
      questions: [],
      isDefaultOption: false,
    };
  });

  newQuestions.push({
    id: temporalId + 1,
    fieldDefinition: `{"Properties":[{"PropertyKey":"${Properties[2].PropertyKey}","AllowValueFilter":true, "DisableContainSearch":true}]}`,
    fieldType: FormFieldTypes.STATE,
    isRequired: q.isRequired || Properties[2].IsRequired,
    helperText: '',
    infoHtml: '',
    options: formFieldStateOptions,
    label: labelPrefix ? `${labelPrefix} State` : 'State',
    placeholder: '',
    fontAwesomeIcon: 'faMapMarker',
    ordinal: q.ordinal + 2,
    formFieldQuestionGroupId: q.formFieldQuestionGroupId,
    parentFormFieldOptionId: null,
    parentFormQuestionId: q.id,
    hasVisibilityDependency: q.hasVisibilityDependency,
  });

  newQuestions.push({
    id: temporalId + 2,
    fieldDefinition: `{"MaskType":"ZipCode","Properties":[{"PropertyKey":"${Properties[3].PropertyKey}"}]}`,
    fieldType: FormFieldTypes.TEXT,
    isRequired: q.isRequired || Properties[3].IsRequired,
    helperText: '',
    options: [],
    infoHtml: '',
    label: labelPrefix ? `${labelPrefix} Zip or Postal Code` : 'Zip or Postal Code',
    placeholder: '',
    fontAwesomeIcon: 'faDirections',
    ordinal: q.ordinal + 3,
    formFieldQuestionGroupId: q.formFieldQuestionGroupId,
    parentFormFieldOptionId: null,
    parentFormQuestionId: q.id,
    hasVisibilityDependency: q.hasVisibilityDependency,
  });

  return newQuestions;
};

export const transformFormPhaseProperties = (phase: FormEntryPhase) => {
  return {
    ...phase,
    questionGroups: phase.questionGroups.map((group) => {
      const newQuestions: Question[] = [];

      group.questions.forEach((q, i) => {
        if (q.fieldType === FormFieldTypes.SMART_LOCATION && group.questions[i + 1]?.label !== 'City') {
          const smartLocationQuestions = addSmartLocationFields(q);

          newQuestions.push(...smartLocationQuestions);
        } else {
          newQuestions.push(q);
        }
      });

      return { ...group, questions: newQuestions.map((_, i) => ({ ..._, ordinal: i + 1 })) };
    }),
  } as FormEntryPhase;
};

export const getCartPrice = (item: CartPackageModel) => {
  const price =
    item.pricePaymentOptions[0].price > 0
      ? item.pricePaymentOptions[0].price
      : item.pricePaymentOptions[0].originalPrice;

  const modifier =
    item.pricePaymentOptions[0].recurrenceFrequency === PriceRecurrenceFrequency.Monthly
      ? '/mo'
      : item.pricePaymentOptions[0].recurrenceFrequency === PriceRecurrenceFrequency.Yearly
        ? '/yr'
        : '';

  const totalAccessoryPrice = item.accessories.reduce((total, accessory) => {
    return accessory.price * item.quantity;
  }, 0);

  let finalPrice = price + totalAccessoryPrice;

  return finalPrice.toFixed(2) + modifier;
};

export const transformPriceValues = (selectedPackage: Package, selectedPriceOption: PriceOption) => {
  const prices = [];
  const subscription: PriceOption | undefined = selectedPackage.packagePrices.find(
    (x) => x.priceType === PriceType.SubscriptionPrice,
  );
  if (subscription && selectedPriceOption.packagePriceId !== subscription.packagePriceId)
    prices.push({ packagePriceId: subscription.packagePriceId });
  prices.push({ packagePriceId: selectedPriceOption.packagePriceId });

  return prices;
};

export const transformCartAccessories = (accessories: Array<CartAccessory>) => {
  return accessories.map((x) => {
    return {
      packageAccessoryId: x.packageAccessoryId,
      price: x.price,
      priceType: x.priceType,
    };
  });
};

export const transformAccessorySelection = (accessories: Array<AccessorySelection>) => {
  return accessories.map((x) => {
    return {
      packageAccessoryId: x.packageAccessoryId,
    };
  });
};

export const getEquipmentQuestionDefaultValue = (
  question: ConfigurationQuestion,
  value: string | null,
  adminEmail: string,
) => {
  let result: string = '';
  switch (question.fieldType) {
    //Make this configuration type the administrator type
    case ConfigurationType.AdministrationEmail:
      result = adminEmail;
      break;
    case ConfigurationType.AutoClose:
      if (value != null) {
        result = value;
      } else {
        const fieldDefinitionJson = JSON.parse(question.fieldDefinition);
        let time = fieldDefinitionJson?.defaultTime ?? '04:00';

        const sampleDate = new Date();
        let tzGuess = dateFnsTz.format(sampleDate, 'z', {
          timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          locale: enUS,
        });

        if (
          supportedTimeZones.some((tz) => tzGuess === dateFnsTz.format(sampleDate, 'z', { timeZone: tz, locale: enUS }))
        )
          result = `${time} ${tzGuess}`;
        else result = `${time} ${dateFnsTz.format(sampleDate, 'z', { timeZone: defaultTimeZone, locale: enUS })}`;
      }
      break;
    default:
      break;
  }
  return result;
};

export const formatEquipmentImageLinks = (images: Array<string>) => {
  const formatted: Array<string> = [];
  for (let i = 0; i < images.length; i++) {
    if (images[i].includes('http')) {
      formatted.push(images[i]);
    } else {
      formatted.push(`${import.meta.env.VITE_IMAGE_CDN}/equipment/${images[i]}`);
    }
  }
  return formatted;
};

function useWindowSize() {
  const [windowSize, setWindowSize] = useState({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    const handleResize = _.debounce(() => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }, 100);

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowSize;
}

export default useWindowSize;

export const convertStateAbbrToLVP = (stateAbbr: string | null) => {
  if (stateAbbr == null) return null;
  const matchedState = stateOptions.find((state) => state.value === stateAbbr);
  if (!matchedState) return null;

  let stateLVP: StateLabelValuePair = {
    label: matchedState.label,
    value: matchedState.value,
  };

  return JSON.stringify(stateLVP);
};

export const scrollbehavior = () => {
  // Detect Safari
  let safariAgent = navigator.userAgent.indexOf('Safari') > -1;
  let chromeAgent = navigator.userAgent.indexOf('Chrome') > -1;

  // Discard Safari since it also matches Chrome
  if (chromeAgent && safariAgent) safariAgent = false;

  return safariAgent ? 'auto' : 'smooth';
};

export const getNumberToCommaString = (value: number | string) => {
  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const parseResumeSessionScope = (resumeScopeText: string | null | undefined): ResumeSessionScope => {
  const normalized = (resumeScopeText ?? '').toUpperCase();
  switch (normalized) {
    case ResumeSessionScope.CHANNEL.toString().toUpperCase():
      return ResumeSessionScope.CHANNEL;
    case ResumeSessionScope.SUBCHANNEL.toString().toUpperCase():
      return ResumeSessionScope.SUBCHANNEL;
    case ResumeSessionScope.SOURCE.toString().toUpperCase():
      return ResumeSessionScope.SOURCE;
    case ResumeSessionScope.CONFIG.toString().toUpperCase():
      return ResumeSessionScope.CONFIG;
    case ResumeSessionScope.TARGET_SESSION.toString().toUpperCase():
      return ResumeSessionScope.TARGET_SESSION;
    default: //default to most specific
      return ResumeSessionScope.AAID;
  }
};

export const compareAddressesAreTheSame = (firstAddress: AddressInfo | null, secondAddress: AddressInfo | null) => {
  if (!firstAddress || !secondAddress) return false;
  return (
    firstAddress.streetAddress === secondAddress.streetAddress &&
    firstAddress.city === secondAddress.city &&
    firstAddress.state === secondAddress.state &&
    firstAddress.zip === secondAddress.zip
  );
};

export const insertUserAttributions = (
  currentAttributions: UserAttribution | null,
  inboundAttributions: Array<Attribution> | null,
) => {
  if (!currentAttributions) currentAttributions = { attributions: [] };
  if (!currentAttributions.attributions) currentAttributions.attributions = [];
  if (!inboundAttributions) return;
  inboundAttributions.forEach((attr) => {
    if (
      !currentAttributions!.attributions.find(
        (x) => x.attributionId === attr.attributionId && x.attributionType === attr.attributionType,
      )
    ) {
      let newAttribution: Attribution = {
        attributionType: attr.attributionType,
        attributionId: attr.attributionId,
        createdUtc: attr.createdUtc,
      };
      currentAttributions!.attributions.push(newAttribution);
    }
  });
};
