import { useEffect, useMemo, useState, useTransition } from 'react';

import { FormHelperText, TextField } from '@mui/material';
import FormControl from '@mui/material/FormControl';

import { FormFieldTypes, TextMaskTypes } from '../../../../../types/enums/FormFieldTypes';
import { QuestionProps } from '../../../../../types/form';
import { isEmpty } from '../../../../../utility';
import MaskedTextInput from '../../../../../utility/maskedTextInput';
import NumberInput from '../../../../../utility/numberInput';
import { screenUtils } from '../../../../../utility/screenUtils';
import { GetMobileInputLabelProps } from '../../../../../utility/showOnDevice';
import './index.css';
import { useFormQuestionDebounceOnChange } from '../../../hooks';
import { formHelperService } from '../../../services';
import { Information } from '../../Information';

interface Props extends QuestionProps {
  type: FormFieldTypes;
}

const TextInput: React.FC<Props> = ({
  label,
  onChange,
  value,
  fieldDefinitionJson,
  helperText,
  placeholder,
  type,
  infoHtml,
  fieldType,
  hideLabel = false,
  renderForSummary = false,
}) => {
  const [internalValue, setInternalValue] = useState(value.value);
  const [internalConfirmationValue, setInternalConfirmationValue] = useState(value.confirmationValue ?? '');
  const [confirmationHasFocus, setConfirmationHasFocus] = useState(false);
  const [hasChanged, setHasChanged] = useState(false);

  //Timer function for blur with confirmation
  const [focustimer, setFocusTimer] = useState<boolean>(false);
  const [, startTransition] = useTransition();

  const debouncedOnChange = useFormQuestionDebounceOnChange(onChange);

  useEffect(() => {
    if (!isEmpty(value.value)) setInternalValue(value.value);
  }, [value]);

  const inputMaskComponent = useMemo(() => (type === FormFieldTypes.NUMBER ? NumberInput : MaskedTextInput), [type]);
  const inputType = fieldDefinitionJson?.MaskType === TextMaskTypes.EMAIL ? 'email' : 'text';
  const confirmationLabel = fieldDefinitionJson?.ConfirmationLabel;
  const validationTitle = fieldDefinitionJson?.ValidationTitle ?? label;
  const customInputProps = formHelperService.getCustomInputProps(
    fieldType,
    fieldDefinitionJson?.Properties[0].PropertyKey,
    label,
  );
  const inputProps =
    type === FormFieldTypes.NUMBER
      ? {
          max: fieldDefinitionJson?.Max,
          maxLength: fieldDefinitionJson?.MaxLength,
          min: fieldDefinitionJson?.Min,
          decimalPlaces: fieldDefinitionJson?.DecimalPlaces || 0,
          prefix: fieldDefinitionJson?.Prefix,
          suffix: fieldDefinitionJson?.Suffix,
          thousandSeparator: fieldDefinitionJson?.ThousandSeparator,
          allowLeadingZeros: fieldDefinitionJson?.AllowLeadingZeros,
          allowOverflow: fieldDefinitionJson?.AllowOverflow,
          ...customInputProps,
        }
      : {
          maskType: fieldDefinitionJson?.MaskType,
          type: inputType,
          maxLength: fieldDefinitionJson?.MaxLength,
          minLength: fieldDefinitionJson?.MinLength,
          ...customInputProps,
        };

  let componentLabel = (renderForSummary && confirmationLabel) || !hideLabel ? label : null;
  let componentLabelInputProps = renderForSummary ? { shrink: true, style: { fontSize: 14.8 } } : { shrink: true };

  return (
    <div
      className={`text-input-container${fieldDefinitionJson?.RequiresConfirmation ? (renderForSummary ? ' with-confirmation-column' : ' with-confirmation') : ''}`}>
      <FormControl fullWidth>
        {fieldDefinitionJson?.Multiline ? (
          <TextField
            id={fieldDefinitionJson?.Properties[0].PropertyKey}
            label={
              componentLabel && (
                <>
                  {componentLabel}
                  {infoHtml && <Information infoHtml={infoHtml} />}
                </>
              )
            }
            multiline
            rows={4}
            variant="filled"
            sx={{
              height: '150px',
              '& .MuiFilledInput-input': {
                alignSelf: 'flex-start',
                textIndent: '0',
                paddingTop: '0px',
                textOverflow: 'ellipsis',
              },
            }}
            placeholder={placeholder ?? ''}
            error={value.validationError}
            required={value.isRequired}
            helperText={value.validationError ? value.validationErrorText : ''}
            onChange={(e) => {
              if (!renderForSummary) {
                let newValue = e.target.value.length > 500 ? e.target.value.substring(0, 500) : e.target.value;
                debouncedOnChange(value.questionId, newValue, false);
                setInternalValue(newValue);
              }
            }}
            InputLabelProps={componentLabelInputProps}
            InputProps={{ style: { height: '200px', paddingTop: '12px' } }}
            onBlur={(e) => {
              if (!renderForSummary) {
                setInternalValue((internalValue: string) => {
                  setTimeout(() => onChange(value.questionId, internalValue?.trim(), true));
                  return internalValue?.trim();
                });
              } else {
                onChange(value.questionId, e.target.value, false);
                setInternalValue(e.target.value);
              }
            }}
            value={internalValue}
            autoComplete={value.autoComplete}
            inputProps={customInputProps}
          />
        ) : (
          <>
            <TextField
              variant="filled"
              id={fieldDefinitionJson?.Properties[0].PropertyKey}
              label={
                componentLabel && (
                  <>
                    {componentLabel}
                    {infoHtml && <Information infoHtml={infoHtml} />}
                  </>
                )
              }
              required={value.isRequired}
              placeholder={placeholder ?? ''}
              error={value.validationError}
              helperText={value.validationError ? value.validationErrorText : ''}
              InputLabelProps={
                screenUtils.DeviceIsMobile() ? GetMobileInputLabelProps(label ?? '') : componentLabelInputProps
              }
              InputProps={{
                type: inputType,
                inputComponent: inputMaskComponent as any,
                inputProps: { ...inputProps, style: { ...inputProps.style, textOverflow: 'ellipsis' } },
              }}
              inputProps={inputProps}
              onChange={(e) => {
                //Don't call if it's the same value to prevent page animations from happening
                if (e.target.value.trim() !== value.value) {
                  startTransition(() => debouncedOnChange(value.questionId, e.target.value, false));
                }
                setInternalValue(e.target.value);
                setHasChanged(true);
              }}
              onBlur={() => {
                setInternalValue((internalValue: string) => {
                  setTimeout(() =>
                    onChange(value.questionId, internalValue?.trim(), hasChanged, internalConfirmationValue?.trim()),
                  );
                  return internalValue?.trim();
                });
                if (fieldDefinitionJson?.RequiresConfirmation) {
                  setFocusTimer(true);
                  setTimeout(() => setFocusTimer(false), 100);
                }
              }}
              value={internalValue}
              autoComplete={value.autoComplete}
            />
          </>
        )}
        {!isEmpty(helperText) && (
          <FormHelperText component={'div'} dangerouslySetInnerHTML={{ __html: helperText ?? '' }}></FormHelperText>
        )}
      </FormControl>
      {/* Display related confirmation field if applicable */}
      {fieldDefinitionJson?.RequiresConfirmation && (
        <FormControl fullWidth className="confirmation-input">
          {!isEmpty(helperText) && (
            <FormHelperText component={'div'} dangerouslySetInnerHTML={{ __html: helperText ?? '' }}></FormHelperText>
          )}
          <TextField
            label={hideLabel && !renderForSummary ? null : 'Confirm ' + confirmationLabel}
            variant="filled"
            InputLabelProps={
              screenUtils.DeviceIsMobile() ? GetMobileInputLabelProps(label ?? '') : componentLabelInputProps
            }
            id={`${fieldDefinitionJson?.Properties[0].PropertyKey}-confirmation`}
            InputProps={{
              type: inputType,
              inputComponent: inputMaskComponent as any,
            }}
            required={value.isRequired || value.value.length > 0}
            placeholder={placeholder ? `Confirm ${placeholder}` : ''}
            error={!confirmationHasFocus && value.confirmationError && !focustimer}
            inputProps={{ ...inputProps, style: { ...inputProps.style, textOverflow: 'ellipsis' } }}
            onChange={(e) => {
              setInternalConfirmationValue(e.target.value);
              debouncedOnChange(value.questionId, internalValue.trim(), false, e.target.value);
              setHasChanged(true);
            }}
            onFocus={() => setConfirmationHasFocus(true)}
            onBlur={() => {
              setConfirmationHasFocus(false);
              setInternalConfirmationValue((internalConfirmationValue: string) => {
                setTimeout(() =>
                  onChange(value.questionId, internalValue.trim(), hasChanged, internalConfirmationValue.trim()),
                );
                return internalConfirmationValue.trim();
              });
            }}
            onPaste={(e) => e.preventDefault()}
            value={internalConfirmationValue}
            autoComplete={value.autoComplete}
            helperText={
              !confirmationHasFocus && value.confirmationError && !focustimer
                ? validationTitle + ' fields do not match.'
                : ''
            }
          />
        </FormControl>
      )}
    </div>
  );
};

export { TextInput };
export * from './DisabledField';
