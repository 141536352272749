import { useCallback, useEffect, useState } from 'react';

import _ from 'lodash';
import { useIsFetching } from 'react-query';

import { queryClient } from '../App';
import { containerId } from '../features/form';
import { formHelperService } from '../features/form/services/FormHelperService';
import { questionVisibilityService } from '../features/form/services/QuestionVisibilityService';
import { ValidationError } from '../features/form/types/ValidationResponse';
import { locationService } from '../features/locations/services';
import { useSession } from '../session/hooks/useSession';
import { UserPropertiesFormEntry } from '../types';
import { SESSION_ERROR_MESSAGE } from '../types/enums';
import { FormFieldTypes, OTHER_MCC } from '../types/enums/FormFieldTypes';
import { LocalStorageKeys } from '../types/enums/LocalStorageEnums';
import { CachedItem, EmailValidatorId, FieldValue, Question, QuestionGroup, ValidationResult } from '../types/form';
import { stateOptions } from '../types/locationSearch';
import {
  CaptchaType,
  FormEntryPhase,
  FormEntrySummaryPhase,
  PhaseEvent,
  PhaseProps,
  PhaseType,
  PhaseTypes,
  TrackerType,
  ValidationErrorDetails,
} from '../types/phase';
import { PhaseQueries } from '../types/queries';
import { OnDemandConfig } from '../types/session';
import { EmailValidationResult, EmailValidationStatus, InputValidationStatus } from '../types/verification';
import { isEmpty, transformFormPhaseProperties } from '../utility';
import tracker from '../utility/eventTracking';
import { createLocalArrayStorageService } from '../utility/localStorage';
import ScrollToTop from '../utility/scrollToTop';
import { validateFieldValue } from '../utility/validation';

const cachedQuestionGroupStore = createLocalArrayStorageService<CachedItem>(LocalStorageKeys.CachedQuestionGroupValues);

type FormState = {
  loading: boolean;
  loadingNextPage: boolean;
  requestSubmit: boolean;
  submitting: boolean;
  rewinding: boolean;
  immediateAnimation: boolean;
  phase: FormEntryPhase | FormEntrySummaryPhase | null;
  fieldValues: Array<FieldValue>;
  savedValues: Array<FieldValue>;
  element: HTMLElement | null;
  privacyAgreement: boolean;
  privacyText: string | null;
  cachedData: Array<CachedItem>;
  showRequiredText: boolean;
  isSummary: boolean;
};

type UseFormProps = {
  state: FormState;
  showPrivacy: boolean | '' | null;
  showApplicationOpenAnotherTab: boolean;
  showDataLossWarning: boolean;
  isLoading: boolean;
  isSubmitting: boolean;
  errorListMessages: Array<ValidationError>;
  allowBack: boolean;
  showTracker?: boolean;
  submitButtonText: string;
  trackerType?: TrackerType;
  captchaType?: CaptchaType;
  cachedGroups: Array<number>;
  isFetchingCalculateQuestionVisibility: boolean;
  onFieldChange: (questionId: number, newValue: any, shouldValidate: boolean, confirmationValue?: any) => void;
  handleBackClick: () => void;
  handleContinueClick: () => void;
  handleContinueWithLocationValidation: () => void;
  handleClearElement: () => void;
  onSensitiveUnlock: (questionId: number) => void;
  setPrivacyAgreement: (e: any) => void;
  setCachedGroups: (cachedGroups: Array<number>) => void;
  validatePageValues: () => Promise<boolean>;
  resetFieldValues: (originalValues: Array<FieldValue>) => void;
};

interface Props extends PhaseProps {
  phase?: FormEntryPhase | FormEntrySummaryPhase;
  onDemandConfig?: OnDemandConfig;
  showApplicationOpenAnotherTab: boolean;
  setLoadedPhase: (loadedPhase: PhaseTypes | undefined) => void;
  setOriginalFieldValues?: (originalValues: Array<FieldValue>) => void;
}

let timeoutFieldChange: NodeJS.Timeout;
const timeoutFieldChangeDelayFormEntry = 200;
const timeoutFieldChangeDelaySummary = 500;

const dbaPropertiesMapping = {
  'DBA Name': 'DBA Name',
  'DBA Address': 'DBA Street Address',
} as any;

const useForm = ({
  rewind,
  submit,
  setLoadedPhase,
  isRewind,
  phase,
  onDemandConfig,
  showApplicationOpenAnotherTab,
  setOriginalFieldValues,
  validateAndSaveSessionProperties,
}: Props): UseFormProps => {
  const { session } = useSession();
  const [state, setState] = useState<FormState>({
    loading: true,
    loadingNextPage: false,
    requestSubmit: false,
    submitting: false,
    rewinding: false,
    immediateAnimation: true,
    phase: null,
    fieldValues: [],
    savedValues: [],
    element: null,
    privacyText: null,
    privacyAgreement: true,
    showRequiredText: false,
    cachedData: cachedQuestionGroupStore.get() ?? [],
    isSummary: false,
  });

  const [errorListMessages, setErrorListMessages] = useState<Array<ValidationError>>([]);

  const [riskyEmails, setRiskyEmails] = useState<Array<string>>([]);
  const [cachedGroups, setCachedGroups] = useState<Array<number>>([]);
  const [showDataLossWarning, setShowDataLossWarning] = useState(false);

  const isFetchingEmailValidation = useIsFetching(PhaseQueries.EMAIL_VALIDATION);
  const isFetchingRoutingNumber = useIsFetching(PhaseQueries.ROUTING_NUMBER_VALIDATION);
  const [isFetchingCalculateQuestionVisibility, setIsFetchingCalculateQuestionVisibility] = useState(false);

  const emailValidationResult = queryClient.getQueryData<EmailValidationResult>(PhaseQueries.EMAIL_VALIDATION);

  const isLoading = state.loading;
  const allowBack = Boolean(phase?.allowRewind);

  const showPrivacy = state.privacyText && !isRewind;
  const submitButtonText = phase?.submitButtonText ? phase.submitButtonText : 'Submit';
  const isSubmitting = state.submitting || state.requestSubmit;

  const [hasFetchedLocationForm, setHasFetchedLocationForm] = useState(false);

  const cacheLocalValues = useCallback(() => {
    let persistedList: Array<CachedItem> = [];

    if (state.phase && !state.isSummary) {
      (state.phase as FormEntryPhase).questionGroups.forEach((x) => {
        if (x.allowLocalCache && cachedGroups.find((p) => p === x.id) != null) {
          x.questions.forEach((g) => {
            const isSensitive = JSON.parse(g.fieldDefinition)?.Properties.find(
              (prop: any) => prop.IsSensitive === true,
            );
            //Do NOT cache sensitive values.
            if (!isSensitive) {
              const persistedValue = state.fieldValues.find(
                (q) => q.questionGroupId === g.formFieldQuestionGroupId && q.questionId === g.id,
              );
              if (persistedValue) {
                persistedList.push({
                  questionGroupId: persistedValue.questionGroupId,
                  id: persistedValue.questionId,
                  value: persistedValue.value,
                });
              }
            }
          });
        }

        if (persistedList.length > 0 || (state.cachedData.length > 0 && !state.isSummary)) {
          cachedQuestionGroupStore.remove();
        }
        if (persistedList.length > 0 && !state.isSummary) cachedQuestionGroupStore.set(persistedList);
      });
    }
  }, [state.phase, state.isSummary, state.cachedData.length, state.fieldValues, cachedGroups]);

  const onSensitiveUnlock = (questionId: number) => {
    const valueIndex = state.fieldValues.findIndex((x) => x.questionId === questionId);
    const newValues = state.fieldValues;

    newValues[valueIndex].isSensitive = undefined;
    newValues[valueIndex].value = '';

    try {
      const fieldDefinition = JSON.parse(newValues[valueIndex].fieldDefinition);

      if (fieldDefinition?.RequiresConfirmation) newValues[valueIndex].confirmationValue = '';
    } catch (e) {
      console.error(e);
    }

    setState((prevState) => {
      return {
        ...prevState,
        fieldValues: newValues,
      };
    });
  };

  const onFieldChange = async (questionId: number, newValue: any, shouldValidate: boolean, confirmationValue?: any) => {
    const valueIndex = state.fieldValues.findIndex((x) => x.questionId === questionId);
    const value = state.fieldValues.find((x) => x.questionId === questionId);

    if (value) {
      if (formHelperService.hasValueChanged(value, newValue) || confirmationValue) {
        setShowDataLossWarning(true);
      }

      const validationResult: any = shouldValidate
        ? validateFieldValue({
            ...value,
            value: newValue,
            confirmationValue: confirmationValue,
          })
        : { ...value, value: newValue, confirmationValue: confirmationValue };

      //Do not make a deep copy of the array, so autocomplete can be used.
      const newValues = state.fieldValues;

      if (value.fieldType === FormFieldTypes.SMART_LOCATION) {
        if (newValue.blur && validationResult?.validationError && newValue.address !== '')
          newValues[valueIndex] = {
            ...newValues[valueIndex],
            value: newValue,
            validationError: false,
            validationErrorText: '',
            confirmationValue: confirmationValue,
            confirmationError: validationResult?.confirmationError,
          };
        else {
          newValues[valueIndex] = {
            ...newValues[valueIndex],
            value: newValue,
            validationError: validationResult?.validationError,
            validationErrorText: validationResult?.validationErrorText,
            confirmationValue: confirmationValue,
            confirmationError: validationResult?.confirmationError,
          };
        }

        if (!isEmpty(newValue?.city)) {
          const cityIndex = state.fieldValues.findIndex(
            (_) =>
              _.questionParentId === questionId &&
              JSON.parse(_.fieldDefinition).Properties[0].PropertyKey.toLowerCase().includes('city'),
          );

          newValues[cityIndex] = {
            ...newValues[cityIndex],
            value: newValue?.city,
            validationError: false,
            validationErrorText: '',
            confirmationValue: confirmationValue,
            confirmationError: validationResult?.confirmationError,
          };
        }

        if (!isEmpty(newValue?.state)) {
          const stateIndex = state.fieldValues.findIndex(
            (_) =>
              _.questionParentId === questionId &&
              JSON.parse(_.fieldDefinition).Properties[0].PropertyKey.toLowerCase().includes('state'),
          );

          newValues[stateIndex] = {
            ...newValues[stateIndex],
            value: {
              id: stateOptions.findIndex((x) => x.value === newValue.state),
              label: stateOptions.find((x) => x.value === newValue.state)?.label ?? '',
              value: newValue.state,
              formFieldQuestionId: value.questionId + 2,
              isDefaultOption: false,
              options: [],
            },
            validationError: false,
            validationErrorText: '',
            confirmationValue: confirmationValue,
            confirmationError: validationResult?.confirmationError,
          };
        }

        if (!isEmpty(newValue?.zip)) {
          const zipIndex = state.fieldValues.findIndex(
            (_) =>
              _.questionParentId === questionId &&
              JSON.parse(_.fieldDefinition).Properties[0].PropertyKey.toLowerCase().includes('zip'),
          );

          newValues[zipIndex] = {
            ...newValues[zipIndex],
            value: newValue?.zip,
            validationError: false,
            validationErrorText: '',
            confirmationValue: confirmationValue,
            confirmationError: validationResult?.confirmationError,
          };
        }
      } else
        newValues[valueIndex] = {
          ...newValues[valueIndex],
          value: newValue,
          validationError: validationResult?.validationError,
          validationErrorText: validationResult?.validationErrorText,
          confirmationValue: confirmationValue,
          confirmationError: validationResult?.confirmationError,
        };

      if (value.hasVisibilityDependency || state.isSummary) {
        handleCalculateQuestionVisibility(newValues);
        setIsFetchingCalculateQuestionVisibility(true);
      } else {
        setState((prevState) => {
          return {
            ...prevState,
            immediateAnimation: true,
            fieldValues: newValues,
          };
        });
      }
    } else {
      //Always turn on immediate animation for field changes
      setState((prevState) => {
        return {
          ...prevState,
          immediateAnimation: true,
        };
      });
    }
  };

  const copyFieldValues = (values: Array<FieldValue>) => {
    let newFieldValues = new Array<FieldValue>();
    for (let i = 0; i < values.length; i++) {
      let val = values[i];
      newFieldValues.push(structuredClone(val));
    }
    return newFieldValues;
  };

  const resetFieldValues = (originalValues: Array<FieldValue>) => {
    setState((prevState) => {
      return {
        ...prevState,
        fieldValues: copyFieldValues(originalValues),
      };
    });
  };

  const modifyPhaseForSummaryGroupVisibilities = (fieldValues: Array<FieldValue>) => {
    let phase: FormEntrySummaryPhase = JSON.parse(JSON.stringify(state.phase as FormEntrySummaryPhase));
    phase.formEntrySummaryGroups.forEach((grp) => {
      let questions = grp.formEntryPhaseModels.flatMap((qg) => qg.questionGroups).flatMap((q) => q.questions);
      let visibleQuestionCount = 0;

      questions.forEach((q) => {
        let associatedFieldValue = _.find(fieldValues, (f) => {
          return f.questionId === q.id;
        });

        if (associatedFieldValue?.visible) {
          visibleQuestionCount++;
        }
      });

      if (visibleQuestionCount === 0) {
        grp.hidden = true;
      } else {
        grp.hidden = false;
      }
    });

    return phase;
  };

  const buildFieldValues = (
    newValues: Array<FieldValue>,
    questionVisibilities: Record<number, boolean>,
    isSummary: boolean,
  ) => {
    return newValues.map((newValue) => {
      if (
        _.has(questionVisibilities, newValue.questionId) ||
        (newValue.questionParentId && _.has(questionVisibilities, newValue.questionParentId))
      ) {
        const visible = Boolean(
          questionVisibilities[newValue.questionId] ||
            (newValue.questionParentId && questionVisibilities[newValue.questionParentId]),
        );

        if (isSummary) {
          return {
            ...newValue,
            visible: visible,
            validationError: visible ? newValue.validationError : false,
            validationErrorText: visible ? newValue.validationErrorText : '',
          };
        } else {
          let questionGroup: QuestionGroup | undefined;
          let question: Question | undefined;

          questionGroup = (state.phase as FormEntryPhase).questionGroups.find((_) => _.id === newValue.questionGroupId);
          question = questionGroup?.questions.find((_) => _.id === newValue.questionId);

          let defaultValue = '';
          let cachedValue: CachedItem | undefined;

          if (visible && question) {
            const savedValue = formHelperService.getSavedValue(question, state.phase?.savedProperties!, []);

            if (questionGroup) {
              cachedValue = state.cachedData.find(
                (cd) => cd.id === question!.id && cd.questionGroupId === questionGroup!.id,
              );
            }

            defaultValue =
              savedValue?.value != null
                ? savedValue.value
                : cachedValue ?? formHelperService.getQuestionDefaultValue(question)?.value ?? '';
          }

          let newFieldValue = '';

          if (visible === true && newValue.visible === false) {
            newFieldValue = defaultValue ?? '';
          } else {
            newFieldValue = newValue.value ?? '';
          }

          return {
            ...newValue,
            value: newFieldValue,
            visible,
            validationError: visible ? newValue.validationError : false,
            validationErrorText: visible ? newValue.validationErrorText : '',
          };
        }
      }

      return { ...newValue };
    });
  };

  const handleCalculateQuestionVisibility = (newValues: Array<FieldValue>) => {
    clearTimeout(timeoutFieldChange);
    timeoutFieldChange = setTimeout(
      async () => {
        const userProperties = formHelperService.createPropertiesPayload(
          newValues as Array<FieldValue>,
          PhaseType.FORM,
        ) as UserPropertiesFormEntry;
        const questionVisibilitiesResponse =
          await questionVisibilityService.calculateQuestionVisibilities(userProperties);

        let questionVisibilities: Record<number, boolean> =
          questionVisibilitiesResponse?.data?.questionVisibilities ?? {};

        if (questionVisibilitiesResponse?.data && !state.isSummary) {
          let newPhaseCopy: FormEntryPhase = JSON.parse(JSON.stringify(state.phase)) as FormEntryPhase;
          const newPhase: FormEntryPhase = {
            ...newPhaseCopy,
            questionVisibilities: { ...newPhaseCopy.questionVisibilities, ...questionVisibilities },
          };

          //if the current for the session IS NOT the current form phase, do not set the loaded phase
          if (session?.payload?.id === newPhase.id) {
            setLoadedPhase(newPhase);
          }
        }

        let updatedPhase = state.phase;
        let updatedValues = buildFieldValues(newValues, questionVisibilities, state.isSummary);
        if (state.isSummary) {
          updatedPhase = modifyPhaseForSummaryGroupVisibilities(updatedValues);
        }

        setState((prevState) => {
          return {
            ...prevState,
            phase: updatedPhase,
            immediateAnimation: false,
            fieldValues: updatedValues,
          };
        });
        setIsFetchingCalculateQuestionVisibility(false);
      },
      !state.isSummary ? timeoutFieldChangeDelayFormEntry : timeoutFieldChangeDelaySummary,
    );
  };

  const saveSummarySessionProperties = async (newValues: Array<FieldValue>): Promise<boolean> => {
    let validationErrorDetails: ValidationErrorDetails[] = [];
    let validPage = true;

    if (state.isSummary && validateAndSaveSessionProperties) {
      validationErrorDetails = await validateAndSaveSessionProperties(newValues, phase?.phaseType ?? PhaseType.FORM);
    }

    if (validationErrorDetails.length > 0) {
      validationErrorDetails.forEach((validationErrorDetail) => {
        let fieldReference = newValues.find((x) =>
          x.label.includes(dbaPropertiesMapping[validationErrorDetail.fieldName]),
        );

        if (fieldReference) {
          validPage = false;
          fieldReference.validationError = true;
          fieldReference.validationErrorText = `Please enter a unique ${validationErrorDetail.fieldName} for each location.`;
        }
      });
    } else if (state.isSummary) {
      setShowDataLossWarning(false);
    }

    return validPage;
  };

  const validatePageValues = async (): Promise<boolean> => {
    let validPage = true;
    let firstError = false;
    let element: HTMLElement | null;

    const pageValues: Array<FieldValue> = state.fieldValues
      .sort(function (a, b) {
        if (a.questionGroupOrdinal > b.questionGroupOrdinal) return 1;
        if (a.questionGroupOrdinal < b.questionGroupOrdinal) return -1;
        else return 0;
      })
      .sort(function (a, b) {
        if (a.ordinal > b.ordinal && a.questionGroupOrdinal === b.questionGroupOrdinal) return 1;
        if (a.ordinal < b.ordinal && a.questionGroupOrdinal === b.questionGroupOrdinal) return -1;
        else return 0;
      })
      .filter((_) => _.visible);

    const newValues: Array<FieldValue> = [...state.fieldValues];

    pageValues.forEach((v) => {
      const valueIndex = state.fieldValues.findIndex((x) => x.questionId === v.questionId);

      let validationResult: ValidationResult;
      //This is a one off for MCC
      if (v.value?.Name === OTHER_MCC) {
        validationResult = {
          validationError: true,
          validationErrorText: 'Business Type is Required',
        };
      } else validationResult = validateFieldValue(v);

      if (
        validationResult?.validationStatus !== InputValidationStatus.WARNING &&
        (validationResult?.validationError || validationResult?.confirmationError === true)
      ) {
        validPage = false;

        if (!firstError) {
          firstError = true;
          const fieldDefinitionProperty = JSON.parse(v.fieldDefinition)?.Properties[0]?.PropertyKey ?? '';

          switch (v.fieldType) {
            case FormFieldTypes.EMAIL_VALIDATOR:
              element = document.getElementById(EmailValidatorId);
              break;
            default:
              element = document.getElementById(fieldDefinitionProperty);
          }
        }
      }

      newValues[valueIndex] = {
        ...newValues[valueIndex],
        validationError: validationResult.validationError,
        validationErrorText: validationResult.validationErrorText,
        confirmationError: validationResult.confirmationError,
      };
    });

    if (validPage) {
      validPage = await saveSummarySessionProperties(newValues);
    }

    let immediate = !validPage;

    if (validPage && setOriginalFieldValues) {
      setOriginalFieldValues(copyFieldValues(pageValues));
    }

    setState((prevState) => {
      return {
        ...prevState,
        immediateAnimation: immediate ? immediate : prevState.immediateAnimation,
        fieldValues: newValues,
        element: element,
      };
    });

    return validPage;
  };

  const handleBackClick = () => {
    if (!state.rewinding && !state.submitting) {
      if (allowBack) {
        rewind();
        setState((prevState) => {
          return {
            ...prevState,
            rewinding: true,
            immediateAnimation: true,
          };
        });
      }
    }
  };

  const handleContinueClick = async () => {
    if (await validatePageValues()) {
      setState((prevState) => {
        return {
          ...prevState,
          requestSubmit: true,
          immediateAnimation: false,
        };
      });
      setShowDataLossWarning(false);
    }
  };

  const handleContinueWithLocationValidation = async () => {
    if (await validatePageValues()) {
      setState((prevState) => {
        return {
          ...prevState,
          submitting: true,
        };
      });
      await locationService.validateNewLocation(state.fieldValues, PhaseType.FORM, '').then(async (response) => {
        if (response?.status === 400) {
          console.error('Error:', response.errorText);
          return;
        }
        if (response!.data!.length > 0) {
          setErrorListMessages(response!.data!);

          setState((prevState) => {
            return {
              ...prevState,
              submitting: false,
              requestSubmit: false,
              immediateAnimation: false,
            };
          });
          return;
        }

        setErrorListMessages([]);

        setState((prevState) => {
          return {
            ...prevState,
            submitting: false,
            requestSubmit: true,
            immediateAnimation: false,
          };
        });
        setShowDataLossWarning(false);
      });
    }
  };

  const handleClearElement = () => {
    setState((prev: FormState) => {
      return { ...prev, element: null } as FormState;
    });
  };

  const setPrivacyAgreement = (e: any) => {
    setState((prevState) => {
      return {
        ...prevState,
        privacyAgreement: e,
      };
    });
  };

  //INITIALIZE FORM/SUMMARY PHASE
  useEffect(() => {
    let subscribed = true;

    const loadFormPhase = async () => {
      let newPhase = phase as FormEntryPhase;

      let transformedFormPhase = transformFormPhaseProperties(phase as FormEntryPhase);

      state.cachedData.forEach((x) => {
        setCachedGroups((prev) => {
          let tempValues = [...prev, x.questionGroupId];
          return Array.from(new Set(tempValues));
        });
      });

      const loadedPhase = await formHelperService
        .loadFormEntryPhase(
          transformedFormPhase,
          state.cachedData,
          onDemandConfig?.autofillAvailable ?? true,
          phase?.savedProperties!,
        )
        .catch((err) => {
          submit([], PhaseType.FORM, SESSION_ERROR_MESSAGE.LOAD_FORM_ENTRY_PHASE);
          throw new Error(err);
        });

      if (subscribed) {
        const newState = {
          loading: false,
          submitting: false,
          rewinding: false,
          privacyAgreement: isRewind ? true : !newPhase.privacyText,
          privacyText: newPhase.privacyText,
          showRequiredText: loadedPhase.pageRequiredText,
          phase: loadedPhase.phase,
          fieldValues: loadedPhase.newValues,
        };

        setState((prevState) => {
          return {
            ...prevState,
            ...newState,
          };
        });

        ScrollToTop();

        setTimeout(() => {
          let main = document.getElementById(containerId);
          if (main) main.querySelector('input')?.focus({ preventScroll: true });
        }, 100);
      }

      tracker.trackEvent(PhaseType.FORM, phase, PhaseEvent.FORM_PAGE_VIEW);
    };

    const loadFormSummaryPhase = async () => {
      const newPhase = phase as FormEntrySummaryPhase;
      const fieldValues = new Array<FieldValue>();

      setState((prevState) => {
        return {
          ...prevState,
          loading: true,
        };
      });

      for (const formEntrySummaryGroup of newPhase.formEntrySummaryGroups) {
        for (let modelIndex = 0; modelIndex < formEntrySummaryGroup.formEntryPhaseModels.length; modelIndex++) {
          const formEntryPhaseModel = formEntrySummaryGroup.formEntryPhaseModels[modelIndex];
          const formEntryPhaseModelTransformed = transformFormPhaseProperties(formEntryPhaseModel as FormEntryPhase);

          try {
            const formEntryPhaseModelLoaded = await formHelperService.loadFormEntryPhase(
              formEntryPhaseModelTransformed,
              state.cachedData,
              onDemandConfig?.autofillAvailable ?? true,
              formEntryPhaseModel?.savedProperties!,
            );

            formEntryPhaseModelLoaded.newValues.forEach((newValue: FieldValue) => {
              if (!newValue.label || newValue.label === '') {
                newValue.label = formEntryPhaseModel.headerHtml?.replace(/(<([^>]+)>)/gi, '') ?? '';
              }

              fieldValues.push(newValue);
            });

            formEntrySummaryGroup.formEntryPhaseModels[modelIndex] = formEntryPhaseModelLoaded.phase;
          } catch (err: any) {
            submit([], PhaseType.FORM_SUMMARY, SESSION_ERROR_MESSAGE.LOAD_FORM_ENTRY_PHASE);

            throw new Error(err);
          }
        }
      }

      const newState = {
        loading: false,
        submitting: false,
        rewinding: false,
        phase: newPhase,
        fieldValues: fieldValues,
        isSummary: true,
      };

      if (setOriginalFieldValues) {
        setOriginalFieldValues(copyFieldValues(fieldValues));
      }

      setState((prevState) => {
        return {
          ...prevState,
          ...newState,
        };
      });

      ScrollToTop();

      setTimeout(() => {
        let main = document.getElementById(containerId);
        if (main) main.querySelector('input')?.focus({ preventScroll: true });
      }, 100);

      tracker.trackEvent(PhaseType.FORM_SUMMARY, phase, PhaseEvent.FORM_PAGE_VIEW);
    };

    const loadLocationModalPhase = async () => {
      if (subscribed && !hasFetchedLocationForm) {
        locationService.getNewLocationForm().then(async (res) => {
          let transformedFormPhase = transformFormPhaseProperties(res!.data as FormEntryPhase);

          const loadedPhase = await formHelperService
            .loadFormEntryPhase(
              transformedFormPhase,
              state.cachedData,
              session?.onDemandConfig.autofillAvailable ?? true,
              res!.data!.savedProperties!,
            )
            .catch((err) => {
              submit([], PhaseType.FORM, SESSION_ERROR_MESSAGE.LOAD_FORM_ENTRY_PHASE);
              throw new Error(err);
            });

          const newState = {
            loading: false,
            submitting: false,
            rewinding: false,
            privacyAgreement: false,
            privacyText: '',
            showRequiredText: loadedPhase.pageRequiredText,
            phase: loadedPhase.phase,
            fieldValues: loadedPhase.newValues,
          };

          setState((prevState) => {
            return {
              ...prevState,
              ...newState,
            };
          });

          setHasFetchedLocationForm(true);
        });
      }
      tracker.trackEvent(PhaseType.FORM, phase, PhaseEvent.LOCATION_MODAL_LOADED);
    };

    if (
      phase?.phaseType === PhaseType.FORM &&
      (state.phase?.id !== phase.id || state.phase?.contentReloadedTimestamp !== phase.contentReloadedTimestamp) &&
      subscribed
    ) {
      loadFormPhase();
    }

    if (
      phase?.phaseType === PhaseType.FORM_SUMMARY &&
      (state.phase?.id !== phase.id || state.phase?.contentReloadedTimestamp !== phase.contentReloadedTimestamp) &&
      subscribed
    ) {
      loadFormSummaryPhase();
    }

    if (
      phase?.phaseType === PhaseType.LOCATIONS &&
      (state.phase?.id !== phase.id || state.phase?.contentReloadedTimestamp !== phase.contentReloadedTimestamp) &&
      subscribed
    ) {
      loadLocationModalPhase();
    }

    return () => {
      subscribed = false;
    };
  }, [
    phase,
    onDemandConfig?.autofillAvailable,
    state.cachedData,
    isRewind,
    submit,
    state.phase?.id,
    state.phase?.contentReloadedTimestamp,
    hasFetchedLocationForm,
    session?.onDemandConfig.autofillAvailable,
    setOriginalFieldValues,
  ]);

  //Submit request
  useEffect(() => {
    const doSubmit = (email?: string) => {
      setState((prevState) => {
        return {
          ...prevState,
          submitting: true,
          requestSubmit: false,
        };
      });
      submit(state.fieldValues, PhaseType.FORM);
      cacheLocalValues();
      tracker.trackEvent(PhaseType.FORM, phase, PhaseEvent.FORM_PAGE_SUBMIT, {}, state.fieldValues);

      if (email != null) {
        tracker.trackEvent(PhaseType.FORM, phase, PhaseEvent.EMAIL, {
          email: email,
        });
      }
    };

    let emailValidator = document.getElementById(EmailValidatorId);
    if (emailValidator != null) {
      if (emailValidator.getAttribute('required') == null && isEmpty(emailValidator.getAttribute('value'))) {
        emailValidator = null;
      }
    }
    const shouldSubmit = state.requestSubmit && !state.submitting;

    if (
      emailValidator != null &&
      emailValidationResult != null &&
      isFetchingEmailValidation === 0 &&
      errorListMessages.length === 0 &&
      shouldSubmit
    ) {
      if (emailValidationResult?.validationStatus === EmailValidationStatus.INVALID) {
        setState((prevState) => {
          return {
            ...prevState,
            submitting: false,
            requestSubmit: false,
            element: emailValidator,
          };
        });
      }

      if (
        emailValidationResult?.validationStatus === EmailValidationStatus.RISKY &&
        riskyEmails.find((x) => x === emailValidationResult?.emailAddress) == null
      ) {
        setRiskyEmails([...riskyEmails, emailValidationResult.emailAddress]);
        setState((prevState) => {
          return {
            ...prevState,
            submitting: false,
            requestSubmit: false,
            element: emailValidator,
          };
        });
      }

      if (
        emailValidationResult?.validationStatus === EmailValidationStatus.RISKY &&
        riskyEmails.find((x) => x === emailValidationResult?.emailAddress) != null
      ) {
        doSubmit(emailValidationResult.emailAddress);
      }

      if (emailValidationResult?.validationStatus === EmailValidationStatus.VALID) {
        doSubmit(emailValidationResult.emailAddress);
      }
    } else if (
      (isFetchingRoutingNumber > 0 || isFetchingCalculateQuestionVisibility || errorListMessages.length > 0) &&
      shouldSubmit
    ) {
      setState((prevState) => {
        return {
          ...prevState,
          submitting: false,
          requestSubmit: false,
        };
      });
    } else if (shouldSubmit && emailValidator == null && errorListMessages.length === 0) {
      doSubmit();
    }
  }, [
    cacheLocalValues,
    isFetchingEmailValidation,
    emailValidationResult,
    isFetchingCalculateQuestionVisibility,
    phase,
    riskyEmails,
    state.requestSubmit,
    state.submitting,
    submit,
    isFetchingRoutingNumber,
    errorListMessages.length,
    state.fieldValues,
  ]);

  return {
    state,
    showPrivacy,
    showApplicationOpenAnotherTab,
    showDataLossWarning,
    isLoading,
    isSubmitting,
    errorListMessages,
    allowBack,
    showTracker: phase?.showTracker,
    captchaType: phase?.captchaType,
    trackerType: phase?.trackerType,
    submitButtonText,
    cachedGroups,
    isFetchingCalculateQuestionVisibility,
    onFieldChange,
    handleBackClick,
    handleContinueClick,
    handleContinueWithLocationValidation,
    handleClearElement,
    onSensitiveUnlock,
    setPrivacyAgreement,
    setCachedGroups,
    validatePageValues,
    resetFieldValues,
  };
};

export type { FormState };
export { useForm };
