import React from 'react';

import { AchDistributionQuestion } from './achDistributionQuestion';
import { BankRoutingValidator } from './BankRoutingValidator';
import { DateInput } from './DateInput';
import { EmailValidator } from './EmailValidator';
import { Equipment } from './Equipment';
import { KVPAutocomplete, KVPButton, KVPCheckbox, KVPCheckboxToggle, KVPDropdown, KVPRadio } from './KVP';
import { Matrix } from './Matrix';
import { MCCSelector } from './MCCSelector';
import { PartnerGpiSoftwareSelection } from './PartnerGpiSoftware';
import { Proportion } from './Proportion';
import { Range } from './Range';
import { SensitiveField } from './SensitiveField';
import { Slider } from './Slider';
import { SmartLocation } from './SmartLocation';
import { DisabledField, TextInput } from './TextInput';
import { FormFieldTypes, KVPTypes } from '../../../../types/enums/FormFieldTypes';
import {
  FieldValue,
  FormFieldOption,
  LockedPropertyKeys,
  PartnerEcommSoftware,
  ReferenceData,
} from '../../../../types/form';

interface Props {
  fieldDefinition: string;
  fieldType: FormFieldTypes;
  label: string;
  onFieldChange: (questionId: number, value: any, shouldValidate: boolean) => void;
  value: FieldValue;
  helperText: string | null;
  icon: string | null;
  referenceData?: Array<ReferenceData>;
  options: Array<FormFieldOption>;
  placeholder: string | null;
  onSensitiveUnlock: (questionId: number) => void;
  infoHtml: string | null;
  isLocked?: boolean;
  hideLabel?: boolean;
  renderForSummary?: boolean;
}

const FormQuestion: React.FC<Props> = ({
  fieldType,
  label,
  onFieldChange,
  value,
  fieldDefinition,
  referenceData,
  icon,
  options,
  helperText,
  placeholder,
  onSensitiveUnlock,
  infoHtml,
  isLocked = false,
  hideLabel = false,
  renderForSummary = false,
}) => {
  const fieldDefinitionJson = JSON.parse(fieldDefinition);
  switch (fieldType) {
    case FormFieldTypes.ACH_DISTRIBUTION:
      return (
        <AchDistributionQuestion
          label={label}
          onChange={(answer, shouldValidate) => {
            onFieldChange(value.questionId, { options: answer, total: 100 }, shouldValidate);
          }}
          fieldDefinitionJson={fieldDefinitionJson}
          helperText={helperText}
          value={value.value}
          errorText={value.validationErrorText}
          total={100}
          valid={value.validationError}
          options={fieldDefinitionJson.AchQuestions}
          required={value.isRequired}
          infoHtml={infoHtml}
          fieldType={fieldType}
          propertyKey={fieldDefinitionJson.AchQuestions[0].PropertyKey}
          hideLabel={hideLabel}
          renderForSummary={renderForSummary}
        />
      );
    case FormFieldTypes.SMART_LOCATION:
      return (
        <SmartLocation
          onChange={onFieldChange}
          value={value}
          infoHtml={infoHtml}
          fieldDefinitionJson={fieldDefinitionJson}
          fieldType={fieldType}
          hideLabel={hideLabel}
        />
      );
    case FormFieldTypes.STATE:
      return (
        <KVPDropdown
          label={label}
          placeholder={placeholder}
          onChange={onFieldChange}
          value={value}
          fieldDefinitionJson={fieldDefinitionJson}
          options={options}
          helperText={helperText}
          infoHtml={infoHtml}
          fieldType={fieldType}
          hideLabel={hideLabel}
        />
      );
    case FormFieldTypes.PARTNER_ECOMM_SOFTWARE:
      let softwareData = (referenceData ?? []) as Array<PartnerEcommSoftware>;
      let formFieldOptions: Array<FormFieldOption> = softwareData.map((x: PartnerEcommSoftware, i) => {
        return {
          label: x.PartnerEcommSoftware,
          formFieldQuestionId: value.questionId,
          value: x.AffiliateId,
          id: i,
          isDefaultOption: false,
          ordinal: i,
          questions: [],
        };
      });
      return (
        <KVPAutocomplete
          label={label}
          onChange={onFieldChange}
          value={value}
          fieldDefinitionJson={fieldDefinitionJson}
          options={formFieldOptions}
          helperText={helperText}
          infoHtml={infoHtml}
          fieldType={fieldType}
          hideLabel={hideLabel}
        />
      );
    case FormFieldTypes.PARTNER_GPI_SOFTWARE:
      return (
        <PartnerGpiSoftwareSelection
          label={label}
          fieldType={fieldType}
          fieldDefinitionJson={fieldDefinitionJson}
          onChange={onFieldChange}
          value={value}
          referenceData={referenceData}
          helperText={helperText}
          infoHtml={infoHtml}
          hideLabel={hideLabel}
        />
      );
    case FormFieldTypes.KVP:
      switch (fieldDefinitionJson?.KVPType) {
        case KVPTypes.BUTTON:
          return renderForSummary ? (
            <KVPDropdown
              label={label}
              placeholder={placeholder}
              onChange={onFieldChange}
              value={value}
              fieldDefinitionJson={fieldDefinitionJson}
              options={options}
              helperText={helperText}
              infoHtml={infoHtml}
              fieldType={fieldType}
              hideLabel={hideLabel}
              renderForSummary={renderForSummary}
            />
          ) : (
            <KVPButton
              label={label}
              onChange={onFieldChange}
              value={value}
              fieldDefinitionJson={fieldDefinitionJson}
              options={options}
              infoHtml={infoHtml}
              fieldType={fieldType}
              fieldSubType={fieldDefinitionJson?.KVPType}
              hideLabel={hideLabel}
            />
          );
        case KVPTypes.DROPDOWN:
          if (fieldDefinitionJson?.MultiSelect)
            return (
              <KVPDropdown
                label={label}
                placeholder={placeholder}
                onChange={onFieldChange}
                value={value}
                fieldDefinitionJson={fieldDefinitionJson}
                options={options}
                helperText={helperText}
                infoHtml={infoHtml}
                fieldType={fieldType}
                fieldSubType={fieldDefinitionJson?.KVPType}
                hideLabel={hideLabel}
                renderForSummary={renderForSummary}
              />
            );
          return (
            <KVPAutocomplete
              label={label}
              onChange={onFieldChange}
              value={value}
              fieldDefinitionJson={fieldDefinitionJson}
              options={options}
              helperText={helperText}
              infoHtml={infoHtml}
              fieldType={fieldType}
              fieldSubType={fieldDefinitionJson?.KVPType}
              hideLabel={hideLabel}
            />
          );
        case KVPTypes.RADIO:
          return renderForSummary ? (
            <KVPDropdown
              label={label}
              placeholder={placeholder}
              onChange={onFieldChange}
              value={value}
              fieldDefinitionJson={fieldDefinitionJson}
              options={options}
              helperText={helperText}
              infoHtml={infoHtml}
              fieldType={fieldType}
              hideLabel={hideLabel}
              renderForSummary={renderForSummary}
            />
          ) : (
            <KVPRadio
              label={label}
              onChange={onFieldChange}
              value={value}
              fieldDefinitionJson={fieldDefinitionJson}
              options={options}
              helperText={helperText}
              infoHtml={infoHtml}
              fieldType={fieldType}
              fieldSubType={fieldDefinitionJson?.KVPType}
              hideLabel={hideLabel}
            />
          );
        case KVPTypes.CHECKBOX:
          if (fieldDefinitionJson?.MultiSelect)
            return renderForSummary ? (
              <KVPDropdown
                label={label}
                placeholder={placeholder}
                onChange={onFieldChange}
                value={value}
                fieldDefinitionJson={fieldDefinitionJson}
                options={options}
                helperText={helperText}
                infoHtml={infoHtml}
                fieldType={fieldType}
                hideLabel={hideLabel}
                renderForSummary={renderForSummary}
              />
            ) : (
              <KVPCheckbox
                label={label}
                onChange={onFieldChange}
                value={value}
                fieldDefinitionJson={fieldDefinitionJson}
                options={options}
                helperText={helperText}
                infoHtml={infoHtml}
                fieldType={fieldType}
                fieldSubType={fieldDefinitionJson?.KVPType}
                hideLabel={hideLabel}
              />
            );
          return renderForSummary ? (
            <KVPDropdown
              label={label}
              placeholder={placeholder}
              onChange={onFieldChange}
              value={value}
              fieldDefinitionJson={fieldDefinitionJson}
              options={options}
              helperText={helperText}
              infoHtml={infoHtml}
              fieldType={fieldType}
              hideLabel={hideLabel}
              renderForSummary={renderForSummary}
            />
          ) : (
            /* Single selection checkbox = radio button */
            <KVPRadio
              label={label}
              onChange={onFieldChange}
              value={value}
              fieldDefinitionJson={fieldDefinitionJson}
              options={options}
              helperText={helperText}
              infoHtml={infoHtml}
              fieldType={fieldType}
              fieldSubType={fieldDefinitionJson?.KVPType}
              hideLabel={hideLabel}
            />
          );
        case KVPTypes.CHECKBOXTOGGLE:
          return (
            <KVPCheckboxToggle
              label={label}
              onChange={onFieldChange}
              value={value}
              fieldDefinitionJson={fieldDefinitionJson}
              options={options}
              helperText={helperText}
              infoHtml={infoHtml}
              fieldType={fieldType}
              fieldSubType={fieldDefinitionJson?.KVPType}
              hideLabel={hideLabel}
            />
          );
        default:
          return null;
      }
    case FormFieldTypes.MCC:
      return (
        <MCCSelector
          label={label}
          onChange={(answer: any, shouldValidate: boolean) => {
            onFieldChange(value.questionId, answer, shouldValidate);
          }}
          data={referenceData!}
          value={value.value}
          helperText={helperText}
          fieldDefinitionJson={fieldDefinitionJson}
          validationError={value.validationError}
          PropertyKey={fieldDefinitionJson?.Properties[0].PropertyKey}
          errorText={value.validationErrorText}
          infoHtml={infoHtml}
          fieldType={fieldType}
          hideLabel={hideLabel}
        />
      );
    case FormFieldTypes.PROPORTION:
      return (
        <Proportion
          label={label}
          onChange={(answer, shouldValidate) => {
            onFieldChange(value.questionId, { options: answer, total: fieldDefinitionJson.Total }, shouldValidate);
          }}
          fieldDefinitionJson={fieldDefinitionJson}
          helperText={helperText}
          value={value.value}
          errorText={value.validationErrorText}
          total={fieldDefinitionJson.Total}
          valid={value.validationError}
          options={fieldDefinitionJson.Properties}
          required={value.isRequired}
          infoHtml={infoHtml}
          fieldType={fieldType}
          propertyKey={fieldDefinitionJson.Properties[0].PropertyKey}
          hideLabel={hideLabel}
          renderForSummary={renderForSummary}
        />
      );
    case FormFieldTypes.NUMBER:
      if (value.isSensitive) {
        return (
          <SensitiveField
            value={value.value}
            setUnlocked={() => onSensitiveUnlock(value.questionId)}
            label={label}
            fieldType={fieldType}
            propertyKey={fieldDefinitionJson.Properties[0].PropertyKey}
            hideLabel={hideLabel}
          />
        );
      }
      return (
        <TextInput
          label={label}
          onChange={onFieldChange}
          value={value}
          placeholder={placeholder}
          fieldDefinitionJson={fieldDefinitionJson}
          helperText={helperText}
          type={FormFieldTypes.NUMBER}
          infoHtml={infoHtml}
          fieldType={fieldType}
          hideLabel={hideLabel}
          renderForSummary={renderForSummary}
        />
      );
    case FormFieldTypes.EMAIL_VALIDATOR:
      if (isLocked)
        return (
          <DisabledField
            label={label}
            value={value.value}
            title={`${label} cannot be changed at this time.`}
            fieldType={fieldType}
            propertyKey={fieldDefinitionJson.Properties[0].PropertyKey}
            hideLabel={hideLabel}
          />
        );
      return (
        <EmailValidator
          label={label}
          onChange={onFieldChange}
          value={value}
          placeholder={placeholder}
          fieldDefinitionJson={fieldDefinitionJson}
          infoHtml={infoHtml}
          fieldType={fieldType}
          hideLabel={hideLabel}
        />
      );
    case FormFieldTypes.BANK_ROUTING_VALIDATOR:
      return (
        <BankRoutingValidator
          label={label}
          onChange={onFieldChange}
          value={value}
          placeholder={placeholder}
          fieldDefinitionJson={fieldDefinitionJson}
          infoHtml={infoHtml}
          fieldType={fieldType}
          hideLabel={hideLabel}
        />
      );
    case FormFieldTypes.TEXT:
    case FormFieldTypes.NAME:
    case FormFieldTypes.URL:
    case FormFieldTypes.ADDRESS:
    case FormFieldTypes.ALPHA_NUMERIC:
      if (value.isSensitive) {
        return (
          <SensitiveField
            value={value.value}
            setUnlocked={() => onSensitiveUnlock(value.questionId)}
            label={label}
            fieldType={fieldType}
            propertyKey={fieldDefinitionJson.Properties[0].PropertyKey}
            hideLabel={hideLabel}
          />
        );
      }
      /*Note: This is for hard locked fields across all configs, not config driven. (I.E. Any unique identifiers/etc.)*/
      if (
        (LockedPropertyKeys.find((x) => x === fieldDefinitionJson.Properties[0].PropertyKey) != null && isLocked) ||
        (fieldDefinitionJson?.DisableEditOnSummary && renderForSummary)
      )
        return (
          <DisabledField
            value={value.value ? value.value : value}
            title={`${label} cannot be changed at this time.`}
            label={label}
            fieldType={fieldType}
            propertyKey={fieldDefinitionJson.Properties[0].PropertyKey}
            hideLabel={hideLabel}
          />
        );

      return (
        <TextInput
          label={label}
          onChange={onFieldChange}
          value={value}
          placeholder={placeholder}
          fieldDefinitionJson={fieldDefinitionJson}
          type={FormFieldTypes.TEXT}
          helperText={helperText}
          infoHtml={infoHtml}
          fieldType={fieldType}
          hideLabel={hideLabel}
          renderForSummary={renderForSummary}
        />
      );
    case FormFieldTypes.DATE:
      return (
        <DateInput
          label={label}
          onChange={onFieldChange}
          value={value}
          fieldDefinitionJson={fieldDefinitionJson}
          helperText={helperText}
          infoHtml={infoHtml}
          fieldType={fieldType}
          hideLabel={hideLabel}
        />
      );
    case FormFieldTypes.EQUIPMENT:
      return (
        <Equipment
          label={label}
          infoHtml={infoHtml}
          onChange={onFieldChange}
          value={value}
          fieldDefinitionJson={fieldDefinitionJson}
          fieldType={fieldType}
          hideLabel={hideLabel}
        />
      );
    case FormFieldTypes.MATRIX:
      return (
        <Matrix label={label} fieldDefinitionJson={fieldDefinitionJson} fieldType={fieldType} hideLabel={hideLabel} />
      );
    case FormFieldTypes.DATE_RANGE:
    case FormFieldTypes.TIME_RANGE:
      return (
        <Range
          label={label}
          onChange={onFieldChange}
          fieldDefinitionJson={fieldDefinitionJson}
          value={value}
          helperText={helperText}
          infoHtml={infoHtml}
          fieldType={fieldType}
          hideLabel={hideLabel}
        />
      );
    case FormFieldTypes.SLIDER:
      return (
        <Slider
          label={label}
          onChange={onFieldChange}
          value={value}
          placeholder={placeholder}
          required={value.isRequired}
          fieldDefinitionJson={fieldDefinitionJson}
          helperText={helperText}
          infoHtml={infoHtml}
          fieldType={fieldType}
          hideLabel={hideLabel}
          renderForSummary={renderForSummary}
        />
      );
    default:
      return null;
  }
};

export { FormQuestion };
