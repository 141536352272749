export const vegaLight = {
  BgBrand: '#596063', // bg-brand
  BgPage: '#F0F3F7', // bg-page
  BgTransparent: 'rgba(0, 0, 0, 0)', // bg-transparent
  BgPrimary: '#FCFCFC', // bg-primary
  BgSecondary: '#F5F7F7', // bg-secondary
  BgTertiary: '#F0F3F7', // bg-tertiary
  BgQuaternary: '#F1F8FB', // bg-quaternary
  BgInvertedPrimary: '#04041C', // bg-inverted-primary
  BgInvertedSecondary: '#121227', // bg-inverted-secondary
  BgInvertedTertiary: '#181830', // bg-inverted-tertiary
  BgInvertedQuaternary: '#464765', // bg-inverted-quaternary
  BgAction: '#1362E2', // bg-action
  BgActionHover: '#0475F5', // bg-action:hover
  BgActionFocus: '#0097FF', // bg-action:focus
  BgActionActive: '#1D41C3', // bg-action:active
  BgActionSecondary: '#FCFCFC', // bg-action-secondary
  BgActionQuarternary: '#DEE1E3', // bg-action-quarternary
  BgDanger: '#E63257', // bg-danger
  BgDangerHover: '#FF5772', // bg-danger:hover
  BgDangerFocus: '#F0345B', // bg-danger:focus
  BgDangerActive: '#BD2947', // bg-danger:active
  BgDatagridRowSelected: 'rgba(19, 98, 226, 0.4)', // bg-datagrid-row-selected
  BgStatusSuccess: '#A0E9A7', // bg-status-success
  BgStatusWarning: '#FFDC83', // bg-status-warning
  BgStatusInfo: '#CDD1D3', // bg-status-info
  BgStatusError: '#FF5772', // bg-status-error
  BgSidebar: '#FFFFFF', // bg-sidebar
  BgSidebarItemHover: '#F4F5F7', // bg-sidebar-item:hover
  BgSidebarItemSelected: '#F4F5F7', // bg-sidebar-item-selected
  BgSidebarIcon: '#A4ACB8', // bg-sidebar-icon
  BgSidebarIconHover: '#1362E2', // bg-sidebar-icon:hover
  BgSidebarIconSelected: '#1362E2', // bg-sidebar-icon-selected
  BgBackdropPopover: 'rgba(32, 54, 69, 0.16)', // bg-backdrop-popover
  BgBackdropModal: 'rgba(18, 18, 39, 0.85)', // bg-backdrop-modal
  BgBackdropSidebar: 'rgba(18, 18, 39, 0.85)', // bg-backdrop-sidebar
  BgAccent1Primary: '#73E6DC', // bg-accent1-primary
  BgAccent1Secondary: '#C1F4EF', // bg-accent1-secondary
  BgAccent1Tertiary: '#E0F9F7', // bg-accent1-tertiary
  BgAccent2Primary: '#806BFF', // bg-accent2-primary
  BgAccent2Secondary: '#C7BDFF', // bg-accent2-secondary
  BgAccent2Tertiary: '#E3DEFF', // bg-accent2-tertiary
  BgAccent3Primary: '#FFDC83', // bg-accent3-primary
  BgAccent3Secondary: '#FFEFC8', // bg-accent3-secondary
  BgAccent3Tertiary: '#FFF7E3', // bg-accent3-tertiary
  BgAccent4Primary: '#FF9571', // bg-accent4-primary
  BgAccent4Secondary: '#FFD0C0', // bg-accent4-secondary
  BgAccent4Tertiary: '#FFE7DF', // bg-accent4-tertiary
  BgAccent5Primary: '#00BBFF', // bg-accent5-primary
  BgAccent5Secondary: '#8EDFF9', // bg-accent5-secondary
  BgAccent5Tertiary: '#E3F7FD', // bg-accent5-tertiary
  BgAccent6Primary: '#FF82C9', // bg-accent6-primary
  BgAccent6Secondary: '#FFC7E7', // bg-accent6-secondary
  BgAccent6Tertiary: '#FFE3F3', // bg-accent6-tertiary
  BgAccent7Primary: '#259F9F', // bg-accent7-primary
  BgAccent7Secondary: '#9BD4D6', // bg-accent7-secondary
  BgAccent7Tertiary: '#D0EFEF', // bg-accent7-tertiary
  BgAccent8Primary: '#563D82', // bg-accent8-primary
  BgAccent8Secondary: '#C7A2CC', // bg-accent8-secondary
  BgAccent8Tertiary: '#E6D6EA', // bg-accent8-tertiary
  BgAccent9Primary: '#FFBF3F', // bg-accent9-primary
  BgAccent9Secondary: '#FED78E', // bg-accent9-secondary
  BgAccent9Tertiary: '#FBE2B9', // bg-accent9-tertiary
  BgAccent10Primary: '#ED7A23', // bg-accent10-primary
  BgAccent10Secondary: '#F19255', // bg-accent10-secondary
  BgAccent10Tertiary: '#F5BB94', // bg-accent10-tertiary
  Divider: '#ABC6D8', //bg-divider
  DividerSecondary: '#C7D9E5', //bg-divider-secondary
  TextComplete: '#0475f5', // text-complete
  TextDownload: '#89B0F0', // text-download
  TextNext: '#999999', // text-next
  TextProgress: '#DD9B18', // text-progress
  TextPrimary: '#203645', // text-primary
  TextSecondary: '#6B747D', // text-secondary
  TextDisabled: '#B0B4B5', // text-disabled
  TextInvertedPrimary: '#FCFCFC', // text-inverted-primary
  TextInvertedSecondary: '#BCBAD5', // text-inverted-secondary
  TextLink: '#1362E2', // text-link
  TextLinkHover: '#0475F5', // text-link:hover
  TextLinkActive: '#1D41C3', // text-link:active
  TextLinkFocus: '#0475F5', // text-link:focus
  TextLinkVisited: '#5F38CE', // text-link:visited
  TextWarning: '#B35D1B',
  TextDanger: '#BD2947',
  TextDangerLink: '#E63257', // text-danger-link
  TextDangerLinkHover: '#E94768', // text-danger-link:hover
  TextDangerLinkActive: '#BD2947', // text-danger-link:active
  TextDangerLinkFocus: '#F0345B', // text-danger-link:focus
  TextDangerLinkVisited: '#E63257', // text-danger-link:visited
  TextSidebarLink: '#203645', // text-sidebar-link
  TextSidebarLinkHover: '#1362E2', // text-sidebar-link:hover
  TextSidebarLinkSelected: '#1362E2', // text-sidebar-link-selected
  TextSuccess: '#01801F ', // text-success
  TextInputPlaceholder: '#B0B4B5', // text-input-placeholder
  TextAccent1Primary: '#73E6DC', // text-accent1-primary
  TextAccent2Primary: '#806BFF', // text-accent2-primary
  TextAccent3Primary: '#FFDC83', // text-accent3-primary
  TextAccent4Primary: '#FF9571', // text-accent4-primary
  TextAccent5Primary: '#00BBFF', // text-accent5-primary
  TextAccent6Primary: '#F0345B', // text-accent6-primary
  TextAccent7Primary: '#259F9F', // text-accent7-primary
  TextAccent8Primary: '#563D82', // text-accent8-primary
  TextAccent9Primary: '#FFBF3F', // text-accent9-primary
  TextAccent10Primary: '#ED7A23', // text-accent10-primary
  BorderColorAction: '#1362E2', // borderColor-action
  BorderColorActionHover: '#0475F5', // borderColor-action:hover
  BorderColorActionActive: '#1D41C3', // borderColor-action:active
  BorderColorDanger: '#E63257', // borderColor-danger
  BorderColorDangerHover: '#FF5772', // borderColor-danger:hover
  BorderColorDangerActive: '#BD2947', // borderColor-danger:active
  BorderColorInputField: '#ABC6D8', // borderColor-input-field
  BorderColorInputFieldHover: '#73A0BE', // borderColor-input-field:hover
  BorderColorInputFieldFocus: '#1362E2', // borderColor-input-field:focus
  BorderColorInputFieldDanger: '#E63257', // borderColor-input-field-danger
  BorderColorInputFieldDangerHover: '#FF5772', // borderColor-input-field-danger:hover
  BorderColorInputFieldDangerFocus: '#E94768', // borderColor-input-field-danger:focus
  BorderColorInputFieldDisabled: '#DEE1E3', // borderColor-input-field-disabled
  BorderColorSidebarItemFocus: '#1362E2', // borderColor-sidebar-item:focus
  SelectionTile: '#FCFCFC', // selection-tile
  SelectionTileHover: '#F7F7F7', // selection-tile:hover
  SelectionTileSelected: '#F2F7FF', // selection-tile:selected
  SelectionTileSelectedHover: '#E5EFFF', // selection-tile:selected:hover
};

export const vegaDark = {
  BgBrand: '#D2D0DF', // bg-brand
  BgPage: '#181830', // bg-page
  BgTransparent: 'rgba(0, 0, 0, 0)', // bg-transparent
  BgPrimary: '#04041C', // bg-primary
  BgSecondary: '#121227', // bg-secondary
  BgTertiary: '#181830', // bg-tertiary
  BgQuaternary: '#464765', // bg-quaternary
  BgInvertedPrimary: '#FCFCFC', // bg-inverted-primary
  BgInvertedSecondary: '#F7F7F7', // bg-inverted-secondary
  BgInvertedTertiary: '#F0F3F7', // bg-inverted-tertiary
  BgInvertedQuaternary: '#F1F8FB', // bg-inverted-quaternary
  BgAction: '#00BBFF', // bg-action
  BgActionHover: '#00CCFF', // bg-action:hover
  BgActionFocus: '#55DAFF', // bg-action:focus
  BgActionActive: '#04A5F5', // bg-action:active
  BgActionSecondary: 'rgba(0, 0, 0, 0)', // bg-action-secondary
  BgActionQuarternary: '#33393D', // bg-action-quarternary
  BgDanger: '#FF3861', // bg-danger
  BgDangerHover: '#FF477B', // bg-danger:hover
  BgDangerFocus: '#FF5288', // bg-danger:focus
  BgDangerActive: '#E63257', // bg-danger:active
  BgDatagridRowSelected: 'rgba(0, 187, 255, 0.4)', // bg-datagrid-row-selected
  BgStatusSuccess: '#7BF297', // bg-status-success
  BgStatusWarning: '#FFD469', // bg-status-warning
  BgStatusInfo: '#FCFCFC', // bg-status-info
  BgStatusError: '#FF3861', // bg-status-error
  BgSidebar: '#121227', //CUSTOM MODIFICATION TO MATCH HEADER
  BgSidebarItemHover: '#252544', // bg-sidebar-item:hover
  BgSidebarItemSelected: '#252544', // bg-sidebar-item-selected
  BgSidebarIcon: '#BCBAD5', // bg-sidebar-icon
  BgSidebarIconHover: '#00BBFF', // bg-sidebar-icon:hover
  BgSidebarIconSelected: '#00BBFF', // bg-sidebar-icon-selected
  BgBackdropPopover: 'rgba(70, 71, 101, 0.56)', // bg-backdrop-popover
  BgBackdropModal: 'rgba(70, 71, 101, 0.8)', // bg-backdrop-modal
  BgBackdropSidebar: 'rgba(70, 71, 101, 0.8)', // bg-backdrop-sidebar
  BgAccent1Primary: '#6DD9CA', // bg-accent1-primary
  BgAccent1Secondary: '#AEEAE2', // bg-accent1-secondary
  BgAccent1Tertiary: '#DFF7F3', // bg-accent1-tertiary
  BgAccent2Primary: '#5F38CE', // bg-accent2-primary
  BgAccent2Secondary: '#B8A7E9', // bg-accent2-secondary
  BgAccent2Tertiary: '#DBD3F4', // bg-accent2-tertiary
  BgAccent3Primary: '#FFCB69', // bg-accent3-primary
  BgAccent3Secondary: '#FBE2B9', // bg-accent3-secondary
  BgAccent3Tertiary: '#FFF3DE', // bg-accent3-tertiary
  BgAccent4Primary: '#FF8157', // bg-accent4-primary
  BgAccent4Secondary: '#FFB9A2', // bg-accent4-secondary
  BgAccent4Tertiary: '#FFE3DA', // bg-accent4-tertiary
  BgAccent5Primary: '#04A5F5', // bg-accent5-primary
  BgAccent5Secondary: '#55DAFF', // bg-accent5-secondary
  BgAccent5Tertiary: '#E3F7FD', // bg-accent5-tertiary
  BgAccent6Primary: '#FF54A5', // bg-accent6-primary
  BgAccent6Secondary: '#FFB3D7', // bg-accent6-secondary
  BgAccent6Tertiary: '#FFD9EB', // bg-accent6-tertiary
  BgAccent7Primary: '#259F9F', // bg-accent7-primary
  BgAccent7Secondary: '#9BD4D6', // bg-accent7-secondary
  BgAccent7Tertiary: '#D0EFEF', // bg-accent7-tertiary
  BgAccent8Primary: '#563D82', // bg-accent8-primary
  BgAccent8Secondary: '#C7A2CC', // bg-accent8-secondary
  BgAccent8Tertiary: '#E6D6EA', // bg-accent8-tertiary
  BgAccent9Primary: '#FFBF3F', // bg-accent9-primary
  BgAccent9Secondary: '#FED78E', // bg-accent9-secondary
  BgAccent9Tertiary: '#FBE2B9', // bg-accent9-tertiary
  BgAccent10Primary: '#ED7A23', // bg-accent10-primary
  BgAccent10Secondary: '#F19255', // bg-accent10-secondary
  BgAccent10Tertiary: '#F5BB94', // bg-accent10-tertiary
  Divider: '#203645', //bg-divider
  DividerSecondary: '#33393D', //bg-divider-secondary
  TextComplete: '#0475f5', // text-complete
  TextDownload: '#89B0F0', // text-download
  TextNext: '#999999', // text-next
  TextProgress: '#DD9B18', // text-progress
  TextPrimary: '#FCFCFC', // text-primary
  TextSecondary: '#BCBAD5', // text-secondary
  TextDisabled: '#464765', // text-disabled
  TextInvertedPrimary: '#04041C', // text-inverted-primary
  TextInvertedSecondary: '#6B747D', // text-inverted-secondary
  TextLink: '#00BBFF', // text-link
  TextLinkHover: '#00CCFF', // text-link:hover
  TextLinkActive: '#04A5F5', // text-link:active
  TextLinkFocus: '#00CCFF', // text-link:focus
  TextLinkVisited: '#806BFF', // text-link:visited
  TextWarning: '#ED7A23',
  TextDanger: '#FF3861',
  TextDangerLink: '#FF3861', // text-danger-link
  TextDangerLinkHover: '#FF477B', // text-danger-link:hover
  TextDangerLinkActive: '#E63257', // text-danger-link:active
  TextDangerLinkFocus: '#FF5288', // text-danger-link:focus
  TextDangerLinkVisited: '#FF3861', // text-danger-link:visited
  TextSidebarLink: '#FCFCFC', // text-sidebar-link
  TextSidebarLinkHover: '#00BBFF', // text-sidebar-link:hover
  TextSidebarLinkSelected: '#00BBFF', // text-sidebar-link-selected
  TextSuccess: '#59D977 ', // text-success
  TextInputPlaceholder: '#464765', // text-input-placeholder
  TextAccent1Primary: '#6DD9CA', // text-accent1-primary
  TextAccent2Primary: '#5F38CE', // text-accent2-primary
  TextAccent3Primary: '#FFCB69', // text-accent3-primary
  TextAccent4Primary: '#FF8157', // text-accent4-primary
  TextAccent5Primary: '#04A5F5', // text-accent5-primary
  TextAccent6Primary: '#FF477B', // text-accent6-primary
  TextAccent7Primary: '#259F9F', // text-accent7-primary
  TextAccent8Primary: '#563D82', // text-accent8-primary
  TextAccent9Primary: '#FFBF3F', // text-accent9-primary
  TextAccent10Primary: '#ED7A23', // text-accent10-primary
  BorderColorAction: '#00BBFF', // borderColor-action
  BorderColorActionHover: '#00CCFF', // borderColor-action:hover
  BorderColorActionActive: '#04A5F5', // borderColor-action:active
  BorderColorDanger: '#FF3861', // borderColor-danger
  BorderColorDangerHover: '#FF477B', // borderColor-danger:hover
  BorderColorDangerActive: '#E63257', // borderColor-danger:active
  BorderColorInputField: '#203645', // borderColor-input-field
  BorderColorInputFieldHover: '#3A627D', // borderColor-input-field:hover
  BorderColorInputFieldFocus: '#00BBFF', // borderColor-input-field:focus
  BorderColorInputFieldDanger: '#FF3861', // borderColor-input-field-danger
  BorderColorInputFieldDangerHover: '#FF477B', // borderColor-input-field-danger:hover
  BorderColorInputFieldDangerFocus: '#FF3861', // borderColor-input-field-danger:focus
  BorderColorInputFieldDisabled: '#04041C', // borderColor-input-field-disabled
  BorderColorSidebarItemFocus: '#00BBFF', // borderColor-sidebar-item:focus
  SelectionTile: '#203645', // selection-tile
  SelectionTileHover: '#2D4C61', // selection-tile:hover
  SelectionTileSelected: '#203645', // selection-tile:selected
  SelectionTileSelectedHover: '#2D4C61', // selection-tile:selected:hover
};

export enum VegaMuiThemeTypes {
  Light = 'light',
  Dark = 'dark',
}

export const vegaMuiTheme: any = (theme: string, alpha: any, overrides: any) => {
  let vegaColors = vegaLight;
  if (theme === VegaMuiThemeTypes.Dark) vegaColors = vegaDark;

  let customColors = {
    //Custom Light
    CustomBorder: '#e7e7e7b3',
    PrimaryButtonText: '#FCFCFC',
    PaperHover: '#ebebeb',
    PaperSelected: '#d6d6d6',
    Footer: '#303a45',
    FooterText: '#94A8BC',
    shadowCard: '0px 2px 4px rgba(0, 0, 0, 0.02), 0px 2px 8px rgba(0, 0, 0, 0.04)',
    shadowPopover:
      '0px 8px 80px rgba(2, 2, 15, 0.16), 0px 4px 24px rgba(2, 2, 15, 0.08), 0px 6px 40px rgba(2, 2, 15, 0.04)',
    lightBackgroundDisabled: '#B0B4B5',
    ringInputFocus: '0px 0px 0px 4px rgba(19, 98, 226, 0.25)',
    ringInputFocusDanger: '0px 0px 0px 4px rgba(230, 50, 87, 0.25)',
  };

  if (theme === 'dark') {
    //Custom Dark
    customColors.CustomBorder = '#555555b3';
    customColors.PrimaryButtonText = '#FCFCFC';
    customColors.PaperHover = '#3b364c8c';
    customColors.PaperSelected = '#b9b8b84f';
    customColors.Footer = '#303a45';
    customColors.FooterText = '#94A8BC';
    customColors.shadowCard = '0px 2px 4px rgba(0, 0, 0, 0.18), 0px 2px 8px rgba(0, 0, 0, 0.1)';
    customColors.shadowPopover =
      '0px 8px 80px rgba(2, 2, 15, 0.16), 0px 4px 24px rgba(2, 2, 15, 0.02), 0px 6px 40px rgba(2, 2, 15, 0.01)';
    customColors.lightBackgroundDisabled = '#464765';
    customColors.ringInputFocus = '0px 0px 0px 4px rgba(0, 187, 255, 0.25)';
    customColors.ringInputFocusDanger = '0px 0px 0px 4px rgba(255, 56, 97, 0.25)';
  }

  return {
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 993,
        lg: 1200,
        xl: 1536,
      },
    },
    palette: {
      primary: {
        main: vegaColors.BgAction,
        light: vegaColors.BgPrimary,
        contrastText: vegaColors.TextInvertedPrimary,
        border: vegaColors.BorderColorAction,
        bgPrimary: vegaColors.BgPrimary,
        bgSecondary: vegaColors.BgSecondary,
        bgTertiary: vegaColors.BgTertiary,
        bgQuarternary: vegaColors.BgQuaternary,
        bgPage: vegaColors.BgPage,
        bgInfo: vegaColors.BgStatusInfo,
        bgActionActive: vegaColors.BgActionActive,
        bgActionQuarternary: vegaColors.BgActionQuarternary,
        bgSidebar: vegaColors.BgSidebar,
        bgSidebarItemHover: vegaColors.BgSidebarItemHover,
        bgSidebarItemSelected: vegaColors.BgSidebarItemSelected,
        bgSidebarIcon: vegaColors.BgSidebarIcon,
        bgSidebarIconHover: vegaColors.BgSidebarIconHover,
        bgSidebarIconSelected: vegaColors.BgSidebarIconSelected,
        bgBackdropSiderbar: vegaColors.BgBackdropSidebar,
        divider: vegaColors.Divider,
        dividerSecondary: vegaColors.DividerSecondary,
        textComplete: vegaColors.TextComplete,
        textDownload: vegaColors.TextDownload,
        textNext: vegaColors.TextNext,
        textProgress: vegaColors.TextProgress,
        bgStatusSuccess: vegaColors.BgStatusSuccess,
        textSidebarLink: vegaColors.TextSidebarLink,
        textSidebarLinkHover: vegaColors.TextSidebarLinkHover,
        textSidebarLinkSelected: vegaColors.TextSidebarLinkSelected,
        textSuccess: vegaColors.TextSuccess,
        inputBorderDisabled: vegaColors.BorderColorInputFieldDisabled,
        inputBorder: vegaColors.BorderColorInputField,
        inputFocus: vegaColors.BorderColorInputFieldFocus,
        inputHover: vegaColors.BorderColorInputFieldHover,

        /*accent5Tertiary is a typo, but left for backward compatibility*/
        accent5Tertiary: vegaColors.BgAccent5Tertiary,
        accentPrimary1: vegaColors.BgAccent1Primary,
        accentPrimary2: vegaColors.BgAccent2Primary,
        accentPrimary3: vegaColors.BgAccent3Primary,
        accentPrimary4: vegaColors.BgAccent4Primary,
        accentPrimary5: vegaColors.BgAccent5Primary,
        accentPrimary6: vegaColors.BgAccent6Primary,
        accentPrimary7: vegaColors.BgAccent7Primary,
        accentPrimary8: vegaColors.BgAccent8Primary,
        accentPrimary9: vegaColors.BgAccent9Primary,
        accentPrimary10: vegaColors.BgAccent10Primary,
        accentSecondary1: vegaColors.BgAccent1Secondary,
        accentSecondary2: vegaColors.BgAccent2Secondary,
        accentSecondary3: vegaColors.BgAccent3Secondary,
        accentSecondary4: vegaColors.BgAccent4Secondary,
        accentSecondary5: vegaColors.BgAccent5Secondary,
        accentSecondary6: vegaColors.BgAccent6Secondary,
        accentSecondary7: vegaColors.BgAccent7Secondary,
        accentSecondary8: vegaColors.BgAccent8Secondary,
        accentSecondary9: vegaColors.BgAccent9Secondary,
        accentSecondary10: vegaColors.BgAccent10Secondary,
        accentTertiary1: vegaColors.BgAccent1Tertiary,
        accentTertiary2: vegaColors.BgAccent2Tertiary,
        accentTertiary3: vegaColors.BgAccent3Tertiary,
        accentTertiary4: vegaColors.BgAccent4Tertiary,
        accentTertiary5: vegaColors.BgAccent5Tertiary,
        accentTertiary6: vegaColors.BgAccent6Tertiary,
        accentTertiary7: vegaColors.BgAccent7Tertiary,
        accentTertiary8: vegaColors.BgAccent8Tertiary,
        accentTertiary9: vegaColors.BgAccent9Tertiary,
        accentTertiary10: vegaColors.BgAccent10Tertiary,
        paperHover: customColors.PaperHover,
      },
      secondary: {
        main: vegaColors.TextAccent1Primary,
        contrastText: vegaColors.TextInvertedPrimary,
        light: vegaColors.BgQuaternary,
        black: vegaColors.BgBrand,
        selectionTileBg: vegaColors.SelectionTile,
        selectionTileHover: vegaColors.SelectionTileHover,
        selectionTileSelected: vegaColors.SelectionTileSelected,
        selectionTileSelectedHover: vegaColors.SelectionTileSelectedHover,
      },
      dark: {
        main: vegaColors.BgInvertedQuaternary,
        light: vegaColors.TextInvertedSecondary,
        neutralDarkest: vegaColors.BgInvertedTertiary,
        contrastText: vegaColors.TextPrimary,
        bgQuarternary: vegaColors.BgQuaternary,
        bgInvertedSecondary: vegaColors.BgInvertedSecondary,
        customBorder: customColors.CustomBorder,
        footer: customColors.Footer,
        footerText: customColors.FooterText,
      },
      warning: {
        main: vegaColors.TextDangerLink,
        border: vegaColors.BgAccent10Primary,
        text: vegaColors.TextWarning,
        error: vegaColors.TextDanger,
      },
      text: {
        primary: vegaColors.TextPrimary,
        secondary: vegaColors.TextSecondary,
        error: vegaColors.TextDangerLink,
        placeholder: vegaColors.TextInputPlaceholder,
      },
      background: {
        default: vegaColors.BgPage,
      },
    },
    zIndex: {
      modal: 10000,
      snackbar: 11000,
      tooltip: 12000,
    },
    typography: {
      fontFamily: 'inherit',
      body1: {
        fontWeight: '500',
      },
      button: {
        textTransform: 'none',
        letterSpacing: '.04rem',
        fontWeight: '500',
        maxHeight: '3.4rem',
        lineHeight: '1.9',
      },
    },
    shape: {
      borderRadius: 12,
    },
    components: {
      MuiSkeleton: {
        styleOverrides: {
          root: {
            borderRadius: 12,
          },
        },
      },
      MuiTypography: {
        styleOverrides: {
          root: {
            fontFamily: 'inherit',
          },
          h5: {
            lineHeight: 2,
          },
        },
      },
      MuiDataGrid: {
        styleOverrides: {
          columnHeaders: {
            backgroundColor: vegaColors.BgTertiary,
            color: vegaColors.TextPrimary,
            borderRadius: '12px',
            borderBottom: 'none',
          },
          //Enable if alternating row colors
          // cell: {
          //   borderBottom: 'none',
          // },
          sortIcon: {
            color: vegaColors.TextSecondary,
          },
          root: {
            border: '0',
            '&.MuiDataGrid-root .MuiDataGrid-cell:focus': {
              outline: 'none',
            },
            width: '100%',
            '& .MuiDataGrid-columnHeaders': {
              color: vegaColors.TextInvertedPrimary,
              backgroundColor: vegaColors.TextPrimary,
              '& .MuiDataGrid-iconButtonContainer .MuiIconButton-root': {
                color: vegaColors.TextInvertedPrimary,
              },
            },
            '& .MuiDataGrid-cell:focus': {
              outline: 0,
            },
            '& .MuiDataGrid-columnHeader:focus': {
              outline: 0,
            },
            '& .MuiDataGrid-columnHeader:last-child .MuiDataGrid-columnSeparator': {
              display: 'none',
            },
          },
          row: {
            borderRadius: '12px',
            cursor: 'pointer',
            '&.Mui-selected, &.Mui-selected:hover': {
              backgroundColor: vegaColors.BgDatagridRowSelected,
            },
            width: '100%',
          },
          //This ruins the row hover functionality
          // virtualScrollerRenderZone: {
          //   '& .MuiDataGrid-row': {
          //     '&:nth-child(2n)': { backgroundColor: vegaColors.BgSecondary },
          //     '&:nth-child(2n):hover': { backgroundColor: vegaColors.bgQuarternary },
          //   },
          // },
          // footerContainer: {
          //   borderTop: 'none',
          // },
        },
      },
      MuiClock: {
        styleOverrides: {
          amButton: ({ ownerState }: { ownerState: any }) => ({
            ...(ownerState?.meridiemMode === 'am' && {
              backgroundColor: vegaColors.BgActionActive,
              color: vegaColors.TextInvertedPrimary + ' !important',
              '&:hover': {
                color: vegaColors.TextInvertedPrimary + ' !important',
                backgroundColor: vegaColors.BgActionHover,
              },
            }),
          }),
          pmButton: ({ ownerState }: { ownerState: any }) => ({
            ...(ownerState?.meridiemMode === 'pm' && {
              backgroundColor: vegaColors.BgActionActive,
              color: vegaColors.TextInvertedPrimary + ' !important',
              '&:hover': {
                color: vegaColors.TextInvertedPrimary + ' !important',
                backgroundColor: vegaColors.BgActionHover,
              },
            }),
          }),
        },
      },
      MuiTablePagination: {
        styleOverrides: {
          select: {
            paddingTop: '2px !important',
            paddingBottom: '2px !important',
            border: `1px solid ${vegaColors.BgTertiary}`,
          },
        },
      },
      MuiDivider: {
        root: {
          backgroundColor: vegaColors.TextPrimary,
          opacity: 0.3,
          width: '100%',
          marginBlockStart: '.3em',
          marginBottom: '.7em',
        },
      },
      MuiListItemIcon: {
        styleOverrides: {
          minWidth: '20px !important',
          marginRight: '5px',
        },
      },
      MuiMenuItem: {
        styleOverrides: {
          root: {
            '&:hover': {
              backgroundColor: vegaColors.BgQuaternary,
            },
            '&.Mui-selected, &.Mui-selected:hover': {
              backgroundColor: vegaColors.BgTertiary,
            },
            selected: {
              backgroundColor: vegaColors.BgTertiary,
            },
            focusVisible: {
              backgroundColor: vegaColors.BgPrimary,
            },
            fontSize: '1rem',
            paddingLeft: '8px',
            paddingRight: '8px',
          },
        },
      },
      MuiListItem: {
        styleOverrides: {
          button: {
            '&:hover': {
              backgroundColor: customColors.PaperHover,
            },
          },
          root: {
            '&.Mui-selected, &.Mui-selected:hover': {
              backgroundColor: customColors.PaperSelected,
            },
          },
        },
      },
      MuiPaper: {
        styleOverrides: {
          root: {
            borderRadius: '8px',
            backgroundColor: vegaColors.BgPrimary,
            boxShadow: '0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -2px rgba(0, 0, 0, 0.1)',
            border: `1px solid ${vegaColors.BorderColorInputField}`,
          },
          rounded: {
            borderRadius: '8px',
          },
        },
      },
      MuiLinearProgress: {
        styleOverrides: {
          root: {
            zIndex: 100,
          },
        },
      },
      MuiAccordion: {
        styleOverrides: {
          root: {
            padding: '6px',
            boxShadow: 'none',
            border: 'none',
            borderRadius: '16px',
            '&:before': {
              backgroundColor: 'transparent',
            },
            '& .MuiSvgIcon-root': {
              color: vegaColors.BgAction,
            },
          },
        },
      },
      MuiAccordionSummary: {
        // styleOverrides: {
        //   root: {
        //     '&.Mui-expanded': {
        //       minHeight: '30px',
        //     },
        //   },
        //   content: {
        //     '&.Mui-expanded': {
        //       margin: '0px !important',
        //     },
        //   },
        // },
      },
      MuiCard: {
        styleOverrides: {
          root: {
            boxShadow: customColors.shadowCard,
            borderRadius: '12px',
            border: 'none',
          },
        },
      },
      MuiCardContent: {
        styleOverrides: {
          root: {
            padding: '24px',
          },
        },
      },
      MuiPopover: {
        styleOverrides: {
          paper: {
            borderRadius: '8px',
            backgroundColor: vegaColors.BgPrimary,
            boxShadow: '0px 4px 6px -1px rgba(0, 0, 0, 0.1), 0px 2px 4px -2px rgba(0, 0, 0, 0.1)',
            border: `1px solid ${vegaColors.BorderColorInputField}`,
            transition: 'none !important',
          },
        },
      },
      MuiList: {
        styleOverrides: {
          root: {
            borderRadius: '14px',
            maxHeight: '20rem',
          },
        },
      },
      MuiRadio: {
        styleOverrides: {
          root: {
            color: `${vegaColors.BorderColorInputField}`,
            '&:checked': {
              color: vegaColors.BgActionActive,
            },
            '&.MuiSvgIcon-root': {
              color: vegaColors.BgAccent10Tertiary,
            },
          },
        },
      },
      MuiFilledInput: {
        styleOverrides: {
          input: {
            textIndent: '.7rem',
            paddingTop: '9px',
            fontWeight: 400,
            paddingLeft: '6px',
            '&::placeholder': {
              color: vegaColors.TextInputPlaceholder,
              opacity: '1',
              fontWeight: 500,
              transform: 'none',
            },
            '&:-webkit-autofill': {
              borderRadius: '8px !important',
              height: 'fit-content',
            },
          },
          root: {
            'label + &': {
              marginTop: '40px',
            },
            height: '3rem',
            fontFamily: 'inherit',
            borderRadius: '8px',
            backgroundColor: vegaColors.BgPrimary,
            border: `1px solid ${vegaColors.BorderColorInputField}`,
            '&:hover': {
              boxShadow: `none`,
              backgroundColor: vegaColors.BgPrimary,
              border: `1px solid ${vegaColors.BorderColorInputFieldHover}`,
            },
            '&.Mui-focused': {
              boxShadow: 'none',
              backgroundColor: vegaColors.BgPrimary,
              border: `1px solid ${vegaColors.BorderColorInputFieldFocus}`,
            },
            '&.Mui-error': {
              border: `1px solid ${vegaColors.BorderColorDanger}`,
            },
          },
          underline: {
            '&:after': {
              borderBottom: 'none',
            },
            '&:before': {
              borderBottom: 'none',
            },
            '&:hover:not(.Mui-disabled):before': {
              borderBottom: 'none !important',
            },
          },
        },
      },
      MuiFormLabel: {
        styleOverrides: {
          root: {
            color: vegaColors.BgInvertedTertiary,
            fontWeight: 700,
            fontSize: '1rem',
            fontFamily: 'inherit !important',
            '&.Mui-error': {
              color: vegaColors.TextDanger,
            },
          },
          asterisk: {
            color: vegaColors.TextDanger,
          },
        },
      },
      MuiFormControlLabel: {
        styleOverrides: {
          label: {
            color: vegaColors.TextPrimary,
            fontWeight: 400,
          },
        },
      },
      MuiInputBase: {
        styleOverrides: {
          inputAdornedStart: {
            marginLeft: '-9px',
          },
        },
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            color: vegaColors.TextPrimary,
            fontFamily: 'inherit',
            fontWeight: '400',
            marginLeft: '-9px',
            '&.Mui-error': {
              color: vegaColors.TextPrimary,
            },
            '&.Mui-focused': {
              color: vegaColors.TextPrimary,
            },
          },
          shrink: {
            display: 'inline',
            transform: 'translate(12px, 13px) scale(1)',
            transition: 'none',
          },
          asterisk: {
            color: vegaColors.TextDangerLink,
          },
        },
      },
      MuiLoadingButton: {
        styleOverrides: {
          loadingIndicator: {
            color: vegaColors.TextSecondary,
          },
        },
      },
      MuiToggleButton: {
        styleOverrides: {
          root: {
            border: `1px solid ${vegaColors.TextInputPlaceholder}`,
            color: vegaColors.TextSecondary,
            '&.Mui-selected': {
              color: vegaColors.BgAction,
            },
          },
        },
      },
      MuiAutocomplete: {
        styleOverrides: {
          root: {
            fontSize: '.9rem',
            fontWeight: '500',
            border: 'none',
            '& .MuiFilledInput-root': {
              paddingTop: 0,
              paddingLeft: '11px',
            },
            '& .MuiFilledInput-input': {
              textIndent: 0,
            },
            '& .MuiInputBase-inputAdornedStart': {
              paddingLeft: '17px !important',
            },
          },
          popper: {
            transition: '0ms',
          },
          loading: {
            color: vegaColors.TextPrimary,
          },
          paper: {
            borderRadius: '12px',
            backgroundColor: vegaColors.BgPrimary,
            color: vegaColors.TextPrimary,
            fontFamily: 'inherit',
          },
          noOptions: {
            color: vegaColors.TextPrimary,
          },
          clearIndicator: {
            color: vegaColors.TextSecondary,
          },
          popupIndicator: {
            color: vegaColors.BgAction,
          },
          input: {},
          option: {
            '&.Mui-focused': {
              backgroundColor: `${customColors.PaperHover} !important`,
            },
          },
        },
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            color: vegaColors.TextSecondary,
            fontWeight: '400',
            marginTop: '7px',
            fontSize: '.8rem',
            '&.Mui-error': {
              color: vegaColors.TextDanger,
            },
          },
        },
      },
      MuiInput: {
        styleOverrides: {
          root: {
            '&.Mui-error': {
              border: `1px solid ${vegaColors.BorderColorDanger}`,
              borderRadius: 16,
            },
            borderRadius: 8,
            borderColor: vegaColors.BorderColorInputField,
          },
          input: {
            height: '1.4rem',
            '&:focus': {
              boxShadow: customColors.ringInputFocus,
              borderColor: vegaColors.BorderColorInputFieldFocus,
              backgroundColor: vegaColors.BgPrimary,
              borderRadius: 8,
            },
          },
        },
      },
      MuiButtonBase: {
        styleOverrides: {
          root: {
            '&.MuiIconButton-root': {
              color: vegaColors.BgAction,
            },
          },
        },
      },
      MuiInputAdornment: {
        styleOverrides: {
          root: {
            zIndex: 1,
            color: vegaColors.TextSecondary,
          },
          positionStart: {
            paddingLeft: '0px',
            marginRight: '0px',
            marginTop: '0px !important',
          },
          hiddenLabel: {
            marginTop: '0px',
          },
        },
      },

      MuiButton: {
        styleOverrides: {
          root: {
            fontFamily: 'inherit',
            fontSize: '1rem',
            borderRadius: '100px',
            minWidth: '8rem',
            minHeight: '3rem',
            backfaceVisibility: 'inherit',
            MozBackfaceVisibility: 'inherit',
            WebkitBackfaceVisibility: 'inherit',
            fontWeight: '600',
            color: vegaColors.TextInvertedPrimary,
            letterSpacing: '0',
            boxShadow: 'none',
          },
          label: {},
          containedPrimary: {
            boxShadow: 'none',
            WebkitTextFillColor: vegaColors.TextInvertedPrimary,
            '&.Mui-disabled': {
              backgroundColor: customColors.lightBackgroundDisabled,
              WebkitTextFillColor: vegaColors.TextInvertedPrimary,
            },
            '&:hover': {
              boxShadow: 'none',
              backgroundColor: vegaColors.BgActionHover,
            },
            '&.Mui-focusVisible': {
              boxShadow: 'none',
              backgroundColor: vegaColors.BgActionActive,
            },
            '&:focused': {
              boxShadow: 'none',
            },
            '&:active': {
              boxShadow: 'none',
            },
          },
          outlinedPrimary: {
            backgroundColor: `${vegaColors.BgPrimary}`,
            border: '2px solid ' + vegaColors.BorderColorAction,
            color: vegaColors.TextLink,
            '&:hover': {
              boxShadow: 'none',
              border: '2px solid ' + vegaColors.BorderColorActionHover,
              borderColor: `${vegaColors.BorderColorActionHover}`,
              backgroundColor: `${vegaColors.BgPage}`,
            },
            '&:selected': {
              boxShadow: 'none',
              backgroundColor: `${vegaColors.BorderColorActionActive}`,
              color: `${vegaColors.BgPage}`,
            },
            '&:focused': {
              boxShadow: 'none',
              border: '2px solid ' + vegaColors.BorderColorAction,
              backgroundColor: `${vegaColors.BorderColorActionHover}`,
              color: `${vegaColors.BgPage}`,
            },
            '&.Mui-disabled': {
              WebkitTextFillColor: vegaColors.TextDisabled,
              backgroundColor: vegaColors.BgActionSecondary,
              border: '2px solid ' + vegaColors.BorderColorInputFieldDisabled,
            },
          },
        },
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            color: vegaColors.BorderColorInputField,

            '&:checked': {
              color: vegaColors.BgActionActive,
            },
            '&.Mui-disabled': {
              color: vegaColors.TextInputPlaceholder,
            },
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            height: '3rem',
            borderRadius: 8,
            border: `1px solid ${vegaColors.BorderColorInputField}`,
            '&:hover': {
              boxShadow: `${alpha(vegaColors.BgAction, 0.1)} 0 0 0 0.1rem`,
              backgroundColor: vegaColors.BgPrimary,
              border: `1px solid ${vegaColors.BorderColorInputFieldHover}`,
            },
            '&.Mui-focused': {
              boxShadow: customColors.ringInputFocus,
              backgroundColor: vegaColors.BgPrimary,
              border: `1px solid ${vegaColors.BorderColorInputFieldFocus}`,
            },
            '&.Mui-error': {
              boxShadow: 'none',
              border: `1px solid ${vegaColors.BorderColorDanger}`,
            },
          },
          input: {
            height: '.9rem',
          },
          notchedOutline: {
            border: 'none',
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          root: {
            borderRadius: 8,
            backgroundColor: vegaColors.BgPrimary,
          },
          select: {
            position: 'relative',
            backgroundColor: vegaColors.BgPrimary,
            color: vegaColors.TextPrimary,
            fontWeight: '500',
            textIndent: '5px',
            lineHeight: '1.4rem',
            padding: '10px 12px',
            borderRadius: 8,
            fontFamily: 'inherit',
            '&&:after': {
              borderBottom: 'none',
            },
          },
          icon: {
            color: vegaColors.BgAction,
          },
        },
      },
      MuiNativeSelect: {
        styleOverrides: {
          root: {
            'label + &': {
              marginTop: '1rem',
            },
            borderRadius: 8,
          },
          select: {
            position: 'relative',
            backgroundColor: vegaColors.BgPrimary,
            color: vegaColors.TextPrimary,
            fontSize: '.9rem',
            fontWeight: '500',
            height: '1.8rem',
            lineHeight: '1.8rem',
            padding: '10px 12px',
            borderRadius: 8,
            boxShadow: 'none',
            fontFamily: 'inherit',
            '&:focus': {
              boxShadow: 'none',
              borderColor: vegaColors.BorderColorInputFieldFocus,
            },
            '&&:after': {
              borderBottom: 'none',
            },
            '&::placeholder': {
              color: vegaColors.TextInputPlaceholder,
              opacity: '1',
              fontWeight: 500,
              fontSize: 14,
            },
          },
          icon: {
            color: vegaColors.BgAction,
          },
        },
      },
      MuiDialog: {
        styleOverrides: {
          paper: {
            padding: '2rem',
            borderRadius: '8px',
            backgroundColor: vegaColors.BgPrimary,
            boxShadow: customColors.shadowPopover,
            border: `1px solid ${vegaColors.BgPage}`,
          },
        },
      },
      MuiDialogContent: {
        styleOverrides: {
          root: {
            paddingBottom: '1rem',
          },
        },
      },
      MuiChip: {
        styleOverrides: {
          root: {
            backgroundColor: vegaColors.BgStatusSuccess,
            color: vegaColors.TextInvertedSecondary,
            height: '1.5rem',
          },
          filledSuccess: {
            backgroundColor: vegaColors.BgStatusSuccess,
            color: vegaColors.TextPrimary,
          },
          filledError: {
            backgroundColor: vegaColors.BgStatusError,
            color: vegaColors.TextPrimary,
          },
        },
      },
      MuiAppBar: {
        styleOverrides: {
          root: {
            backgroundColor: vegaColors.BgPage,
            borderRadius: 0,
            border: 'none',
            boxShadow: 'none',
            transition: 'max-width 200ms, box-shadow 600ms',
            borderBottom: '1px solid ' + customColors.CustomBorder,
          },
        },
      },
      MuiBreadcrumbs: {
        styleOverrides: {
          separator: {
            fontSize: 35,
            color: vegaColors.TextSecondary,
          },
        },
      },
      MuiLink: {
        styleOverrides: {
          root: {
            cursor: 'pointer',
            color: vegaColors.TextLink,
            '&:hover': {
              color: vegaColors.TextLinkHover,
            },
          },
        },
      },
      //@ts-ignore
      // eslint-disable-next-line no-dupe-keys
      MuiIconButton: {
        styleOverrides: {
          root: {
            '&.Mui-disabled': {
              color: vegaColors.TextDisabled,
            },
          },
        },
      },
      MuiDateRangePickerDay: {
        styleOverrides: {
          dayInsideRangeInterval: {
            backgroundColor: alpha(vegaColors.BgAccent5Primary, 0.1),
          },
          rangeInternalPreview: {
            '&.Mui-disabled': {
              color: vegaColors.TextDisabled,
            },
            '&.MuiPickersDay-today': {
              border: 0,
              color: vegaColors.BgAction,
              fontWeight: 800,
            },
          },
          root: {},
        },
      },
      MuiPickersDay: {
        styleOverrides: {
          root: {
            backgroundColor: 'transparent',
            '&.Mui-disabled': {
              color: vegaColors.TextDisabled,
            },
            '&.MuiPickersDay-today': {
              border: 0,
              color: vegaColors.BgAction,
              fontWeight: 800,
            },
          },
        },
      },
      MuiPickersArrowSwitcher: {
        styleOverrides: {
          button: {
            color: 'inherit',
          },
        },
      },
      MuiPickerStaticWrapper: {
        styleOverrides: {
          content: {
            backgroundColor: vegaColors.BgPrimary,
          },
          root: {
            color: vegaColors.TextPrimary,
            backgroundColor: vegaColors.BgPrimary,
            borderRadius: '12px',
            '& .MuiIconButton-root': {
              color: vegaColors.TextPrimary,
            },
            '& .MuiDateRangePickerDay-rangeIntervalPreview': {
              '& .Mui-disabled': {
                color: vegaColors.TextDisabled,
              },
              '& .MuiPickersDay-today': {
                border: 0,
                color: vegaColors.BgAction,
                fontWeight: 800,
              },
            },
            '& .MuiDateRangePickerDay-rangeIntervalDayHighlight': {
              backgroundColor: alpha(vegaColors.BgAccent5Primary, 0.1),
            },
          },
        },
      },
      MuiSlider: {
        styleOverrides: {
          root: {
            '& .MuiSlider-markLabelActive': {
              fontWeight: 500,
            },
          },
        },
      },
      MuiAlert: {
        styleOverrides: {
          root: {
            color: vegaColors.TextPrimary,
            backgroundColor: vegaColors.BgSecondary,
            fontWeight: '500',
          },
          outlined: {
            border: 0,
          },
          outlinedSuccess: {
            borderLeft: `4px solid ${vegaColors.BgStatusSuccess}`,
          },
          outlinedError: {
            borderLeft: `4px solid ${vegaColors.BgStatusError}`,
          },
          outlinedWarning: {
            borderLeft: `4px solid ${vegaColors.BgStatusWarning}`,
          },
          outlinedInfo: {
            borderLeft: `4px solid ${vegaColors.BgStatusInfo}`,
          },
        },
      },
      ...overrides,
    },
  };
};
